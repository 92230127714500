<template>
  <div class="uk-flex uk-height-medium uk-padding-large" uk-height-viewport="offset-bottom: 30">
    <div class="uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s">
      <div class="uk-width-large uk-margin-auto">
        <div class="uk-text-center uk-animation-slide-bottom-medium uk-card uk-card-default uk-padding" style="animation-delay: 300ms">
          <img src="@/assets/img/code/lock.gif" width="50" alt="Promo-Code" />
          <h3 class="uk-margin-remove-bottom uk-margin-small-top">Willkommen bei careety</h3>
          <p class="uk-margin-remove-top uk-margin-medium-bottom uk-width-4-5 uk-margin-auto">Trage bitte deinen Promo-Code ein um dich kostenlos anzumelden &#128071;</p>

          <div>
            <el-form
              class="uk-text-left"
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              v-on:submit.prevent
            >
              <el-form-item prop="code">
                <el-input v-model.number="ruleForm.code" placeholder="Promo-Code"></el-input>
              </el-form-item>
            </el-form>
            <el-button
              @click="checkPromoCode"
              type="primary"
              class="uk-width-1-1 uk-margin-small-top"
              :loading="loading"
              >Bestätigen</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { MUTATION_SET_PROMO_CODE } from "@/store/mutation.type";

// eslint-disable-next-line consistent-return
const checkCode = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("Bitte Promo-Code eingeben"));
  }
  if (value !== "care21") {
    return callback(new Error("Dieser Promo-Code ist nicht gültig"));
  }
  setTimeout(() => {
    if (value < 6) {
      callback(new Error("Mindestens 6 Zeichen"));
    } else {
      callback();
    }
  }, 1000);
};

export default {
  name: "PromoCodeChecker",
  data() {
    return {
      ruleForm: {
        code: "",
      },
      loading: false,
      rules: {
        code: [{ validator: checkCode, trigger: "blur" }],
      },
    };
  },
  methods: {
    checkPromoCode() {
      this.loading = false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$router.push({
            name: "Signup",
            params: {
              campaign_pin: this.ruleForm.code,
            },
          });
          this.$store.commit(MUTATION_SET_PROMO_CODE, this.ruleForm.code);
        } else {
          console.log("error");
        }
      });
    },
  },
};
</script>

<style>
</style>
