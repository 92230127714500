<template>

  <div class="editor-component-wrapper uk-margin-large-bottom uk-width-2-4@s uk-width-1-1 uk-align-center">

      <div class="card-header uk-margin-large-bottom">
        <h3 class="editor-title">To-Dos</h3>
        <p v-if="behaviourRecommendations?.length" class="editor-subtitle">
          Füge deine besten To-Dos hinzu oder übernimm einfach vorhandene.
        </p>
        <p v-else class="editor-subtitle">
          Im Moment sind keine To-Dos zugewiesen.
        </p>
      </div>

      <behaviour-item
        :single-behaviour-item="item"
        :is-editing="isEditing"
        v-for="item in behaviourRecommendations"
        :key="item.text"
        @showEdit="editItem"
        @save-item="save"
        @delete-item="remove"
        :add-new-item="false"
        >
      </behaviour-item>

      <behaviour-item
        :single-behaviour-item="item"
        :is-editing="isEditing"
        v-for="item in behaviourRecommendationsLocal"
        :key="item.text"
        @showEdit="editItem"
        @save-item="save"
        @delete-item="remove"
        :add-new-item="addNewItem"
        @add-new-behaviour="addNewBehaviour"
        @remove-object-from-array="removeObjectFromArray"
        >
      </behaviour-item>

      <add-new-item
        class="uk-width-1-1"
        @click="addLocalBehaviour"
        >
      </add-new-item>

  </div>
</template>

<script>
import { Duration } from "luxon";
import BehaviourItem from "./BehaviourItem.vue";
import AddNewItem from "../../widgets/AddNewItem.vue";

export default {
  name: "BehaviourEditor",
  emits: ["update-parent-behaviour", "delete-parent-behaviour", "add-new-behaviour"],
  components: {
    BehaviourItem,
    AddNewItem,
  },
  props: {
    behaviourRecommendations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      labelPosition: "right",
      isEditing: false,
      behaviourRecommendationsLocal: [],
      addNewItem: false,
    };
  },
  methods: {
    removeObjectFromArray() {
      this.behaviourRecommendationsLocal.splice(0, 1);
    },
    addNewBehaviour(newItem) {
      this.$emit("add-new-behaviour", newItem);
      this.behaviourRecommendationsLocal = [];
    },
    remove(singleBehaviourItemLocal) {
      this.$emit("delete-parent-behaviour", singleBehaviourItemLocal);
    },
    save(singleBehaviourItemLocal) {
      this.$emit("update-parent-behaviour", singleBehaviourItemLocal);
    },
    addLocalBehaviour() {
      this.addNewItem = true;
      this.behaviourRecommendationsLocal.push(
        {
          text: "",
          offset: Duration.fromObject({ days: this.durationValue }),
          duration: Duration.fromObject({ days: this.durationValue }),
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header p {
  font-size: 0.9em;
}

.behaviour-item {
  margin: 50px 0;
}
</style>
