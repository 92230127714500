<template>
      <el-form
        v-loading="loading"
        class="uk-margin-large-bottom behaviour-item uk-animation-slide-bottom-small uk-card uk-card-default uk-flex-between uk-padding uk-grid-collapse"
        uk-grid
        uk-margin
        :label-position="labelPosition"
        label-width="100px"
        :model="formLabelAlign"
      >
      <div class="uk-margin-bottom uk-width-1-1 uk-width-2-5@m uk-padding-remove">
        <div>
          <el-form-item label="Beschreibung">
            <el-input
              type="textarea"
              maxlength="240"
              :autosize="{ minRows: 1, maxRows: 3 }"
              show-word-limit
              placeholder="To-Do ..."
              v-model="singleBehaviourItemLocal.text"
              :disabled="!isEditing"
            >
            </el-input>

          </el-form-item>
        </div>
      </div>

      <duration-picker :is-editing="isEditing" v-model="singleBehaviourItemLocal.duration"></duration-picker>
      <offset-picker :isEditing="isEditing" v-model="singleBehaviourItemLocal.offset"></offset-picker>

        <div class="editor-menu">
          <div v-if="!isEditing" class="uk-position-top-right uk-position-small edit-btn"><el-button type="text" class="edit-btn" @click="editItem()">Bearbeiten</el-button></div>
          <div v-if="isEditing" class="uk-margin" ><el-button size="small" class="cancel-btn" @click="cancelEdit()">Abbrechen</el-button></div>
          <div v-if="isEditing" class="uk-margin"><el-button size="small" type="primary" class="save-btn" @click="save()" >Speichern</el-button></div>
          <div v-if="isEditing && !isNew">
            <el-popconfirm
              confirmButtonText="Löschen"
              cancelButtonText="Abbrechen"
              icon="el-icon-info"
              iconColor="red"
              title="Möchtest du dieses Element löschen?"
              @confirm="deleteSingleBehaviourItem">
              <template #reference>
                <el-button type="default" class="delete-btn" size="small">Löschen</el-button>
              </template>
            </el-popconfirm>
          </div>
        </div>

      </el-form>
</template>

<script>
import { apiHpPatchBehaviourRecommendation, apiHPDeleteBehaviourRecommendation, apiHpAddBehaviourRecommendations } from "@/api";

import DurationPicker from "../../widgets/DurationPicker.vue";
import OffsetPicker from "../../widgets/OffsetPicker.vue";

export default {
  name: "BehaviourItem",
  props: ["singleBehaviourItem", "addNewItem"],
  emits: ["showEdit", "save-item", "cancelEdit", "delete-item", "add-new-behaviour", "remove-object-from-array"],
  components: {
    DurationPicker,
    OffsetPicker,
  },
  data() {
    return {
      isNew: this.addNewItem,
      loading: false,
      isEditing: this.addNewItem,
      labelPosition: "top",
      singleBehaviourItemLocal: {
        text: this.singleBehaviourItem.text,
        duration: this.singleBehaviourItem.duration,
        offset: this.singleBehaviourItem.offset,
        id: this.singleBehaviourItem.id,
      },
      values: [
        {
          value: "Tag(e)",
          label: "Tag(e)",
        },
        {
          value: "Woche(n)",
          label: "Woche(n)",
        },

      ],
      timeSpanDuration: "Tag(e)",
      timeSpanOffset: "Tag(e)",
    };
  },
  methods: {
    editItem() {
      this.isEditing = !this.isEditing;
    },
    cancelEdit() {
      this.isEditing = !this.isEditing;
      if (this.addNewItem === true) {
        this.$emit("remove-object-from-array");
      }
    },
    async save() {
      if (this.singleBehaviourItemLocal.text === "") {
        await this.$message({
          message: "Beschreibung angeben",
          type: "error",
          offset: 80,
        });
      }
      if (this.singleBehaviourItemLocal.duration.as("days") === 0) {
        await this.$message({
          message: "Dauer angeben",
          type: "error",
          offset: 80,
        });
      }
      if (this.addNewItem === true && this.singleBehaviourItemLocal.text !== "" && this.singleBehaviourItemLocal.duration.as("days") !== 0) {
        const data = {
          treatment_id: this.$route.params.id,
          text: this.singleBehaviourItemLocal.text,
          duration: this.singleBehaviourItemLocal.duration,
          offset: this.singleBehaviourItemLocal.offset,
          id: "",
        };
        try {
          await apiHpAddBehaviourRecommendations(data).then((result) => {
            this.singleBehaviourItemLocal.id = result.id;
          }).catch((err) => {
            console.log(err);
          });
          this.$message({
            message: "To-Do hinzugefügt",
            type: "success",
            offset: 80,
          });
        } catch (error) {
          console.log(error);
        }
        this.$emit("add-new-behaviour", this.singleBehaviourItemLocal);
        this.isEditing = !this.isEditing;
      } else if (this.addNewItem === false && this.singleBehaviourItemLocal.text !== "" && this.singleBehaviourItemLocal.duration.as("days") !== 0) {
        this.$emit("save-item", this.singleBehaviourItemLocal);
        await setTimeout(() => {
          apiHpPatchBehaviourRecommendation(this.singleBehaviourItem.id, this.singleBehaviourItemLocal);
          this.$message({
            message: "To-Do geändert",
            type: "success",
            offset: 80,
          });
        }, 800);
        this.isEditing = !this.isEditing;
      }
    },
    async deleteSingleBehaviourItem() {
      this.loading = true;
      await setTimeout(() => {
        try {
          this.$emit("delete-item", this.singleBehaviourItemLocal);
        } catch (error) {
          console.log(error);
        }
        try {
          apiHPDeleteBehaviourRecommendation(this.singleBehaviourItem.id);
        } catch (error) {
          console.log(error);
        }
        this.$message({
          message: "To-Do gelöscht",
          offset: 80,
        });
        this.loading = false;
      }, 800);
      this.isEditing = false;
    },
  },

};
</script>

<style lang="scss">

.behaviour-item {
  margin: 50px 0;
}

.behaviour-item .el-form-item__label {
  font-size: .9em;
  font-weight: 500;
  color: #272725;
  padding: 0;
  line-height: 1.2;
}
.behaviour-item .el-input--suffix .el-input__inner {
  height: 32px;
}
.behaviour-item .el-form-item {
  margin-bottom: 5px!important
}
.behaviour-item .el-input__icon {
  line-height: 36px;
}
.behaviour-item .el-textarea__inner:focus {
  border-color: #4f4f4f;
}

.el-button--small {
  min-width: 90px;
}

.editor-menu {
  width: 100px;
  padding: 0;
  text-align: right;
}
.editor-menu .el-button--text {
  color:#4f4f4f;
}

.editor-menu .edit-btn span {
    font-size: .90em;
  }

.editor-menu .el-button--text:hover {
  text-decoration: underline;
}

.integer-wrapper .el-input-number--small,
.integer-wrapper .el-select {
  min-width: 160px;
  width: 100%
}

@media screen and (max-width: 600px) {
  .editor-menu,
  .el-button--small {
    width: 100%;
  }
  .editor-menu .edit-btn span {
    font-size: .8em;
  }
}
</style>
