<template>
  <header class="uk-width-1-1 editor-header">
    <div
      uk-sticky=" sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-dark; top: 200; animation: uk-animation-slide-top;"
    >
      <nav
        class="uk-navbar uk-container uk-container-xlarge uk-black uk-padding-large uk-padding-remove-top uk-padding-remove-bottom uk-flex-wrap"
        uk-navbar
      >
        <div class="uk-navbar-left uk-width-1-6@m uk-width-1-1">
          <router-link to="/hp" class="uk-navbar-item uk-logo uk-padding-remove"
            ><img src="@/assets/logo/c.png" width="40" />
          </router-link>
        </div>

        <div
          class="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap uk-width-1-1 uk-width-expand@m"
        >
          <div
            class="title-wrapper uk-margin-small-right uk-width-1-1 uk-width-auto@s"
          >
            <h3>{{ treatment?.name }}</h3>
            <h4>{{ treatment?.diagnosis?.name }}</h4>
          </div>
          <ul
            class="uk-navbar-nav uk-flex-middle editor-steps uk-flex-wrap uk-width-1-1 uk-width-auto@s"
          >
            <li
              :class="{
                'uk-active': $route.name === 'HealthProfessionalTreatmentSend',
              }"
            >
              <router-link :to="{ name: 'HealthProfessionalTreatmentSend' }"
                >Therapieplan</router-link
              >
            </li>

            <li :class="{ 'uk-active': $route.name === 'DiagnosisPreview' }">
              <router-link :to="{ name: 'DiagnosisPreview' }">Diagnose</router-link>
            </li>
          </ul>
          <preview-range-slider
            :preview-progress-max-days="previewProgressMaxDays"
            @update-view="updateView"
          >
          </preview-range-slider>

          <div class="send-plan-btn uk-flex uk-text-center uk-flex-middle">
            <u class="edit-plan-link"><router-link :to="{ name: 'TreatmentDetails' }"> Editieren </router-link></u>
            <el-button
              type="primary"
              style="display: block"
              size="small"
              @click="openDialog"
              >Senden</el-button
            >
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import PreviewRangeSlider from "./PreviewRangeSlider.vue";

export default {
  name: "SendHeader",
  props: [
    "treatment",
    "sendDialog",
    "previewProgressMaxDays",
  ],
  emits: ["showSendDialog"],
  components: {
    PreviewRangeSlider,
  },
  methods: {
    openDialog() {
      this.$emit("showSendDialog");
    },
    updateView(previewProgressDayLocal) {
      this.$emit("update-view", previewProgressDayLocal);
    },
  },
};
</script>

<style lang="scss">
.title-wrapper h3 {
  font-size: 0.94em;
  margin: 0;
}

.title-wrapper h4 {
  font-size: 0.84em;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
}

.editor-steps li a {
  font-size: 0.94em;
}
.editor-steps li a:first-child {
  padding-left: 0;
}

.uk-navbar-nav > li > a {
  min-height: 10px;
}

.edit-plan-link {
  font-size: 13px;
  font-weight: 500;
  margin-right: 20px;
}

@media screen and (max-width: 1050px) {
  .send-plan-btn {
    flex-direction: column-reverse;
    align-items: center;
  }
  .edit-plan-link {
    margin-right: 0;
  }
}
@media screen and (max-width: 959px) {
  .send-plan-btn {
    flex-direction: row;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .edit-plan-link {
    margin-right: 20px;
  }
  .uk-sticky-fixed {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 739px) {
  .title-wrapper {
    margin-bottom: 20px;
  }
}
</style>
