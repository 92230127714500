import { DateTime, Duration } from "luxon";

export const parseTimedeltaStringToMillis = (timedelta) => {
  if (typeof timedelta === "number") {
    return timedelta;
  }
  const reWithDays = /(?<days>\d+ )?(?<hours>\d{2}):(?<minutes>\d{2}):(?<seconds>\d{2})(?<millis>\.\d+)?/mg;
  const match = reWithDays.exec(timedelta);
  if (match) {
    let days = 0;
    if (match.groups.days) {
      days = parseInt(match.groups.days.trim(), 10);
    }
    let millis = 0;
    if (match.groups.millis) {
      millis = parseInt(match.groups.millis.substring(1, 4), 10);
    }
    return (
      days * 86400000 + parseInt(match.groups.hours, 10) * 3600000
      + parseInt(match.groups.minutes, 10) * 60000
      + parseInt(match.groups.seconds, 10) * 1000
      + millis
    );
  }
  return 0;
};

export const dateToMillis = (date) => {
  if (typeof date === "number") {
    return date;
  }
  if (typeof date === "string") {
    return DateTime.fromISO(date).toMillis();
  }
  if (typeof date === "object") {
    if (date instanceof DateTime) {
      return date.toMillis();
    }
    if (date instanceof Date) {
      return date.valueOf();
    }
    return DateTime.fromObject(date).toMillis();
  }
  return date;
};

export const parseTimedelta = (timedelta) => {
  if (Duration.isDuration(timedelta)) {
    return timedelta;
  }
  return Duration.fromMillis(parseTimedeltaStringToMillis(timedelta));
};

const toDateTime = (d) => {
  if (typeof (d) === "object") {
    if (d.isLuxonDateTime) {
      return d;
    }
    return DateTime.fromObject(d);
  }
  if (typeof (d) === "number") {
    return DateTime.fromMillis(d);
  }
  if (typeof (d) === "string") {
    return DateTime.fromISO(d);
  }
  return d;
};

export const progress = (startDate, nowDate, offset, duration) => {
  const start = DateTime.fromISO(startDate);
  const now = toDateTime(nowDate);
  const off = parseTimedelta(offset);
  const dur = parseTimedelta(duration);
  return Math.max(0.0, Math.min(1.0, now.diff(start.plus(off)).valueOf() / dur.valueOf()));
};

export const progressDur = (startDate, nowDate, offset, duration) => {
  const start = DateTime.fromISO(startDate);
  const now = toDateTime(nowDate);
  const off = parseTimedelta(offset);
  const dur = parseTimedelta(duration);
  const prog = now.diff(start.plus(off));
  if (prog.valueOf() > dur.valueOf()) {
    return dur;
  }
  if (prog.valueOf() < 0) {
    return Duration.fromMillis(0);
  }
  return prog;
};

export const progressAsDays = (startDate, nowDate, offset, duration) => {
  const days = Math.ceil(progressDur(startDate, nowDate, offset, duration).as("days"));
  return days;
};

export const OffsetDurationTimeBounderies = class {
  constructor() {
    this.min = Duration.fromObject({ years: -10 }).as("milliseconds");
    this.max = Duration.fromObject({ years: 10 }).as("milliseconds");
    this.totalOffset = this.max;
    this.totalDuration = this.min;
  }

  add(offset, duration) {
    const off = parseTimedelta(offset).valueOf();
    const dur = parseTimedelta(duration).valueOf();
    if (off < this.totalOffset) {
      this.totalOffset = off;
    }
    if (off + dur > this.totalDuration) {
      this.totalDuration = off + dur;
    }
  }

  duration() {
    if (this.totalDuration === this.min) {
      return 0;
    }
    return this.totalDuration - this.offset();
  }

  offset() {
    if (this.totalOffset === this.max) {
      return 0;
    }
    return this.totalOffset;
  }

  progress(startDate, nowDate) {
    return progress(startDate, nowDate, this.offset(), this.duration());
  }

  progressAsDays(startDate, nowDate) {
    return progressAsDays(startDate, nowDate, this.offset(), this.duration());
  }

  durationAsDays() {
    return Math.ceil(Duration.fromMillis(this.duration()).as("days"));
  }

  offsetAsDays() {
    return Math.ceil(Duration.fromMillis(this.offset()).as("days"));
  }
};

export const filterArrayOffDurCurrentlyActive = (startDate, nowDate, thatArray) => thatArray.filter(
  (item) => {
    const start = dateToMillis(startDate);
    const now = dateToMillis(nowDate);
    const offset = parseTimedelta(item.offset).valueOf();
    const duration = parseTimedelta(item.duration).valueOf();
    if (duration === 0) {
      return false;
    }
    return (start + offset < now) && (start + offset + duration > now);
  },
);

export const filterArrayOffDurNotYetActive = (startDate, nowDate, thatArray) => thatArray.filter(
  (item) => {
    const start = dateToMillis(startDate);
    const now = dateToMillis(nowDate);
    const offset = parseTimedelta(item.offset).valueOf();
    const duration = parseTimedelta(item.duration).valueOf();
    if (duration === 0) {
      return false;
    }
    return start + offset > now;
  },
);

export const filterArrayOffDurAlreadyFinished = (startDate, nowDate, thatArray) => thatArray.filter(
  (item) => {
    const start = dateToMillis(startDate);
    const now = dateToMillis(nowDate);
    const offset = parseTimedelta(item.offset).valueOf();
    const duration = parseTimedelta(item.duration).valueOf();
    if (duration === 0) {
      return false;
    }
    return start + offset + duration < now;
  },
);
