<template>
  <!-- eslint-disable max-len -->

  <div v-if="!hideMatomoBanner" class="uk-container uk-container-large c-banner-wrapper">
    <div class="uk-text-left uk-card uk-card-default c-banner-content uk-animation-slide-bottom-small">
      <h3 class="uk-margin-remove">Cookies & Privacy</h3>
      <p>
        Wir verwenden Matomo Cloud um unseren Datenverkehr zu analysieren und
        uns zu helfen, die Benutzerfreundlichkeit zu verbessern. Wir
        verarbeiten deine IP-Adresse und Cookies werden 13 Monate lang auf
        deinem Browser gespeichert. Diese Daten werden nur von uns und unseren
        Dienstleistern verarbeitet. Lies unsere <u><a href="#modal-privacy"
        uk-toggle>Datenschutzerklärung</a></u> um mehr zu erfahren, oder gehe
        auf unsere Datenschutzerklärungsseite, um deine Einstellungen zu
        verwalten.
      </p>
      <div class="uk-text-left">
        <button
          @click="consentGrantedMatomo"
          class="uk-button uk-button-primary c-banner-btn uk-text-capitalize uk-width-auto@s"
        >
          Akzeptieren
        </button>
        <button
          @click="consentDeniedMatomo"
          class="uk-button uk-button-default uk-text-capitalize uk-width-auto@s"
        >
          Ablehnen
        </button>
      </div>
    </div>
  </div>

  <div id="modal-privacy" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-margin-remove">Datenschutzerklärung</h2>
      </div>
      <div class="uk-modal-body" uk-overflow-auto>
        <p v-html="privacy.content"></p>
      </div>
    </div>
  </div>

</template>

<script>
import { ACTION_ASSURE_FETCHED_PRIVACY } from "@/store/action.type";
import { mapState } from "vuex";
import {
  matomoCheckConsentUndecidedAndPropagate,
  matomoGrantConsentNow,
  matomoDenyConsentNow,
} from "@/utils/consent";

export default {
  name: "AcceptBar",
  data() {
    return {
      hideMatomoBanner: false,
    };
  },
  methods: {
    consentGrantedMatomo() {
      this.hideMatomoBanner = true;
      matomoGrantConsentNow();
    },
    consentDeniedMatomo() {
      this.hideMatomoBanner = true;
      matomoDenyConsentNow();
    },
  },
  computed: {
    ...mapState(["privacy"]),
  },
  async mounted() {
    try {
      this.$store.dispatch(ACTION_ASSURE_FETCHED_PRIVACY);
    } catch (err) {
      // TODO: handle errors
    }
    this.hideMatomoBanner = !matomoCheckConsentUndecidedAndPropagate();
  },
};
</script>

<style lang="sass" scoped>
.uk-modal-title
    font-size: 1.6em

.c-banner-wrapper
    position: fixed
    bottom: 25px
    left: 0
    right: 0
    min-width: 200px
    max-width: 90%
    z-index: 6001;

.c-banner-content
    padding: 20px 30px
    border-radius: 12px
    margin: 0 auto
    animation-delay: 1200ms

.c-banner-content h3
    font-weight: 600

.c-banner-content p
    font-size: .88em
    margin-top: 8px
    margin-bottom: 24px
    color: #000!important
    line-height: 1.5

.c-banner-btn
    margin-right: 20px

@media screen and (max-width: 639px)
    .c-banner-btn
        margin-bottom: 10px
        margin-right: 0
</style>
