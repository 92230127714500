<template>
  <div class="c-signup-wrapper uk-grid-large uk-flex-middle" uk-grid>
    <div
      class="uk-grid-small uk-width-1-2@m"
      uk-grid
      uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
      style="animation-delay: 400ms"
    >
      <div class="uk-width-4-5@s">
        <div class="uk-margin-medium-bottom">
          <h3 class="uk-margin-small-bottom">Alle Infos zu Ihrer Behandlung an einem Ort</h3>
          <div v-if="introText" v-html="introText"></div>
          <div v-else>
            <p>
              In Ihrem digitalen Behandlungsplan erfahren Sie alles, was Sie über Ihre Diagnose und  Behandlung wissen müssen. Was tun, was nicht? Medikamente & Hausmittel, Übungen & Hilfsmittel, Ernährung & mehr, …
            </p>
          </div>
        </div>

        <div>
          <h3 class="uk-margin-small-bottom">Wie Ihre Daten geschützt werden</h3>
          <p class="uk-margin-remove-top">
            Wir geben Ihnen nicht nur die volle Kontrolle über Ihre Gesundheit, sondern auch über Ihre Daten. Bitte nehmen Sie sich einen Moment Zeit, um unsere <a href="#modal-privacy" uk-toggle class="color-coral">Datenschutzerklärung</a> zu lesen. Einwilligungen können jederzeit widerrufen werden.
          </p>
        </div>
      </div>
    </div>

    <div
      class="uk-width-xlarge@l uk-width-1-2@m"
      uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
      style="animation-delay: 800ms"
    >
      <div class="uk-card uk-card-default uk-card-body">
        <h3>Kostenlos anmelden</h3>
        <el-form
          :model="signupProfile"
          :rules="VALIDATOR_RULES"
          ref="signupProfileForm"
          class="c-signup-person-form"
          :hide-required-asterisk="true"
        >
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="forename" label="Vorname">
                <el-input
                  v-model="signupProfile.forename"
                  type="text"
                  name="forename"
                  id="forename"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="surname" label="Nachname">
                <el-input
                  v-model="signupProfile.surname"
                  type="text"
                  name="surname"
                  id="surname"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="username" label="Username">
                <el-input
                  v-model="username"
                  :disabled="true"
                  type="email"
                  id="email"
                  name="email"
                  autocomplete="username"
                ></el-input>
                <span class="c-label-bottom">
                  In den Einstellungen änderbar</span
                >
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="password" label="Passwort">
                <el-input
                  v-model="signupProfile.password"
                  show-password
                  type="password"
                  name="password"
                  id="password"
                ></el-input>
                <span class="c-label-bottom">Mindestens 8 Zeichen</span>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="radio-btn-group uk-margin-small-top">
            <el-form-item prop="agbs_confirmation">
              <el-checkbox v-model="signupProfile.agbs_confirmation">Ich habe die <span class="c-ceckbox-text"><a href="#modal-terms" uk-toggle>Nutzungsbedingungen</a> gelesen und akzeptiere diese.</span></el-checkbox>
            </el-form-item>

            <el-form-item prop="careety_dp_confirmation">
              <el-checkbox v-model="signupProfile.careety_dp_confirmation">Ich bin damit einverstanden, dass Careety die von mir freiwillig zur Verfügung gestellten persönlichen Gesundheitsdaten verarbeitet, um mein Konto zu erstellen. Diese Einwilligung kann jederzeit widerrufen werden, mehr dazu in unserer <span class="c-ceckbox-text"><a href="#modal-privacy" uk-toggle>Datenschutzerklärung.</a></span></el-checkbox>
            </el-form-item>
          </div>

        </el-form>
        <div>
           <el-button
            type="primary"
            class="uk-width-1-1 uk-margin-top"
            :loading="loading"
            :disabled="!signupProfile.forename || !signupProfile.surname || !signupProfile.password || !username || !signupProfile.agbs_confirmation || !signupProfile.careety_dp_confirmation"
            @click="validateAndCompletePatientSignup">Zum Behandlungsplan</el-button>
        </div>
      </div>
    </div>
  </div>

    <div id="modal-terms" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-margin-remove">Nutzungsbedingungen</h2>
      </div>
      <div class="uk-modal-body" uk-overflow-auto>
        <terms-patient></terms-patient>
      </div>
    </div>
  </div>

</template>

<script>
import { ACTION_PATIENT_SIGNUP, ACTION_UPDATE_PROFILE } from "@/store/action.type";
import { MUTATION_SET_PATIENT_SIGNUP_DATA } from "@/store/mutation.type";
import TermsPatient from "../widgets/TermsPatient.vue";

const VALIDATOR_RULES = {
  forename: [
    { required: true, message: "Bitte geben Sie Ihren Vornamen ein", trigger: "blur" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "blur" },
  ],
  surname: [
    { required: true, message: "Bitte geben Sie Ihren Nachnamen ein", trigger: "blur" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "blur" },
  ],
  password: [
    { required: true, message: "Wähle Sie bitte Ihr Passwort", trigger: "blur" },
    { min: 8, message: "Mindestens 8 Zeichen", trigger: "blur" },
  ],
  agbs_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Akzeptieren Sie unsere Nutzungsbedingungen um fortzufahren",
    },
  ],
  careety_dp_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Akzeptieren Sie unsere Datenschutzerklärung um fortzufahren",
    },
  ],
};

export default {
  name: "SignupPatient",
  components: {
    TermsPatient,
  },
  data() {
    return {
      username: "",
      disabled: true,
      loading: false,
      introText: "",
      signupProfile: {
        forename: "",
        surname: "",
        password: "",
        age_confirmation: true,
        agbs_confirmation: false,
        careety_dp_confirmation: false,
      },
    };
  },
  mounted() {
    if (this.$store.state.patientSignup0InviteData) {
      this.introText = this.$store.state.patientSignup0InviteData.introductionary_text;
    }
    if (this.$store.state.patientSignup0InviteData) {
      this.username = this.$store.state.patientSignup0InviteData.email;
    }
    if (this.$store.state.patientSignupData) {
      this.signupProfile = this.$store.state.patientSignupData;
    }
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
  },
  methods: {
    async validateAndCompletePatientSignup() {
      // validate
      try {
        await this.$refs.signupProfileForm.validate();
        await this.$store.commit(MUTATION_SET_PATIENT_SIGNUP_DATA, this.signupProfile);
        this.loading = true;
      } catch (err) {
        console.log("signupPatientForm validation error");
        return;
      }

      try {
        // we need to keep a separate reference of profile update args here
        // becuase the signup action clears the store
        const updateArgs = {
          forename: this.$store.state.patientSignupData.forename,
          surname: this.$store.state.patientSignupData.surname,
        };
        await this.$store.dispatch(ACTION_PATIENT_SIGNUP);
        await this.$store.dispatch(ACTION_UPDATE_PROFILE, updateArgs);
      } catch (err) {
        console.log(`signup error: ${err}`);
        // TODO: display error message
        this.$message({
          message: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut",
          type: "error",
          offset: 80,
        });
        this.loading = false;
        return;
      }
      setTimeout(() => {
      // all done, go to treatment
        this.$router.push("/treatment");
      }, 1200);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/helpers/_variables.scss";

// .c-signup-form a {
//   color: #202020;
// }

// .c-signup-form .c-ceckbox-text {
//   display: block;
//   line-height: 1.7;
//   font-size: .89em;
//   color: #202020;
//   margin-left: 25px;
// }

// .c-signup-form .el-checkbox {
//   color: #202020!important;
//   font-weight: 600!important;
//   line-height: 2;
//   display: block;
// }

// .c-signup-form .el-form-item {
//   margin-bottom: 40px!important;
// }

// .c-signup-gdpr-content img {
//   margin-bottom: 1.8em
// }

// .c-form-link-primary {
//   color: $c-coral-main;
//   display: flex;
//   justify-content: center;
// }

// .c-signup-container .c-label-bottom {
//     font-size: .8em!important;
// }

// .c-signup-container .el-form-item__label {
//     color: #202020!important;
// }

//  __      _____
//  \ \    / /__ \
//   \ \  / /   ) |
//    \ \/ /   / /
//     \  /   / /_
//      \/   |____|

.c-signup-wrapper::v-deep .el-form-item__label {
  color: $c-black-main;
  display: flex!important;
  flex-direction: row-reverse!important;
}

.c-signup-wrapper::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  margin-left: 4px;
  margin-right: 0;
}

.c-signup-wrapper::v-deep .uk-card {
  padding-bottom: 40px!important;
  padding-top: 32px!important;
}

.c-signup-wrapper::v-deep .el-checkbox {
  display: flex;
  align-items: center;
}

.c-signup-wrapper::v-deep .el-checkbox__label {
  white-space: normal;
  font-weight: 400;
  font-size: .96em;
  color: $c-black-main;
}

.c-signup-wrapper::v-deep a {
  text-decoration: underline;
}

.c-signup-wrapper::v-deep .color-coral {
  color: $c-coral-main;
}

.c-signup-wrapper .radio-btn-group::v-deep .el-form-item__error {
  margin-left: 24px;
}

.c-signup-wrapper .radio-btn-group .el-form-item::v-deep {
  margin-bottom: 32px;
}

.c-signup-wrapper::v-deep .c-label-bottom {
    font-size: .8em!important;
    margin-top: 2px;
}

</style>
