<template>
  <div
    class="uk-flex uk-height-medium uk-background-default uk-margin uk-padding-small"
    uk-height-viewport="offset-bottom: 30"
  >
    <div
      class="uk-margin-auto uk-margin-auto-vertical uk-width-medium@s uk-text-center"
      uk-scrollspy="cls: uk-animation-fade; repeat: true"
    >
      <img src="@/assets/logo/careety-logo.png" width="240" alt="careety Logo" />
      <h1 class="uk-text-small uk-text-light uk-margin-medium-bottom">
        coming soon
      </h1>
      <div>
        <a
          class="uk-button uk-button-default uk-width-4-6 uk-text-capitalize uk-margin-bottom"
          href="#"
          role="button"
          ><del>Einladung anfordern</del></a
        >
      </div>
      <div>
        <router-link
          to="/login"
          class="uk-button uk-button-primary uk-width-4-6 uk-text-capitalize c-login-button"
          role="button"
          >zum Login</router-link
        >
      </div>
    </div>
  </div>
</template>
