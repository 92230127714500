<template>
  <slim-header></slim-header>
  <diagnosis :patient-diagnosis="patientDiagnosis"></diagnosis>
  <careety-footer :user-profile="userProfile"></careety-footer>
</template>
<script>
import { ACTION_ASSURE_FETCHED_DIAGNOSIS } from "@/store/action.type";
import { mapState } from "vuex";

import Diagnosis from "../components/diagnosis/Diagnosis.vue";

export default {
  name: "DiagnosisView",
  props: ["userProfile"],
  components: {
    Diagnosis,
  },
  computed: {
    ...mapState(["patientDiagnosis"]),
  },
  async mounted() {
    try {
      this.$store.dispatch(ACTION_ASSURE_FETCHED_DIAGNOSIS);
    } catch (err) {
      // TODO: handle errors
    }
  },
};
</script>
