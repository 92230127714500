<template>
      <div class="send-plan-content">
        <h3 class="uk-modal-title uk-margin-top">{{ patientCase.treatment.name }}</h3>
        <p>{{ patientCase.treatment.diagnosis.name }}</p>
      </div>

      <el-form v-loading="loading" :model="sendPlan" :rules="VALIDATOR_RULES" ref="sendPlanForm" class="send-plan-form">
        <el-form-item prop="email">
          <el-input placeholder="E-Mail" id="email" name="email" type="email" autocomplete="on" v-model="sendPlan.email"></el-input>
        </el-form-item>

        <div class="c-checkbox-hp-send uk-text-left">
           <p class="c-ceckbox-text">Mit dem Versenden des Therapieplans stimmen Sie unseren <u><router-link to="/agb-health-professional" target="_blank"> AGB</router-link> </u> zu.</p>
        </div>

        <button
          class="uk-button uk-button-primary uk-width-expand uk-margin-top"
          type="submit"
          @click.prevent="sendTreatmentPlantoUser(plan_id)"
          :disabled="isDisabled"
        >
          Verschicken
        </button>
      </el-form>

      <div v-if="errorMessage" class="uk-margin c-alert">
        <el-alert title="Fehler" :closable="false" type="error">
          <span>{{ errorMessage }}</span>
        </el-alert>
      </div>
</template>

<script>
import { ACTION_SEND_TREATMENTPLAN } from "@/store/action.type";

const VALIDATOR_RULES = {
  email: [
    { required: true, message: "Bitte eine E-Mail eingeben", trigger: "blur" },
    {
      type: "email",
      message: "Bitte eine korrekte E-Mail eingeben",
      trigger: ["blur", "change"],
    },
  ],
  agbs_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Akzeptieren Sie unsere Nutzungsbedingungen um fortzufahren",
    },
  ],
};

export default {
  name: "SendPlanModal",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      info: "E-Mail-Einladung für diesen Therapieplan verschicken.",
      errorMessage: "",
      sendPlan: {
        email: "",
      },
      loading: false,
    };
  },
  // Todo: watch for Alert and auto hide
  methods: {
    async sendTreatmentPlantoUser() {
      try {
        this.loading = true;
        await this.$refs.sendPlanForm.validate();
        await this.$store.dispatch(ACTION_SEND_TREATMENTPLAN, {
          email: this.sendPlan.email,
          plan_id: this.patientCase.treatment.id,
        });
        this.sendPlan.email = "";
        setTimeout(() => {
          this.$emit("close-quick-send-dialog");
        }, 100);
      } catch (err) {
        this.loading = false;
        if (err.request && err.request.status === 230) {
          this.errorMessage = "Zu viele Anfragen";
        } else {
          this.errorMessage = "Eintragung fehlgeschlagen, bitte geben Sie eine gültige E-Mail Adresse ein.";
        }
        setTimeout(() => {
          this.errorMessage = "";
        }, 4000);
      }
    },
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
  },
};
</script>

<style lang="scss">
.el-dialog {
    border-radius: 12px;
}

.el-overlay {
  padding: 0 15px;
}

.el-dialog .el-dialog__header {
  padding: 0;
}

.el-dialog .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}

.el-dialog__title {
  font-weight: 600;
  font-size: 1.3em;
}
.el-dialog h3,
.el-dialog h4 {
    margin: 0;
}

.el-dialog h3 {
  font-size: 1.6em;
}

.el-dialog .send-plan-content h3,
.el-dialog .send-plan-content h4 {
    margin: 0;
}

.el-dialog .send-plan-content p {
    font-size: .9em;
    line-height: 1.3;
    padding: 0 12px;
    margin-top: 5px;
}

.el-dialog .send-plan-form .uk-input {
    border: none;
    background-color: #f8f8f8;
}

.el-dialog .send-plan-form button {
    text-transform: capitalize;
}

.el-dialog .c-alert {
    text-align: left;
}

</style>
