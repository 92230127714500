<template>
  <div
    v-if="previewProgressMaxDays"
    class="preview-progress-input--wrapper uk-flex uk-flex-start uk-flex-middle"
  >
    <el-slider
      v-model="previewProgressDayLocal"
      @change="progressDayChanged"
      style="width: 100px; margin-right: 10px"
      :min="1"
      :max="previewProgressMaxDays"
    >
    </el-slider>
    <div class="preview-days-wrapper">
      Tag {{ previewProgressDayLocal }} von {{ previewProgressMaxDays }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewProgressDayLocal: 1,
    };
  },
  name: "PreviewRangeSlider",
  props: [
    "previewProgressMaxDays",
  ],
  methods: {
    async progressDayChanged() {
      try {
        this.$emit("update-view", this.previewProgressDayLocal);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.preview-progress--wrapper h5 {
  font-size: 0.9em;
  margin: 0;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #4a4a4a;
}

.preview-progress--wrapper p {
  font-size: 0.84em;
  margin: 0;
}

.preview-progress-input--wrapper {
  margin-top: 8px;
}
.preview-days-wrapper {
  font-size: 0.8em;
}
</style>
