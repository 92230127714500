import { createStore } from "vuex";
import axios from "axios";
import { apiUserPatientCase } from "@/api";
import { patientCaseWithProgress } from "@/utils/progress";
import { parseTimedelta } from "@/utils/time";
import {
  ACTION_TRY_LOGIN,
  ACTION_LOGOUT,
  ACTION_FETCH_PROFILE,
  ACTION_ASSURE_FETCHED_PROFILE,
  ACTION_UPDATE_PROFILE,
  ACTION_FETCH_DIAGNOSIS,
  ACTION_ASSURE_FETCHED_DIAGNOSIS,
  ACTION_FETCH_CASE,
  ACTION_ASSURE_FETCHED_CASE,
  ACTION_FETCH_PRIVACY,
  ACTION_ASSURE_FETCHED_PRIVACY,
  ACTION_FETCH_IMPRINT,
  ACTION_ASSURE_FETCHED_IMPRINT,
  ACTION_WAITINGLIST_SIGNUP,
  ACTION_USER_REQUEST_PASSWORD_RESET,
  ACTION_USER_PASSWORD_RESET,
  ACTION_PATIENT_SIGNUP_CHECK_TOKEN,
  ACTION_PATIENT_SIGNUP,
  ACTION_FETCH_TREATMENTPLANS_TO_SEND,
  ACTION_SEND_TREATMENTPLAN,
  ACTION_PATIENT_FEEDBACK,
  ACTION_FETCH_TERMS_PATIENT,
  ACTION_ASSURE_FETCHED_TERMS_PATIENT,
  ACTION_FETCH_TERMS_HEALTH_PROFESSIONAL,
  ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL,
  ACTION_ASSURE_FETCHED_PROMO_CODE,
  ACTION_FETCH_PROMO_CODE,
} from "@/store/action.type";
import {
  MUTATION_SET_PROFILE,
  MUTATION_SET_DIAGNOSIS,
  MUTATION_SET_CASE,
  MUTATION_SET_PRIVACY,
  MUTATION_SET_IMPRINT,
  MUTATION_SET_PATIENT_SIGNUP_0_INVITE_DATA,
  MUTATION_SET_PATIENT_SIGNUP_2_CONSENT,
  MUTATION_SET_PATIENT_SIGNUP_3_PROFILE,
  MUTATION_CLEAR_PATIENT_SIGNUP,
  MUTATION_SET_TREATMENTPLANS_TO_SEND,
  MUTATION_SET_TERMS_PATIENT,
  MUTATION_SET_TERMS_HEALTH_PROFESSIONAL,
  MUTATION_CLEAR_USERDATA,
  MUTATION_SET_PROMO_CODE,
  MUTATION_SET_PATIENT_SIGNUP_DATA,
} from "@/store/mutation.type";

const axiosClient = axios.create({
  // baseURL: "",
  // timeout: 1000,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken",
});

const mutations = {
  [MUTATION_SET_PROFILE](state, data) {
    state.userProfile = data;
    return data;
  },
  [MUTATION_SET_DIAGNOSIS](state, data) {
    state.patientDiagnosis = data;
    return data;
  },
  [MUTATION_SET_CASE](state, data) {
    state.patientCase = patientCaseWithProgress(data);
    return state.patientCase;
  },
  [MUTATION_SET_PRIVACY](state, data) {
    state.privacy = data;
    return data;
  },
  [MUTATION_SET_IMPRINT](state, data) {
    state.imprint = data;
    return data;
  },
  async [MUTATION_SET_PATIENT_SIGNUP_0_INVITE_DATA](state, inviteData) {
    state.patientSignup0InviteData = inviteData;
    return inviteData;
  },
  async [MUTATION_SET_PATIENT_SIGNUP_2_CONSENT](state, data) {
    state.patientSignup2Consent = data;
    return data;
  },
  async [MUTATION_SET_PATIENT_SIGNUP_3_PROFILE](state, data) {
    state.patientSignup3Profile = data;
    return data;
  },
  async [MUTATION_SET_PATIENT_SIGNUP_DATA](state, data) {
    state.patientSignupData = data;
    return data;
  },
  async [MUTATION_CLEAR_PATIENT_SIGNUP](state) {
    state.patientSignup0InviteData = null;
    state.patientSignup2Consent = null;
    state.patientSignup3Profile = null;
    state.patientSignupData = null;
  },
  [MUTATION_SET_TREATMENTPLANS_TO_SEND](state, data) {
    state.treatmentplans = data;
    return data;
  },
  [MUTATION_SET_TERMS_PATIENT](state, data) {
    state.termsPatient = data;
    return data;
  },
  [MUTATION_SET_TERMS_HEALTH_PROFESSIONAL](state, data) {
    state.termsHealthProfessional = data;
    return data;
  },
  [MUTATION_CLEAR_USERDATA](state) {
    // general user profile
    this.commit(MUTATION_SET_PROFILE, "");
    // patient data
    this.commit(MUTATION_SET_DIAGNOSIS, "");
    this.commit(MUTATION_SET_CASE, null);
    this.commit(MUTATION_CLEAR_PATIENT_SIGNUP);
    // health professional data
    state.treatmentplans = {};
  },
  [MUTATION_SET_PROMO_CODE](state, data) {
    state.promoCode = data;
    return data;
  },
};

export const actions = {
  async [ACTION_TRY_LOGIN](context, data) {
    const response = await axiosClient.post("/api/user/login/", data);
    await context.commit(MUTATION_CLEAR_USERDATA);
    return response.data;
  },

  async [ACTION_FETCH_PROFILE](context) {
    try {
      const response = await axiosClient.get("/api/user/profile/");
      context.commit(MUTATION_SET_PROFILE, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_PROFILE, {});
      throw err;
    }
  },

  async [ACTION_ASSURE_FETCHED_PROFILE](context) {
    if (context.state.userProfile === "") {
      return context.dispatch(ACTION_FETCH_PROFILE);
    }
    return context.state.userProfile;
  },

  async [ACTION_UPDATE_PROFILE](context, newProfileData) {
    const resp = await axiosClient.post("/api/user/profile/", newProfileData);
    await context.commit(MUTATION_SET_PROFILE, resp.data);
    return resp;
  },

  async [ACTION_LOGOUT](context) {
    try {
      const response = await axiosClient.get("/api/user/logout/");
      return response.data;
    } finally {
      await context.commit(MUTATION_CLEAR_USERDATA);
    }
  },

  async [ACTION_ASSURE_FETCHED_DIAGNOSIS](context) {
    if (context.state.patientDiagnosis === "") {
      return context.dispatch(ACTION_FETCH_DIAGNOSIS);
    }
    return context.state.patientDiagnosis;
  },

  async [ACTION_FETCH_DIAGNOSIS](context) {
    try {
      const response = await axiosClient.get("/api/patient/diagnosis/");
      context.commit(MUTATION_SET_DIAGNOSIS, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_DIAGNOSIS, {});
      throw err;
    }
  },

  async [ACTION_FETCH_CASE](context) {
    try {
      const patientCase = await apiUserPatientCase();
      context.commit(MUTATION_SET_CASE, patientCase);
      return patientCase;
    } catch (err) {
      context.commit(MUTATION_SET_CASE, {});
      throw err;
    }
  },

  async [ACTION_ASSURE_FETCHED_CASE](context) {
    if (!context.state.patientCase) {
      return context.dispatch(ACTION_FETCH_CASE);
    }
    return context.state.patientCase;
  },

  async [ACTION_FETCH_PRIVACY](context) {
    try {
      const response = await axiosClient.get("/api/pages/privacy_policy/");
      context.commit(MUTATION_SET_PRIVACY, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_PRIVACY, {});
      throw err;
    }
  },

  async [ACTION_ASSURE_FETCHED_PRIVACY](context) {
    if (context.state.privacy === "") {
      return context.dispatch(ACTION_FETCH_PRIVACY);
    }
    return context.state.privacy;
  },

  async [ACTION_FETCH_IMPRINT](context) {
    try {
      const response = await axiosClient.get("/api/pages/imprint/");
      context.commit(MUTATION_SET_IMPRINT, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_IMPRINT, {});
      throw err;
    }
  },

  async [ACTION_ASSURE_FETCHED_IMPRINT](context) {
    if (context.state.imprint === "") {
      return context.dispatch(ACTION_FETCH_IMPRINT);
    }
    return context.state.imprint;
  },

  async [ACTION_WAITINGLIST_SIGNUP](context, data) {
    const response = await axiosClient.post("/api/waitinglist/signup/", data);
    return response.data;
  },

  async [ACTION_USER_REQUEST_PASSWORD_RESET](context, email) {
    const response = await axiosClient.post("/api/user/password_reset/request/", { email });
    return response.data;
  },

  async [ACTION_USER_PASSWORD_RESET](context, data) {
    const response = await axiosClient.post("/api/user/password_reset/", data);
    await context.commit(MUTATION_CLEAR_USERDATA);
    return response.data;
  },

  async [ACTION_PATIENT_SIGNUP_CHECK_TOKEN](context, token) {
    const response = await axiosClient.post("/api/patient/signup_check_token/", { token });
    return { token, ...response.data };
  },

  async [ACTION_PATIENT_SIGNUP](context) {
    if (context.state.patientSignup0InviteData === null) {
      throw new Error("patientSignup0InviteData not in store");
    }
    // if (context.state.patientSignup2Consent === null) {
    //   throw new Error("patientSignup2Consent not in store");
    // }
    if (context.state.patientSignupData === null) {
      throw new Error("patientSignupData not in store");
    }
    const data = {
      token: context.state.patientSignup0InviteData.token,
      password: context.state.patientSignupData.password,
      age_confirmation: context.state.patientSignupData.age_confirmation,
      agbs_confirmation: context.state.patientSignupData.agbs_confirmation,
      careety_dp_confirmation: context.state.patientSignupData.careety_dp_confirmation,
    };
    const response = await axiosClient.post("/api/patient/signup/", data);
    await context.commit(MUTATION_CLEAR_USERDATA);
    return response;
  },

  async [ACTION_FETCH_TREATMENTPLANS_TO_SEND](context) {
    try {
      const response = await axiosClient.get("/api/health_professional/treatments/");

      // convert all date/durtation-data in response to luxon
      response.data.forEach((item) => {
        item.duration = parseTimedelta(item.duration); // eslint-disable-line no-param-reassign
      });

      context.commit(MUTATION_SET_TREATMENTPLANS_TO_SEND, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_TREATMENTPLANS_TO_SEND, {});
      throw err;
    }
  },

  async [ACTION_SEND_TREATMENTPLAN](context, data) {
    const response = await axiosClient.post("/api/health_professional/invite_patient/", data);
    return response.data;
  },

  async [ACTION_PATIENT_FEEDBACK](context, data) {
    const response = await axiosClient.post("/api/patient/feedback/", data);
    // also clear all login dependant data, because feedback changes user
    // identity/logs users in as feedback user
    await context.commit(MUTATION_CLEAR_USERDATA);
    return response.data;
  },

  async [ACTION_FETCH_TERMS_PATIENT](context) {
    try {
      const response = await axiosClient.get("/api/pages/agb_patient/");
      context.commit(MUTATION_SET_TERMS_PATIENT, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_TERMS_PATIENT, {});
      throw err;
    }
  },

  async [ACTION_ASSURE_FETCHED_TERMS_PATIENT](context) {
    if (context.state.termsPatient === "") {
      return context.dispatch(ACTION_FETCH_TERMS_PATIENT);
    }
    return context.state.termsPatient;
  },

  async [ACTION_FETCH_TERMS_HEALTH_PROFESSIONAL](context) {
    try {
      const response = await axiosClient.get("/api/pages/agb_health_professional/");
      context.commit(MUTATION_SET_TERMS_HEALTH_PROFESSIONAL, response.data);
      return response.data;
    } catch (err) {
      context.commit(MUTATION_SET_TERMS_HEALTH_PROFESSIONAL, {});
      throw err;
    }
  },

  async [ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL](context) {
    if (context.state.termsHealthProfessional === "") {
      return context.dispatch(ACTION_FETCH_TERMS_HEALTH_PROFESSIONAL);
    }
    return context.state.termsHealthProfessional;
  },

  async [ACTION_ASSURE_FETCHED_PROMO_CODE](context) {
    if (context.state.promoCode !== "") {
      return context.dispatch(ACTION_FETCH_PROMO_CODE);
    }
    return context.state.promoCode;
  },
};

const state = {
  userProfile: "",
  patientDiagnosis: "",
  patientCase: null,
  privacy: "",
  waitingList: "",
  imprint: "",
  patientSignup0InviteData: null,
  patientSignup2Consent: null,
  patientSignup3Profile: null,
  patientSignupData: null,
  treatmentplans: {},
  termsPatient: "",
  termsHealthProfessional: "",
  promoCode: "",
};

const getters = {
  isAuthenticated: (stateRef) => (
    Boolean(stateRef.userProfile) && Boolean(Object.keys(stateRef.userProfile).length > 0)
  ),
};

export default createStore({
  state,
  mutations,
  actions,
  modules: {},
  getters,
});
