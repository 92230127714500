<template>
  <el-form v-loading="loading"
    class="uk-grid-collapse uk-margin-large-bottom behaviour-item uk-animation-slide-bottom-small uk-card uk-card-default uk-flex-between uk-padding"
    uk-grid :label-position="labelPosition" label-width="100px" :model="formLabelAlign">
    <div class="uk-width-4-5@m uk-width-1-1 uk-padding-remove">
      <div>
        <h3 class="drugname">{{ singleMedicationItemLocal.name }}</h3>

        <div class="uk-width-auto@s uk-flex uk-flex-start uk-flex-wrap">
          <div class="uk-width-1-2@s uk-width-1-4@m uk-width-1-6@l uk-width-1-1 c-margin-right">
            <duration-picker :is-editing="isEditing" v-model="singleMedicationItemLocal.duration">
            </duration-picker>
          </div>

          <div class="uk-width-1-2@s uk-width-1-4@m uk-width-1-6@l uk-width-1-1">
            <offset-picker :isEditing="isEditing" v-model="singleMedicationItemLocal.offset">
            </offset-picker>
          </div>
        </div>

        <div class="uk-margin-medium-bottom uk-margin-remove-top" uk-grid>
          <div class="uk-width-1-2@s">
            <el-form-item label="Dosierungshinweis">
              <el-input type="text" maxlength="80" show-word-limit :autosize="{ minRows: 2, maxRows: 4}"
                v-model="singleMedicationItemLocal.intake_hint" :disabled="!isEditing">
              </el-input>
              <span class="c-label-bottom uk-text-small">Tägliche Dosis: z.B.: max. 3 Tabletten täglich</span>
            </el-form-item>
          </div>
          <div class="uk-width-1-2@s">
            <el-form-item label="Einnhamegrund">
              <el-input type="text" maxlength="80" show-word-limit :autosize="{ minRows: 2, maxRows: 4}"
                v-model="singleMedicationItemLocal.short_clarification" :disabled="!isEditing">
              </el-input>
              <span class="c-label-bottom uk-text-small">Grund der Einnahme: z.B.: bei Schmerzen, nach dem Essen</span>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div class="editor-menu uk-width-expand uk-width-1-4@s">
      <div v-if="!isEditing" class="uk-position-top-right edit-btn-wrapper uk-position-small">
        <el-button type="text" class="edit-btn" @click="editItem()">Bearbeiten</el-button>
      </div>
      <div v-if="isEditing" class="uk-margin">
        <el-button size="small" class="cancel-btn" @click="cancelEdit()">Abbrechen</el-button>
      </div>
      <div v-if="isEditing" class="uk-margin">
        <el-button size="small" type="primary" class="save-btn" @click="save()">Speichern</el-button>
      </div>
      <div v-if="isEditing && !isNew">
        <el-popconfirm confirmButtonText="Löschen" cancelButtonText="Abbrechen" icon="el-icon-info"
          iconColor="red" title="Möchtest du dieses Element löschen?"
          @confirm="removeSingleMedicationItem">
          <template #reference>
            <el-button type="default" class="delete-btn" size="small">Löschen</el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>

  </el-form>
</template>

<script>
import { apiHpPatchMedication, apiHpDeletePrescription, apiHpAddPrescription } from "@/api";

import DurationPicker from "../../widgets/DurationPicker.vue";
import OffsetPicker from "../../widgets/OffsetPicker.vue";

export default {
  name: "MedicationItem",
  emits: ["remove-single-medication-item", "save-item"],
  props: ["singleMedicationItem", "addNewItem", "remove-object-from-array"],
  components: {
    DurationPicker,
    OffsetPicker,
  },
  data() {
    return {
      isNew: this.addNewItem,
      loading: false,
      isEditing: this.addNewItem,
      labelPosition: "top",
      singleMedicationItemLocal: {
        treatment_id: this.$route.params.id,
        drug: this.singleMedicationItem.drug,
        prescription_type: this.singleMedicationItem.prescription_type,
        quantity_morning: this.singleMedicationItem.quantity_morning,
        quantity_noon: this.singleMedicationItem.quantity_noon,
        quantity_evening: this.singleMedicationItem.quantity_evening,
        quantity_night: this.singleMedicationItem.quantity_night,
        intake_hint: this.singleMedicationItem.intake_hint,
        short_clarification: this.singleMedicationItem.short_clarification,
        full_clarification: this.singleMedicationItem.full_clarification,
        duration: this.singleMedicationItem.duration,
        offset: this.singleMedicationItem.offset,
        name: this.singleMedicationItem.name,
        name_addition: this.singleMedicationItem.name_addition,
        affiliate_url: this.singleMedicationItem.affiliate_url,
        id: this.singleMedicationItem.id,
      },
    };
  },
  methods: {
    async removeSingleMedicationItem() {
      this.loading = true;
      await setTimeout(() => {
        try {
          this.$emit("remove-single-medication-item", this.singleMedicationItemLocal);
        } catch (error) {
          console.log(error);
        }
        try {
          apiHpDeletePrescription(this.singleMedicationItem.id);
        } catch (error) {
          console.log(error);
        }
        this.$message({
          message: "Medikation entfernt",
          offset: 80,
        });
        this.loading = false;
      }, 800);
      this.isEditing = false;
    },
    editItem() {
      this.isEditing = !this.isEditing;
    },
    cancelEdit() {
      this.isEditing = !this.isEditing;
      if (this.addNewItem === true) {
        this.$emit("remove-object-from-array");
      }
    },
    async save() {
      if (this.singleMedicationItemLocal.duration.as("days") === 0) {
        await this.$message({
          message: "Dauer angeben",
          type: "error",
          offset: 80,
        });
      }
      if (this.singleMedicationItemLocal.intake_hint === "") {
        await this.$message({
          message: "Dosierungshinweis angeben",
          type: "error",
          offset: 80,
        });
      }
      if (this.singleMedicationItemLocal.short_clarification === "") {
        await this.$message({
          message: "Einnahmegrund angeben",
          type: "error",
          offset: 80,
        });
      }
      if (this.addNewItem === true && this.singleMedicationItemLocal.intake_hint !== "" && this.singleMedicationItemLocal.short_clarification !== "" && this.singleMedicationItemLocal.duration.as("days") !== 0) {
        const data = {
          treatment_id: this.$route.params.id,
          drug: this.singleMedicationItemLocal.drug,
          prescription_type: null,
          quantity_morning: 0,
          quantity_noon: 0,
          quantity_evening: 0,
          quantity_night: 0,
          intake_hint: this.singleMedicationItemLocal.intake_hint,
          short_clarification: this.singleMedicationItemLocal.short_clarification,
          full_clarification: null,
          duration: this.singleMedicationItemLocal.duration,
          offset: this.singleMedicationItemLocal.offset,
        };
        try {
          await apiHpAddPrescription(data).then((result) => {
            this.singleMedicationItemLocal.id = result.id;
          }).catch((err) => {
            console.log(err);
          });
          this.$message({
            message: "Medikation hinzugefügt",
            type: "success",
            offset: 80,
          });
        } catch (error) {
          console.log(error);
        }
        this.$emit("add-new-prescription", this.singleMedicationItemLocal);
        this.isEditing = false;
      } else if (this.addNewItem === false && this.singleMedicationItemLocal.intake_hint !== "" && this.singleMedicationItemLocal.short_clarification !== "" && this.singleMedicationItemLocal.duration.as("days") !== 0) {
        this.$emit("save-item", this.singleMedicationItemLocal);
        const data = {
          treatment_id: this.$route.params.id,
          drug_id: this.singleMedicationItemLocal.drug.id,
          prescription_type: null,
          offset: this.singleMedicationItemLocal.offset,
          duration: this.singleMedicationItemLocal.duration,
          quantity_morning: 0,
          quantity_noon: 0,
          quantity_evening: 0,
          quantity_night: 0,
          intake_hint: this.singleMedicationItemLocal.intake_hint,
          short_clarification: this.singleMedicationItemLocal.short_clarification,
          full_clarification: null,
        };
        await setTimeout(() => {
          apiHpPatchMedication(this.singleMedicationItem.id, data);
          this.$message({
            message: "Medikation geändert",
            type: "success",
            offset: 80,
          });
        }, 800);
        this.isEditing = false;
      }
    },
  },
};
</script>

<style lang="scss">
.behaviour-item .el-form-item__label {
  font-size: .88em;
  font-weight: 500;
}

.behaviour-item .c-label-bottom {
  font-size: .9em!important;
}

.drugname {
  font-size: 1.2em;
}
@media screen and (max-width: 570px) {
  .edit-btn-wrapper {
    top: -15px;
  }
}

@media screen and (min-width: 960px) {
  .c-margin-right {
    margin-right: 40px;
  }
}

@media (max-width: 959px) and (min-width: 640px) {
  .editor-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .editor-menu div {
    margin: 0 10px!important;
  }
}

</style>
