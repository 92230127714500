import { DateTime } from "luxon";
import {
  parseTimedelta,
  progress,
  progressDur,
  OffsetDurationTimeBounderies,
} from "@/utils/time";

const powerupWithProgress = (listOfObjects, startDate, nowDate) => {
  listOfObjects.forEach((obj) => {
    obj.progress = progress(startDate, nowDate, obj.offset, obj.duration); // eslint-disable-line no-param-reassign
    obj.progressDur = progressDur(startDate, nowDate, obj.offset, obj.duration); // eslint-disable-line no-param-reassign
    obj.durationDur = parseTimedelta(obj.duration); // eslint-disable-line no-param-reassign
  });
};

const totalArrayProgress = (listOfObjects, startDate, nowDate) => {
  const odtb = new OffsetDurationTimeBounderies();
  listOfObjects.forEach((obj) => {
    odtb.add(obj.offset, obj.duration);
  });
  const ret = {
    progress: odtb.progress(startDate, nowDate),
    progressAsDays: odtb.progressAsDays(startDate, nowDate),
    durationAsDays: odtb.durationAsDays(),
    offsetAsDays: odtb.offsetAsDays(),
    startDate: DateTime.fromISO(startDate).plus(odtb.offset()).toFormat("dd. LLLL", { locale: "de" }),
    startCountdownAsDays: Math.max(0, Math.floor(DateTime.fromISO(startDate).plus(odtb.offset()).diff(DateTime.local()).as("days"))),
    finishDate: DateTime.fromISO(startDate).plus(odtb.offset()).plus(odtb.duration()).toFormat("dd. LLLL", { locale: "de" }),
  };
  return ret;
};

export const patientCaseWithProgress = (origPatientCase, nowDate) => {
  if (!origPatientCase) {
    return null;
  }
  const now = nowDate || new Date();
  const patientCase = origPatientCase;
  if (patientCase.behavior_recommendations) {
    powerupWithProgress(
      patientCase.behavior_recommendations,
      patientCase.treatment_case.date,
      now,
    );
    patientCase.behavior_recommendations_overall_progress = totalArrayProgress(
      patientCase.behavior_recommendations,
      patientCase.treatment_case.date,
      now,
    );
  }
  if (patientCase.prescriptions) {
    powerupWithProgress(
      patientCase.prescriptions,
      patientCase.treatment_case.date,
      now,
    );
    patientCase.prescriptions_overall_progress = totalArrayProgress(
      patientCase.prescriptions,
      patientCase.treatment_case.date,
      now,
    );
  }
  if (patientCase.exercise_program) {
    patientCase.exercice_programs_overall_progress = totalArrayProgress(
      patientCase.exercise_program,
      patientCase.treatment_case.date,
      now,
    );
  }
  if (patientCase.treatment_case) {
    patientCase.treatment_case.progress = progress(
      patientCase.treatment_case.date,
      now,
      0,
      patientCase.treatment.duration,
    );
    patientCase.treatment_case.progressDur = progressDur(
      patientCase.treatment_case.date,
      now,
      0,
      patientCase.treatment.duration,
    );
    // TODO: remove durationDur becuase patientCase.treatment.duration is a luxon Duration now
    patientCase.treatment_case.durationDur = parseTimedelta(patientCase.treatment.duration);
  }
  return patientCase;
};

export const patientCaseWithPreview = (previewCase, previewDate) => {
  if (!previewCase) {
    return null;
  }
  const preview = previewCase;
  preview.health_professional = {};
  const dur = parseTimedelta(preview.treatment.duration);
  const start = DateTime.local().toISO();
  const finish = DateTime.local().plus(dur).toISO();
  preview.treatment_case = {
    date: start,
    date_finished: finish,
  };
  return patientCaseWithProgress(preview, previewDate);
};

export default {};
