<template>
  <footer>
    <div
      class="uk-container uk-container-xlarge uk-margin-small-bottom uk-flex uk-flex-start uk-flex-middle uk-flex-wrap"
    >
      <div class="uk-margin-medium-right uk-width-auto">
        <p class="footer-copyright">
          &#169; {{ currentYear }} careety Health GmbH, All rights reserved.
        </p>
        <div class="footer-links">
          <router-link to="/datenschutz">Datenschutz</router-link>
          <router-link to="/impressum">Impressum</router-link>
        </div>
      </div>
      <div class="uk-margin-top uk-width-auto@s uk-flex-first uk-flex-last@s">
        <a href="https://www.ffg.at/" target="_blank">
          <img
            src="@/assets/logo/ffg-logo.png"
            width="135"
            height="100"
            alt="FFG Logo"
            class="uk-animation-slide-bottom-small"
            style="animation-delay: 1000ms;"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="sass" scoped>
@import '../../assets/scss/helpers/_variables.scss';

.uk-active
  color: $c-coral-main!important
  font-weight: 500
.footer-links
  color: #909190
  a
    position: relative
    margin-right: 15px
    &::after
      content: ""
      height: 3px
      width: 3px
      right: -9px
      bottom: 5px
      background: #909190ed
      position: absolute
      border-radius: 50%
    &:last-child
      &::after
        display: none
</style>
