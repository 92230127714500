<template>
  <footer>
    <div class="uk-container uk-container-xlarge uk-margin-medium-bottom">
      <div class="c-seperator c-seperator--footer"></div>
      <p
        v-if="userProfile.is_patient === true && userProfile.is_health_professional === false"
        class="c-footer-text">
        Careety bietet keine eigenständigen medizinischen Leistungen an. Für behandlungsplanbezogene
        Aussagen über careety sind die jeweiligen Ärzte und Therapeuten selbst verantwortlich. Bei
        Fragen wende Dich bitte an Deinen behandelnden Arzt. Für eine abschließende und umfassende
        Therapie oder Behandlung ist der Besuch eines Arztes oder Therapeuten vor Ort
        beziehungsweise in dringenden Fällen einer Notfallambulanz notwendig.
      </p>
      <p class="footer-copyright">
        &#169; {{ currentYear }} careety Health GmbH, All rights reserved.
      </p>
      <FooterNav></FooterNav>
    </div>
  </footer>
</template>

<script>
import FooterNav from "./TheFooterNav.vue";

export default {
  name: "TheFooter",
  props: ["userProfile"],
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  components: {
    FooterNav,
  },
};
</script>
