<template>
  <!-- eslint-disable max-len -->
  <div class="uk-flex-center@m uk-flex-around uk-flex-middle uk-flex-wrap" uk-grid uk-height-viewport="offset-bottom: 30">
    <div class="uk-width-1-3@s uk-animation-slide-bottom-small c-responsive uk-text-center" style="animation-delay: 900ms">
      <h1><img src="@/assets/logo/careety-logo.png" width="180" alt="careety" /></h1>
      <h2>We help doctors create great patient experiences</h2>
      <p class="uk-text-lead"><strong>Sie sind interessiert?</strong><br>Dann tragen Sie sich jetzt in unsere Warteliste ein!</p>

      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        {{ successMessage }}
      </div>

      <form class="uk-form" v-on:submit.prevent>
        <fieldset class="uk-fieldset uk-margin-small">
          <input type="text" placeholder="E-Mail" class="uk-input" id="email" v-model="email" :class="{'uk-form-danger': errorMessage}" />
        </fieldset>

        <fieldset class="uk-fieldset uk-margin-small uk-text-left">
          <input type="checkbox" class="uk-checkbox" id="privacyConsent" v-model="privacyConsent" :class="{'uk-form-danger': errorMessage}" />
          <label for="privacyConsent" class="uk-form-label uk-margin-small-left" :class="{'c-text-danger': errorMessage}">Ich stimmte der <a
              href="#modal-privacy" :class="{'c-text-danger': errorMessage}" uk-toggle>Datenschutzerklärung</a> zu.</label>
        </fieldset>

        <div v-if="errorMessage" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          {{ errorMessage }}
        </div>

        <!-- TODO: dynamically add or remove disabled instead of having 2 buttons -->
        <button v-if="!Boolean(successMessage)" class="uk-button uk-button-primary uk-width-expand uk-text-capitalize uk-margin-small"
          @click="subscribe()" type="submit" value="absenden">
          Absenden
        </button>
        <button v-if="Boolean(successMessage)" class="uk-button uk-button-primary uk-width-expand uk-text-capitalize uk-margin-small"
          type="submit" value="absenden" disabled>
          Absenden
        </button>
      </form>
    </div>
  </div>

</template>

<script>
import { ACTION_WAITINGLIST_SIGNUP } from "@/store/action.type";

export default {
  name: "WaitingList",
  data() {
    return {
      email: "",
      privacyConsent: "",
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    async subscribe() {
      if (!/[^@]+@[^@]+\.[^@]+/.exec(this.email)) {
        this.errorMessage = "Bitte eine gültige E-Mail Adresse angeben.";
        return;
      }

      if (!this.privacyConsent) {
        this.errorMessage = "Um deine E-Mail Addresse speichern zu dürfen, "
        + "benötigen wir deine Zustimmung.";
        return;
      }

      try {
        await this.$store.dispatch(ACTION_WAITINGLIST_SIGNUP, {
          email: this.email,
        });
        this.successMessage = "Erfolgreich eingetragen.";
      } catch (err) {
        if (err.request && err.request.status === 409) {
          this.errorMessage = "E-Mail Adresse wurde bereits eingetragen.";
        } else if (err.request && err.request.status === 429) {
          this.errorMessage = "Zu viele Anfragen.";
        } else {
          this.errorMessage = "Eintragung fehlgeschlagen, bitte gib eine gültige E-Mail Adresse ein.";
        }
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import '../../assets/scss/helpers/_variables.scss';

form
  margin: 50px 0
  .uk-radio:checked,
  .uk-checkbox:checked,
  .uk-checkbox:indeterminate
    background-color: $c-coral-main

  .uk-radio:focus,
  .uk-checkbox:focus
    border-color: $c-coral-main

  .uk-form-danger
    border-color: #f0506e!important
  .c-text-danger
    color: #f0506e

.uk-grid
  margin-left: 0!important

  @media screen and (max-width: 640px)
    .c-responsive
      padding:0!important
      margin: 0!important
h1
    margin-bottom: 10px

h2
    margin: 32px 0 32px 0
    font-weight: 400
    font-size: 1.8rem
    line-height: 1

.uk-text-lead
  font-size: 1em
  line-height: 1.2
  color: #000!important

p
    margin-top: 5px

</style>
