<template>
  <div class="editor-component-wrapper uk-margin-large-bottom uk-width-2-4@s uk-width-1-1 uk-align-center">
    <div class="uk-margin-large-bottom">
      <h3 class="editor-title">Medikamente</h3>
      <p v-if="medications?.length" class="editor-subtitle">
        Lösche Medikamente oder wähle neue aus unserem Katalog aus.
      </p>
      <p v-else class="editor-subtitle">Im Moment sind keine Medikamente zugewiesen.</p>
    </div>

    <!-- <medications-table :medications="medications"></medications-table> -->
    <medication-item
      :single-medication-item="item"
      :is-editing="isEditing"
      v-for="item in medications"
      :key="item.name"
      @remove-single-medication-item="remove"
      @save-item="save"
      @showEdit="editItem"
      :add-new-item="false"
    ></medication-item>

    <medication-item
      :single-medication-item="item"
      :is-editing="isEditing"
      v-for="item in medicationsLocal"
      :key="item.name"
      :add-new-item="addNewItem"
      @add-new-prescription="addPrescriptionItem"
      @remove-object-from-array="removeObjectFromArray"
      @showEdit="editItem"
      @save-item="save"
      @remove-single-medication-item="remove"
    ></medication-item>

    <drug-search @add-prescription-item="addLocalMedication"></drug-search>

    <!-- <div>
      <ul v-for="item in medications" :key="item.name">
        <li class="uk-list">{{ item.full_clarification }}</li>
        <li class="uk-list">{{ item.intake_hint }}</li>
        <li class="uk-list">{{ item.name }}</li>
        <li class="uk-list">{{ item.affiliate_url }}</li>
        <li class="uk-list">{{ item.quantity_evening }}</li>
        <li class="uk-list">{{ item.quantity_morning }}</li>
        <li class="uk-list">{{ item.quantity_night }}</li>
        <li class="uk-list">{{ item.quantity_noon }}</li>
        <li class="uk-list">{{ item.short_clarification }}</li>
        <hr>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { Duration } from "luxon";
// import MedicationsTable from "./MedicationsTable.vue";
import MedicationItem from "./MedicationItem.vue";
import DrugSearch from "./DrugSearch.vue";

export default {
  name: "MedicationEditor",
  emits: ["add-medication", "remove-parent-medication", "update-parent-medication"],
  components: {
    // MedicationsTable,
    MedicationItem,
    DrugSearch,
  },
  props: {
    medications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      labelPosition: "right",
      addNewItem: false,
      isEditing: false,
      medicationsLocal: [],
    };
  },
  methods: {
    save(singleMedicationItemLocal) {
      this.$emit("update-parent-medication", singleMedicationItemLocal);
    },
    addLocalMedication(singleDrugItem) {
      this.addNewItem = true;
      this.medicationsLocal.push(
        {
          treatment_id: this.$route.params.id,
          name: singleDrugItem.drug.value,
          drug: {
            name: singleDrugItem.drug.value,
            id: singleDrugItem.drug.id,
          },
          prescription_type: "",
          quantity_morning: 0,
          quantity_noon: 0,
          quantity_evening: 0,
          quantity_night: 0,
          intake_hint: "",
          short_clarification: "",
          full_clarification: "",
          duration: Duration.fromObject({ days: this.durationValue }),
          offset: Duration.fromObject({ days: this.offsetValue }),
        },
      );
    },
    remove(singleMedicationItemLocal) {
      this.$emit("remove-parent-medication", singleMedicationItemLocal);
    },
    addPrescriptionItem(singlePrescriptionItem) {
      this.$emit("add-prescription", singlePrescriptionItem);
      this.medicationsLocal = [];
    },
    removeObjectFromArray() {
      this.medicationsLocal.splice(0, 1);
      this.$message({
        message: "Zuweisung abgebrochen",
        offset: 80,
      });
    },
  },
};
</script>
