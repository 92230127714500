import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import { ACTION_ASSURE_FETCHED_PROFILE, ACTION_ASSURE_FETCHED_PROMO_CODE } from "@/store/action.type";
import { lockdownMode } from "@/utils/cfg";

import PasswordResetRequest from "@/views/PasswordResetRequest.vue";
import SignupPatientWithToken from "@/views/SignupPatientWithToken.vue";
import PatientFeedback from "@/views/PatientFeedback.vue";
import HealthProfessionalTreatmentEdit from "@/views/HealthProfessional/TreatmentEdit.vue";
import Home from "../views/Home.vue";
import Diagnose from "../views/Diagnose.vue";
import AgbPatient from "../views/AgbPatient.vue";
import AgbHealthProfessional from "../views/AgbHealthProfessional.vue";
import Datenschutz from "../views/Datenschutz.vue";
import Impressum from "../views/Impressum.vue";
import Login from "../views/Login.vue";
import Treatment from "../views/Treatment.vue";
import Landing from "../views/Landing.vue";
import PasswordReset from "../views/PasswordReset.vue";
// import SignupPatientWizzard from "../views/SignupPatientWizzard.vue";
import NotFound from "../components/NotFound.vue";
import WaitingList from "../views/WaitingList.vue";
import StepOne from "../components/signup/StepOne.vue";
import StepTwo from "../components/signup/StepTwo.vue";
import StepThree from "../components/signup/StepThree.vue";
import HealthProfessionalDashboard from "../views/HealthProfessional/Dashboard.vue";
import HealthProfessionalTreatmentSend from "../views/HealthProfessional/TreatmentSend.vue";
import TreatmentDetails from "../components/HealthProfessional/editor/TreatmentDetails.vue";
import BehaviourEditor from "../components/HealthProfessional/editor/BehaviourEditor.vue";
import TipEditor from "../components/HealthProfessional/editor/TipEditor.vue";
import ComplicationEditor from "../components/HealthProfessional/editor/ComplicationEditor.vue";
import MedicationEditor from "../components/HealthProfessional/editor/MedicationEditor.vue";
import DiagnosisPreview from "../components/HealthProfessional/preview/Diagnosis.vue";
import SignupHealthProfessional from "../views/SignupHealthProfessional.vue";
import SignupPatient from "../views/SignupPatient.vue";

const hasPatientAccess = async () => {
  try {
    await store.dispatch(ACTION_ASSURE_FETCHED_PROFILE);
    return Boolean(store.state.userProfile) && Boolean(store.state.userProfile.is_patient);
  } catch (err) {
    return false;
  }
};

const hasHealthProfessionalAccess = async () => {
  try {
    await store.dispatch(ACTION_ASSURE_FETCHED_PROFILE);
    return Boolean(store.state.userProfile) && Boolean(store.state.userProfile.is_health_professional);
  } catch (err) {
    return false;
  }
};
const hasStaffAccess = async () => {
  try {
    await store.dispatch(ACTION_ASSURE_FETCHED_PROFILE);
    return Boolean(store.state.userProfile) && Boolean(store.state.userProfile.is_staff);
  } catch (err) {
    return false;
  }
};
const hasPromoCode = async () => {
  try {
    await store.dispatch(ACTION_ASSURE_FETCHED_PROMO_CODE);
    return (store.state.promoCode);
  } catch (err) {
    return false;
  }
};

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      availableInLockdown: true,
      healthProfessionalForward: "/hp",
      patientForward: "/treatment",
      staffForward: "/admin",
    },
  },
  {
    path: "/treatment",
    name: "Treatment",
    component: Treatment,
    meta: { requiresPatientAccess: true },
  },
  {
    path: "/diagnose",
    name: "Diagnose",
    component: Diagnose,
    meta: { requiresPatientAccess: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      healthProfessionalForward: "/hp",
      patientForward: "/treatment",
      staffForward: "/admin",
    },
  },
  {
    path: "/agb-patient",
    name: "AgbPatient",
    component: AgbPatient,
  },
  {
    path: "/agb-health-professional",
    name: "AgbHealthProfessional",
    component: AgbHealthProfessional,
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
    meta: { availableInLockdown: true },
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: { availableInLockdown: true },
  },
  {
    path: "/request-password-reset",
    name: "PasswortResetRequest",
    component: PasswordResetRequest,
    meta: {
      healthProfessionalForward: "/hp",
      patientForward: "/treatment",
      staffForward: "/admin",
    },
  },
  {
    path: "/reset/password/:token",
    name: "PasswortReset",
    component: PasswordReset,
    // TODO: this should also invalidate password reset not just
    // foward away from here
    meta: {
      healthProfessionalForward: "/hp",
      patientForward: "/treatment",
      staffForward: "/admin",
    },
  },
  {
    path: "/signup/patient/",
    name: "SignupPatientWizzard",
    component: SignupPatient,
    children: [
      {
        path: "1",
        name: "SignupStepOne",
        component: StepOne,
      },
      {
        path: "2",
        name: "SignupStepTwo",
        component: StepTwo,
      },
      {
        path: "3",
        name: "SignupStepThree",
        component: StepThree,
      },
    ],
  },
  {
    path: "/signup/patient/:token",
    name: "SignupPatinetWithToken",
    component: SignupPatientWithToken,
    meta: {
      healthProfessionalForward: "/hp",
      patientForward: "/treatment",
      staffForward: "/admin",
    },
  },
  {
    path: "/feedback/:choice/:token",
    name: "PatientFeedback",
    component: PatientFeedback,
  },

  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
    meta: { availableInLockdown: true },
  },
  {
    path: "/waitinglist",
    name: "WaitingList",
    component: WaitingList,
    meta: { availableInLockdown: true },
  },
  {
    path: "/healthprofessional/dashbaord",
    alias: "/hp",
    name: "HealthProfessionalDashboard",
    component: HealthProfessionalDashboard,
    meta: { requiresHealthProfessionalAccess: true },
  },
  {
    path: "/hp/treatment/:id([0-9]+)/edit/",
    name: "HealthProfessionalTreatmentEdit",
    component: HealthProfessionalTreatmentEdit,
    meta: { requiresHealthProfessionalAccess: true },
    props: true,
    children: [
      {
        path: "treatmentdetails",
        name: "TreatmentDetails",
        component: TreatmentDetails,
        props: true,
      },
      {
        path: "behaviour",
        name: "BehaviourEditor",
        component: BehaviourEditor,
        props: true,
      },
      {
        path: "tip",
        name: "TipEditor",
        component: TipEditor,
        props: true,
      },
      {
        path: "complication",
        name: "ComplicationEditor",
        component: ComplicationEditor,
        props: true,
      },
      {
        path: "medication",
        name: "MedicationEditor",
        component: MedicationEditor,
        props: true,
      },
    ],
  },
  // {
  //   path: "/hp/treatment/:id([0-9]+)/edit/treatmentdetails/",
  //   name: "TreatmentDetails",
  //   component: TreatmentDetails,
  //   meta: { requiresHealthProfessionalAccess: true },
  //   props: true,
  // },
  // {
  //   path: "/hp/treatment/:id([0-9]+)/edit/behaviour/",
  //   name: "BehaviourEditor",
  //   component: BehaviourEditor,
  //   meta: { requiresHealthProfessionalAccess: true },
  //   props: true,

  // },
  {
    path: "/hp/treatment/:id([0-9]+)/send/",
    name: "HealthProfessionalTreatmentSend",
    component: HealthProfessionalTreatmentSend,
    meta: { requiresHealthProfessionalAccess: true },
    props: true,
    children: [
      {
        path: "diagnosis",
        name: "DiagnosisPreview",
        component: DiagnosisPreview,
        props: true,
      },
    ],
  },
  {
    path: "/signup/",
    name: "Signup",
    component: SignupHealthProfessional,
    meta: { requiresPromoCode: true },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "uk-active",
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => Boolean(record.meta.healthProfessionalForward))) {
    if (await hasHealthProfessionalAccess()) {
      next(to.meta.healthProfessionalForward);
      return;
    }
  }
  if (to.matched.some((record) => Boolean(record.meta.patientForward))) {
    if (await hasPatientAccess()) {
      next(to.meta.patientForward);
      return;
    }
  }
  if (to.matched.some((record) => Boolean(record.meta.staffForward))) {
    if (await hasStaffAccess()) {
      next(to.meta.staffForward);
      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresPatientAccess)) {
    if (!await hasPatientAccess()) {
      next("/login");
      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresHealthProfessionalAccess)) {
    if (!await hasHealthProfessionalAccess()) {
      next("/login");
      return;
    }
  }

  if (lockdownMode) {
    if (to.matched.some((record) => !record.meta.availableInLockdown)) {
      next("/");
      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresPromoCode)) {
    if (!await hasPromoCode()) {
      next("/");
      return;
    }
  }

  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
