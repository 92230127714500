<template>
  <!-- eslint-disable max-len -->

  <div class="uk-card uk-card-default uk-padding-remove uk-width-5-6@xl uk-align-right"
    uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true">
    <div class="uk-card-header uk-flex uk-flex-between">
      <div class="uk-grid-small uk-flex-top" uk-grid>
        <img src="@/assets/icons/0007-bathtub.svg" width="45" height="45" uk-svg>
        <div class="c-card-remove-line-height">
          <h3 class="uk-card-title uk-margin-remove-bottom">To-Dos</h3>
          <span class="c-card-title-day">Heute</span>
        </div>
      </div>
    </div>

    <el-tooltip content="Mehr Informationen" placement="top" v-if="modal">
      <div class="svg-icon uk-position uk-position-top-right uk-position-small">
        <a href="#behavior-modal" uk-toggle class="card--info-btn">
          <img src="@/assets/icons/custom/information-icon-custom.svg" width="15" height="15"
            uk-svg /></a>
      </div>
    </el-tooltip>

    <div class="uk-card-body" v-if="behaviourActive.length">
      <div class="c-behavior-wrapper uk-grid-small uk-grid-match uk-flex-middle"
        v-for="behavior in behaviourActive" :key="behavior" uk-grid>
        <el-tooltip
          :content="`Tag ${Math.ceil(behavior.progressDur.as('days'))} von ${Math.ceil(behavior.durationDur.as('days'))}`"
          placement="top">
          <div class="uk-width-auto c-behavior-pb">
            <radial-progress-bar-small v-bind:completed-steps="28 * behavior.progress">
            </radial-progress-bar-small>
          </div>
        </el-tooltip>
        <div class="uk-width-expand">
          <p class="c-behavior-text">{{ behavior.text }}</p>
        </div>
      </div>
    </div>

    <div class="uk-card-body" v-else>
      <p class="c-behavior-text uk-text-center">Im Moment sind keine To-Dos aktiv</p>
    </div>

    <div class="uk-card-body uk-padding-remove-bottom" v-if="behaviourNotActive.length">
    <el-collapse accordion>
    <el-collapse-item title="Startet in Kürze" name="1">
          <div class="c-behavior-wrapper uk-grid-small uk-grid-match uk-flex-middle uk-margin-top" v-for="behavior in behaviourNotActive" :key="behavior" uk-grid>
            <div class="uk-width-auto c-behavior-pb">
            <radial-progress-bar-small v-bind:completed-steps="28 * behavior.progress"></radial-progress-bar-small>
          </div>
            <div class="uk-width-expand">
              <p class="c-behavior-text">{{ behavior.text }}</p>
            </div>
        </div>
    </el-collapse-item>
    </el-collapse>
  </div>

  <div class="uk-card-body uk-padding-remove-top uk-padding-remove-bottom c-behavior-finished" v-if="behaviourFinished.length">
    <el-collapse accordion>
    <el-collapse-item title="Abgeschlossen" name="1">
          <div class="c-behavior-wrapper uk-grid-small uk-grid-match uk-flex-middle uk-margin-top" v-for="behavior in behaviourFinished" :key="behavior" uk-grid>
            <div class="uk-width-auto c-behavior-pb">
                <img src="@/assets/icons/custom/finished-circle.svg" width="24" height="24" uk-svg />
            </div>
            <div class="uk-width-expand">
              <p class="c-behavior-text">{{ behavior.text }}</p>
            </div>
        </div>
    </el-collapse-item>
    </el-collapse>
  </div>

  <div class="uk-card-body">
       <div class="c-progress-wrapper uk-margin-bottom">
        <div class="uk-text-right c-progress-text-wrapper">
          <p class="c-progress-day uk-margin-remove" v-if="patientCase.behavior_recommendations_overall_progress.progress === 1">
            Abgeschlossen
          </p>
          <p v-else class="c-progress-day uk-margin-remove">Tag
            {{ patientCase.behavior_recommendations_overall_progress.progressAsDays }} von
            {{ patientCase.behavior_recommendations_overall_progress.durationAsDays }}</p>
        </div>

        <el-tooltip
          :content="`Sie sind bei Tag ${patientCase.behavior_recommendations_overall_progress.progressAsDays} von ${ patientCase.behavior_recommendations_overall_progress.durationAsDays }`"
          placement="top">
          <progress id="js-progressbar" class="uk-progress uk-margin-remove"
            v-bind:value="100*patientCase.behavior_recommendations_overall_progress.progress" max="100">
          </progress>
        </el-tooltip>
      </div>
    </div>

</div>

  <div id="behavior-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-border-rounded uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-grid-small" uk-grid>
        <div class="uk-width-auto@s">
          <img src="@/assets/icons/0007-bathtub.svg" class="uk-margin-right c-svg-dark" width="30"
            height="30" uk-svg />
        </div>
        <div class="uk-width-4-5@s">
          <h3 class="uk-margin-remove">To-Dos</h3>
          <p class="uk-margin-remove c-treatment-start">
            {{ patientCase.behavior_recommendations_overall_progress.durationAsDays }} Tage</p>
          <p class="uk-margin-remove-top uk-margin-small-bottom c-treatment-duration">
            {{ patientCase.behavior_recommendations_overall_progress.startDate }}
            –
            {{ patientCase.behavior_recommendations_overall_progress.finishDate }}
          </p>
          <div class="uk-margin-remove" v-html="patientCase.treatment.med_aids_explainer "></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { DateTime } from "luxon";
import { filterArrayOffDurCurrentlyActive, filterArrayOffDurNotYetActive, filterArrayOffDurAlreadyFinished } from "@/utils/time";
import RadialProgressBarSmall from "../../UI/RadialProgressSmall.vue";

export default {
  name: "BehaviorCard",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    modal: {
      type: Boolean,
      required: true,
      default: true,
    },
    nowDate: {
      type: Object,
      default: DateTime.local(),
    },
  },
  computed: {
    behaviourActive() {
      return filterArrayOffDurCurrentlyActive(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.behavior_recommendations,
      );
    },
    behaviourNotActive() {
      return filterArrayOffDurNotYetActive(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.behavior_recommendations,
      );
    },
    behaviourFinished() {
      return filterArrayOffDurAlreadyFinished(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.behavior_recommendations,
      );
    },
  },
  components: {
    RadialProgressBarSmall,
  },
};
</script>

<style lang="sass">
.el-collapse-item__header,
.el-collapse,
.el-collapse-item__wrap
    border-color: transparent
.el-collapse-item__header
    font-size: .88em
    font-weight: 600
.c-behavior-finished
  p
    font-size: 1.06em

.c-behavior-wrapper
    margin-bottom: 24px

.c-gradient
    border: 1px solid #f8f8f8
    padding: 1em 0
    border-radius: 12px

.c-behavior-text
    font-weight: 400
    font-size: .95rem
    line-height: 1.3

.c-preview-item
  border: 1px solid #f8f8f8
  border-radius: 12px
  padding: 1em 0
  .c-preview-text
    font-size: .94em
    font-weight: 500
    text-align: center
  .c-behavior-pb
    opacity: .3

</style>
