<template>
  <div id="step1">
    <div class="uk-child-width-expand@s c-signup-wrapper uk-grid-large" uk-grid>
      <div
        class="uk-grid-small"
        uk-grid
        uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
        style="animation-delay: 400ms"
      >
        <div class="uk-width-auto">
          <img
            src="@/assets/icons/0493-first-aid.svg"
            class="uk-margin-right"
            width="35"
            height="35"
            uk-svg
          />
        </div>
        <div class="uk-width-4-5@s uk-margin-large-bottom">
          <h3>Willkommen bei Ihrem Therapieplan </h3>
          <div v-if="introText" v-html="introText"></div>
          <div v-else>
            <p>
              Hier erfahren Sie alles, was Sie über Ihre Diagnose und das von mir empfohlene
              Therapiekonzept wissen müssen.
            </p>
            <p>
              Mit Ihrem Feedback und Ihren Interaktionen helfen Sie uns die Effektivität des Konzepts
              zu ermitteln und dieses zu verbessern. Sollten Informationen in diesem Therapieplan von
              meinen mündlichen Empfehlungen oder Empfehlungen in Befund oder Rezept abweichen gelten
              letztere.
            </p>
          </div>
          <p><strong>Alles Gute! </strong></p>
        </div>
      </div>

      <div
        uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
        style="animation-delay: 800ms"
      >
        <img
          class="uk-border-rounded uk-box-shadow-medium uk-margin-large-bottom"
          src="@/assets/img/signup/signup-01.jpg"
          alt="Ihr digitaler Therapieplan"
        />
      </div>
    </div>

    <div
      uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
      style="animation-delay: 1000ms"
    >
      <button
        class="uk-button uk-button-primary uk-width-small uk-align-center uk-text-capitalize"
        type="button"
        @click="checkForm1()"
        :disabled="!token"
      >
        Weiter
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignupStepOne",
  data() {
    return {
      token: "ABC12345",
      introText: "",
    };
  },
  methods: {
    checkForm1() {
      if (this.token !== "") {
        console.log("OK");
        this.$router.push("/signup/patient/2");
      }
    },
  },
  mounted() {
    if (this.$store.state.patientSignup0InviteData) {
      this.introText = this.$store.state.patientSignup0InviteData.introductionary_text;
    }
  },
};
</script>

<style></style>
