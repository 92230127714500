import { createApp } from "vue";
import VueMatomo from "vue-matomo";
import "./assets/scss/main.scss";
import jQuery from "jquery";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import ElementPlus from "element-plus";
import { matomoSiteId } from "@/utils/cfg";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import store from "./store";
import router from "./router";

// Import Components
import App from "./App.vue";
import CareetyNav from "./components/header/TheNavigation.vue";
import CareetyHeader from "./components/header/TheHeader.vue";
import CareetyFooter from "./components/footer/TheFooter.vue";
import FooterNav from "./components/footer/TheFooterNav.vue";
import TreatmentOverview from "./components/TreatmentOverview.vue";
import MyPersonalBox from "./components/mypersonalbox/MyPersonalBox.vue";
import GreetPatientCard from "./components/mypersonalbox/cards/GreetPatientCard.vue";
import SlimHeader from "./components/header/SlimHeader.vue";

const $ = jQuery;
window.$ = $;

// import { Bar } from "vue-chartjs"

// Use UIkit and set Kit
UIkit.use(Icons);
window.UIkit = UIkit;

// Create App
const app = createApp(App);

// Use Bar
// app.use(Bar);

// Use Element Plus
app.use(ElementPlus);

// Use plyr
app.use(VuePlyr);

// Use CKEditor
app.use(CKEditor);

// Use Routing
app.use(router);

// Use Store
app.use(store);

// configure and use vue-matomo
app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: "https://careety.matomo.cloud",
  siteId: matomoSiteId,

  // Enables automatically registering pageviews on the router
  router: router, // eslint-disable-line object-shorthand

  // Enables link tracking on regular links. Note that this won"t
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: true,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  // enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 5,

  // Changes the default .js and .php endpoint"s filename
  // Default: "matomo"
  // trackerFileName: "piwik",

  // Overrides the tracker endpoint entirely
  // Default: undefined
  trackerUrl: undefined,

  // Overrides the tracker script path entirely
  // Default: undefined
  trackerScriptUrl: undefined,

  // Whether or not to log debug information
  // Default: false
  debug: true,

  // User ID
  // Default: undefined
  userId: undefined,

  preInitActions: [
    ["setCustomVariable", "1", "VisitorType", "Member"],
  ],
});

// Register Components
app.component("careety-navbar", CareetyNav);
app.component("careety-header", CareetyHeader);
app.component("careety-footer", CareetyFooter);
app.component("footer-nav", FooterNav);
app.component("treatment-overview", TreatmentOverview);
app.component("my-personal-box", MyPersonalBox);
app.component("greet-patient-card", GreetPatientCard);
app.component("slim-header", SlimHeader);

// Mount App
app.mount("#app");
