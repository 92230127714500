<template>
  <div class="footer-links">
    <router-link to="/agb-patient" v-if="userProfile.is_patient">AGBs für PatientInnen</router-link>
    <router-link to="/agb-health-professional" v-if="userProfile.is_health_professional">AGBs</router-link>
    <router-link to="/datenschutz">Datenschutz</router-link>
    <router-link to="/impressum">Impressum</router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheFooterNavigation",
  computed: {
    ...mapState(["userProfile"]),
  },
};
</script>

<style lang="sass" scoped>
@import '../../assets/scss/helpers/_variables.scss';

.uk-active
  color: $c-coral-main!important
  font-weight: 500
.footer-links
  color: #909190
  a
    position: relative
    margin-right: 15px
    &::after
      content: ""
      height: 3px
      width: 3px
      right: -9px
      bottom: 5px
      background: #909190ed
      position: absolute
      border-radius: 50%
    &:last-child
      &::after
        display: none
</style>
