<template>
    <login></login>
</template>

<script>
import Login from "../components/login/Login.vue";

export default {
  name: "LoginView",
  components: {
    Login,
  },
};
</script>

<style>
</style>
