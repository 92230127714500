import axios from "axios";
import { parseTimedelta } from "@/utils/time";

const axiosClient = axios.create({
  // baseURL: "",
  // timeout: 1000,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken",
});

const convertCaseTimesToLuxon = (newCase) => {
  if (newCase?.treatment?.duration) {
    newCase.treatment.duration = parseTimedelta(newCase.treatment.duration); // eslint-disable-line no-param-reassign
  }
  if (newCase?.behavior_recommendations) {
    newCase.behavior_recommendations.forEach((behav) => {
      behav.duration = parseTimedelta(behav.duration); // eslint-disable-line no-param-reassign
      behav.offset = parseTimedelta(behav.offset); // eslint-disable-line no-param-reassign
    });
  }
  if (newCase?.prescriptions) {
    newCase.prescriptions.forEach((presc) => {
      presc.duration = parseTimedelta(presc.duration); // eslint-disable-line no-param-reassign
      presc.offset = parseTimedelta(presc.offset); // eslint-disable-line no-param-reassign
    });
  }
  // TODO: convert all not yet converted timestamps and durations too:
  //       exercises, ...
};

export const apiUserPasswordResetCheckToken = async (token) => {
  const resp = await axiosClient.post("/api/user/password_reset/check_token/", { token });
  return { token, ...resp.data };
};

export const apiUserPatientCase = async () => {
  const response = await axiosClient.get("/api/patient/case/");
  convertCaseTimesToLuxon(response.data);
  return response.data;
};

export const apiGetHpTreatmentsCasePreview = async (treatmentId) => {
  const resp = await axiosClient.get(`/api/health_professional/treatments/${treatmentId}/case_preview/`);
  convertCaseTimesToLuxon(resp.data);
  return resp.data;
};

export const apiGetHpDiagnosis = async (diagnosisId) => {
  const resp = await axiosClient.get(`/api/health_professional/treatments/${diagnosisId}/diagnosis/`);
  return resp.data;
};

export const apiHpTreatmentCopy = async (id) => {
  const resp = await axiosClient.get(`/api/health_professional/treatments/${id}/duplicate`);
  return resp.data;
};

export const apiHpUpdateTreatment = async (treatmentId, data) => {
  const resp = await axiosClient.post(`/api/health_professional/treatments/${treatmentId}/`, data);
  return resp.data;
};

export const apiHpPatchBehaviourRecommendation = async (behaviourId, data) => {
  const param = { text: data.text, offset: data.offset.as("seconds"), duration: data.duration.as("seconds") };
  const resp = await axiosClient.patch(`/api/health_professional/behavior_recommendations/${behaviourId}/`, param);
  return resp.data;
};

export const apiHPDeleteBehaviourRecommendation = async (behaviourId) => {
  const resp = await axiosClient.delete(`/api/health_professional/behavior_recommendations/${behaviourId}/`);
  return resp.data;
};

export const apiHpPatchTip = async (tipId, data) => {
  const resp = await axiosClient.patch(`/api/health_professional/tips/${tipId}/`, data);
  return resp.data;
};

export const apiHPDeleteTip = async (tipId) => {
  const resp = await axiosClient.delete(`/api/health_professional/tips/${tipId}/`);
  return resp.data;
};

export const apiHpGetComplications = async (treatmentId) => {
  const resp = await axiosClient.get(`/api/health_professional/treatments/${treatmentId}/complications/`);
  return resp.data;
};

export const apiHpSearchComplications = async () => {
  const resp = await axiosClient.get("/api/health_professional/complications/all");
  return resp.data;
};

export const apiHpUpdateComplications = async (complicationId, data) => {
  const resp = await axiosClient.post(`/api/health_professional/treatments/${complicationId}/complications/`, data);
  return resp.data;
};

export const apiHpAddBehaviourRecommendations = async (data) => {
  const param = {
    treatment_id: data.treatment_id, text: data.text, id: data.id, offset: data.offset.as("seconds"), duration: data.duration.as("seconds"),
  };
  const resp = await axiosClient.post("/api/health_professional/behavior_recommendations/", param);
  return resp.data;
};

export const apiHpAddTips = async (data) => {
  const resp = await axiosClient.post("/api/health_professional/tips/", data);
  return resp.data;
};

export const apiHpPatchMedication = async (prescriptionId, data) => {
  const param = {
    offset: data.offset.as("seconds"),
    duration: data.duration.as("seconds"),
    treatment_id: data.treatment_id,
    drug_id: data.drug_id,
    prescription_type: data.prescription_type,
    quantity_morning: data.quantity_morning,
    quantity_noon: data.quantity_noon,
    quantity_evening: data.quantity_evening,
    quantity_night: data.quantity_night,
    intake_hint: data.intake_hint,
    short_clarification: data.short_clarification,
    full_clarification: data.full_clarification,
  };
  const resp = await axiosClient.patch(`/api/health_professional/prescriptions/${prescriptionId}/`, param);
  return resp.data;
};

export const apiHpDeletePrescription = async (prescriptionId) => {
  const resp = await axiosClient.delete(`/api/health_professional/prescriptions/${prescriptionId}/`);
  return resp.data;
};

export const apiHpDrugSearch = async (data) => {
  const resp = await axiosClient.post("/api/health_professional/drugs/search/", data);
  return resp.data;
};

export const apiHpAddPrescription = async (data) => {
  const param = {
    treatment_id: data.treatment_id,
    drug_id: data.drug.id,
    prescription_type: data.prescription_type,
    offset: data.offset.as("seconds"),
    duration: data.duration.as("seconds"),
    quantity_morning: data.quantity_morning,
    quantity_noon: data.quantity_noon,
    quantity_evening: data.quantity_evening,
    quantity_night: data.quantity_night,
    intake_hint: data.intake_hint,
    short_clarification: data.short_clarification,
    full_clarification: data.full_clarification,
  };
  const resp = await axiosClient.post("/api/health_professional/prescriptions/", param);
  return resp.data;
};

export const apiPatientFeedback = async (data) => {
  const resp = await axiosClient.post("/api/patient/treatment/rating/", data);
  return resp.data;
};

export const apiHpSignup = async (data) => {
  const param = {
    campaign_pin: data.campaign_pin,
    email: data.email,
    password: data.password,
    name_prefix: data.name_prefix,
    forename: data.forename,
    surname: data.surname,
    name_postfix: data.name_postfix,
    agbs_confirmation: data.agbs_confirmation,
    careety_dp_confirmation: data.careety_dp_confirmation,
    careety_dpa_confirmation: data.careety_dpa_confirmation,
    newsletter_choice: data.newsletter_choice,
  };
  const resp = await axiosClient.post("/api/health_professional/signup/", param);
  return resp.data;
};

export const apiHpFeedback = async (data) => {
  const resp = await axiosClient.post("/api/health_professional/feedback/", data);
  return resp.data;
};

export default {};
