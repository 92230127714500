import Cookies from "js-cookie";

// Patient Cookie for Feedbacks
const PATIENT_FEEDBACK_POPUP = "patientFeedbackPopup";

export const patientFeedbackPopupCheckandPropagate = () => {
  if (Cookies.get(PATIENT_FEEDBACK_POPUP) === "feedbackTrue") {
    return false;
  }
  if (Cookies.get(PATIENT_FEEDBACK_POPUP) === "feedbackFalse") {
    return false;
  }
  return true;
};

export const patientFeedbackPopupTrue = () => {
  Cookies.set(PATIENT_FEEDBACK_POPUP, "feedbackTrue", { expires: 365, sameSite: "Strict" });
};

export const patientFeedbackPopupFalse = () => {
  Cookies.set(PATIENT_FEEDBACK_POPUP, "feedbackFalse", { expires: 3, sameSite: "Strict" });
};

// Health Professional Cookie for Feedbacks
const HEALTH_PROFESSIONAL_FEEDBACK_POPUP = "healthProfessionalFeedbackPopup";

export const healthProfessionalFeedbackPopupCheckandPropagate = () => {
  if (Cookies.get(HEALTH_PROFESSIONAL_FEEDBACK_POPUP) === "feedbackTrue") {
    return false;
  }
  if (Cookies.get(HEALTH_PROFESSIONAL_FEEDBACK_POPUP) === "feedbackFalse") {
    return false;
  }
  return true;
};

export const healthProfessionalFeedbackPopupTrue = () => {
  Cookies.set(HEALTH_PROFESSIONAL_FEEDBACK_POPUP, "feedbackTrue", { expires: 365, sameSite: "Strict" });
};

export const healthProfessionalFeedbackPopupFalse = () => {
  Cookies.set(HEALTH_PROFESSIONAL_FEEDBACK_POPUP, "feedbackFalse", { expires: 3, sameSite: "Strict" });
};
export default {};
