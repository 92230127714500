export const MUTATION_SET_PROFILE = "mutationSetProfile";
export const MUTATION_SET_DIAGNOSIS = "mutationSetDiagnosis";
export const MUTATION_SET_CASE = "mutationSetCase";
export const MUTATION_SET_PRIVACY = "mutationSetPrivacy";
export const MUTATION_SET_IMPRINT = "mutationSetImprint";
export const MUTATION_SET_PATIENT_SIGNUP_0_INVITE_DATA = "mutationSetPatientSignup0InviteData";
export const MUTATION_SET_PATIENT_SIGNUP_2_CONSENT = "mutationSetPatientSignup2Consent";
export const MUTATION_SET_PATIENT_SIGNUP_3_PROFILE = "mutationSetPatientSignup3Profile";
export const MUTATION_CLEAR_PATIENT_SIGNUP = "mutateionClearPatientSignup";
export const MUTATION_SET_TREATMENTPLANS_TO_SEND = "mutationSetTreatmentplansToSend";
export const MUTATION_SET_TERMS_PATIENT = "mutationSetTermsPatient";
export const MUTATION_SET_TERMS_HEALTH_PROFESSIONAL = "mutationSetTermsHealthProfessional";
export const MUTATION_CLEAR_USERDATA = "mutationsClearUserdata";
export const MUTATION_SET_PROMO_CODE = "mutationSetPromoCode";
export const MUTATION_SET_PATIENT_SIGNUP_DATA = "mutationSetPatientSignupData";
