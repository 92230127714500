<template>
  <!-- TODO: add a loading animation/spinner or something -->

  <div
    class="uk-flex uk-height-medium uk-background-default uk-margin uk-padding-large"
    uk-height-viewport="offset-bottom: 30"
  >
    <div class="uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s">
      <div class="uk-width-large uk-margin-auto uk-margin-top">
        <div class="uk-text-center uk-margin-medium uk-animation-fade">
          <img src="@/assets/logo/careety-logo.png" uk-svg width="160" alt="careety Logo" />
        </div>
        <div
          class="uk-card uk-card-default uk-card-large uk-padding uk-animation-slide-bottom-medium"
          style="animation-delay: 500ms"
          :class="{ largeMarginBottom : feedbackState === 'success' }"
        >
          <h1 class="uk-text-bold uk-text-center c-login uk-margin-medium-bottom">
            Patienten Feedback
          </h1>
          <p v-if="feedbackState === 'loading'">
            <!-- TODO: animation goes here -->
            loading ...
          </p>
          <div v-if="feedbackState === 'success'">
            <p class="uk-alert-success uk-border-rounded" uk-alert>
              Danke für Ihr Feedback. <a href="/">Weiter zu careety</a>
            </p>
            <div
              v-if="reviewUrlAvailable && $route.params.choice === '5' || $route.params.choice === '4' "
              class="uk-margin-large-top c-review-wrapper uk-text-center"
            >
              <h5>Wir würden uns über eine Bewertung sehr freuen!</h5>
              <a
                v-if="patientCase?.health_professional?.google_review_url"
                :href="patientCase.health_professional.google_review_url"
                class="uk-button uk-button-primary uk-margin-bottom uk-width-expand"
                target="_blank"
                >Auf Google bewerten</a
              >
              <a
                v-if="patientCase?.health_professional?.docfinder_review_url"
                :href="patientCase.health_professional.docfinder_review_url"
                class="uk-button uk-button-primary uk-width-expand"
                target="_blank"
                >Auf Docfinder bewerten</a
              >
            </div>
          </div>

          <div
            v-if="feedbackState === 'fail'"
            class="uk-alert uk-padding-small uk-border-rounded"
            uk-alert
          >
            Vielen Dank, wir haben Ihr Feedback bereits erfasst &#128077;
            <div><a href="/">Weiter zu careety</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ACTION_PATIENT_FEEDBACK, ACTION_ASSURE_FETCHED_CASE } from "@/store/action.type";

export default {
  name: "PatientFeedback",
  computed: {
    ...mapState(["patientCase"]),
    reviewUrlAvailable() {
      return (
        Boolean(this.patientCase?.health_professional?.google_review_url)
        || Boolean(this.patientCase?.health_professional?.docfinder_review_url)
      );
    },
  },
  data() {
    return {
      feedbackState: "loading",
    };
  },
  async mounted() {
    const data = {
      token: this.$route.params.token,
      choice: this.$route.params.choice,
    };
    try {
      await this.$store.dispatch(ACTION_PATIENT_FEEDBACK, data);
      this.$store.dispatch(ACTION_ASSURE_FETCHED_CASE);
      this.feedbackState = "success";
    } catch (err) {
      this.feedbackState = "fail";
    }
  },
};
</script>

<style lang="sass">
.largeMarginBottom
    margin-bottom: 50px!important

.c-review-wrapper
  a
    text-transform: none
  h5
    font-size: 1.08em
</style>
