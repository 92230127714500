<template>
  <slim-header></slim-header>
    <div class="uk-container uk-container-xlarge uk-padding-large" v-html="termsHealthProfessional.content"></div>
  <careety-footer :user-profile="userProfile"></careety-footer>
</template>

<script>
import { ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL } from "@/store/action.type";
import { mapState } from "vuex";

export default {
  name: "TermsHealthProfessional",
  props: {
    userProfile: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL);
    } catch (err) {
      // TODO: handle errors
    }
  },
  computed: {
    ...mapState(["termsHealthProfessional"]),
  },
};
</script>
