<template>
  <preview-header
    :treatment="patientCase?.treatment"
    @showSendDialog="openSendDialog"
    :preview-progress-max-days="previewProgressMaxDays"
    @update-view="progressDayChanged"
  >
  </preview-header>

  <el-dialog
    custom-class="send-treatment-modal uk-text-center uk-padding"
    v-model="dialogVisible"
    width="100%"
    :destroy-on-close="true"
    @close-quick-send-dialog="closeDialog"
  >
    <send-plan-modal
      :patient-case="patientCase"
      @close-quick-send-dialog="closeDialog"
    ></send-plan-modal>
  </el-dialog>

    <treatment-overview
    v-if="$route.name === 'HealthProfessionalTreatmentSend'"
    :patientCase="patientCase"
    :userProfile="userProfile"
    :nowDate="previewDate"
    :modal="true"
  ></treatment-overview>

  <diagnosis
    v-if="$route.name === 'DiagnosisPreview'"
    ></diagnosis>
</template>

<script>
import { DateTime } from "luxon";
import { ACTION_ASSURE_FETCHED_PROFILE } from "@/store/action.type";

import { apiGetHpTreatmentsCasePreview } from "@/api";
import SendPlanModal from "../../components/HealthProfessional/SendPlanModal.vue";
import { patientCaseWithPreview } from "../../utils/progress";
import Diagnosis from "../../components/HealthProfessional/preview/Diagnosis.vue";
import PreviewHeader from "../../components/HealthProfessional/preview/PreviewHeader.vue";

export default {
  name: "TreatmentSendPreview",
  components: {
    SendPlanModal,
    Diagnosis,
    PreviewHeader,
  },
  data() {
    return {
      errorCode: null,
      patientCase: null,
      previewDate: DateTime.local().plus({ minutes: 1 }),
      userProfile: null,
      previewProgressDay: null,
      previewProgressMaxDays: null,
      dialogVisible: false,
    };
  },
  async mounted() {
    try {
      this.userProfile = await this.$store.dispatch(
        ACTION_ASSURE_FETCHED_PROFILE,
      );
      const previewCase = await apiGetHpTreatmentsCasePreview(
        this.$route.params.id,
      );
      const previewCaseWithProgress = patientCaseWithPreview(
        previewCase,
        this.previewDate,
      );
      this.previewProgressDay = 1;
      this.previewProgressMaxDays = previewCaseWithProgress.treatment_case.durationDur.as(
        "days",
      );
      this.patientCase = previewCaseWithProgress;
    } catch (err) {
      if (err.response?.data?.err) {
        this.errorCode = err.response.data.err;
      } else {
        this.errorCode = -1; // display generic error message
      }
    }
  },
  methods: {
    openSendDialog() {
      this.dialogVisible = true;
    },
    async progressDayChanged(previewProgressDayLocal) {
      this.previewProgressDay = previewProgressDayLocal;
      try {
        this.previewDate = DateTime.local().plus({
          hours:
            24 * (this.previewProgressDay - 1)
            + 24 * ((this.previewProgressDay - 1) / this.previewProgressMaxDays),
          minutes: 1,
        });
        await patientCaseWithPreview(this.patientCase, this.previewDate);
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$message({
        message: "Therapieplan wurde erfolgreich versendet.",
        type: "success",
      });
    },
  },
};
</script>

<style lang="scss">
.view-wrapper {
  padding-top: calc(2% + 50px);
}

.send-header {
  background: #fff;
  z-index: 1;
  padding: 16px 24px;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.04);
  position: fixed;
  box-sizing: border-box;
  width: 100%;
}

.preview-treatment--title {
  margin: 0;
  font-size: 1.2em;
  line-height: 1;
}

.action--item {
  padding: 0 10px;
}
.action--item:first-child {
  padding: 0;
}

.send-header h5,
.el-page-header__content {
  font-size: 0.9em;
  font-weight: 600;
}

.send-header h5 {
  margin-bottom: 5px;
  display: inline-block;
}

.send-header p {
  font-size: 0.8em;
  line-height: 1.4;
  margin: 5px 0;
}

.view-navbar .uk-navbar-nav > li > a {
  min-height: 20px;
  text-transform: none;
}

.view-navbar .uk-navbar-nav > li > a:first-child {
  padding-left: 0;
}

.preview-progress--wrapper h5 {
  font-size: 0.9em;
  margin: 0;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #4a4a4a;
}

.preview-progress--wrapper p {
  font-size: 0.84em;
  margin: 0;
}

.preview-progress-input--wrapper {
  margin-top: 8px;
}
.preview-days-wrapper {
  font-size: 0.8em;
  white-space: nowrap;
}

@media screen and (max-width: 1200px) {
  .view-wrapper {
    padding-top: calc(2% + 80px);
  }
}

@media screen and (max-width: 1057px) {
  .view-wrapper {
    padding-top: calc(2% + 100px);
  }
}

@media screen and (max-width: 960px) {
  .preview-treatment--title {
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .action--item {
    margin-bottom: 20px;
    padding: 0;
  }
  .send-header {
    position: static !important;
  }
  .view-wrapper {
    padding-top: 0px;
  }
}
</style>
