<template>
  <diagnose :patient-diagnosis="previewDiagnosis"></diagnose>
</template>

<script>
import { apiGetHpDiagnosis } from "@/api";
import Diagnose from "../../diagnosis/Diagnosis.vue";

export default {
  name: "Diagnosis",
  components: {
    Diagnose,
  },
  data() {
    return {
      previewDiagnosis: null,
    };
  },
  async mounted() {
    try {
      const response = await apiGetHpDiagnosis(this.$route.params.id);
      this.previewDiagnosis = response;
    } catch (err) {
      if (err.response?.data?.err) {
        this.errorCode = err.response.data.err;
      } else {
        this.errorCode = -1; // display generic error message
      }
    }
  },
};
</script>

<style>

</style>
