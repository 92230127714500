import Cookies from "js-cookie";

const paqPush = (item) => {
  // using setTimeout as a workaround here, because on page-reloads vue-matomo
  // usually takes rather long to load and most components (including
  // consent banners) already got rendered before matomo is ready
  setTimeout(() => {
    if (!window._paq) { // eslint-disable-line no-underscore-dangle
      window._paq = []; // eslint-disable-line no-underscore-dangle
    }
    window._paq.push([item]); // eslint-disable-line no-underscore-dangle
  }, 1000);
};

const MATOMO_CONSENT_COOKIE = "matomoConsent";

/**
 *  Run this all parts of the app which are tracked by matomo
 */
export const matomoCheckConsentUndecidedAndPropagate = () => {
  if (Cookies.get(MATOMO_CONSENT_COOKIE) === "granted") {
    paqPush("setConsentGiven");
    return false;
  }
  if (Cookies.get(MATOMO_CONSENT_COOKIE) === "denied") {
    return false;
  }
  return true;
};

export const matomoGrantConsentNow = () => {
  Cookies.set(MATOMO_CONSENT_COOKIE, "granted", { expires: 30 * 13, sameSite: "Strict" });
  // workaround because rememberConsentGiven is not always reliably removes this cookie.
  // (sameSite is a workaround for: https://github.com/js-cookie/js-cookie/issues/672)
  if (typeof Cookies.get("mtm_consent_removed") !== "undefined") {
    Cookies.remove("mtm_consent_removed", { sameSite: "strict" });
  }
  paqPush("setConsentGiven");
};

export const matomoDenyConsentNow = () => {
  Cookies.set(MATOMO_CONSENT_COOKIE, "denied", { expires: 30 * 13, sameSite: "Strict" });
  paqPush("forgetConsentGiven");
};

/**
* Check / Set HP Terms Cookie
*/

const HEALTH_PROFESSIONAL_CONSENT_COOKIE = "hpReadTermsAndConsent";

export const healthProfessionalConsentTermsCheckandProagate = () => {
  if (Cookies.get(HEALTH_PROFESSIONAL_CONSENT_COOKIE) === "granted") {
    return false;
  }
  if (Cookies.get(HEALTH_PROFESSIONAL_CONSENT_COOKIE) === "denied") {
    return false;
  }
  return true;
};

export const healthProfessionalConsentTerms = () => {
  Cookies.set(HEALTH_PROFESSIONAL_CONSENT_COOKIE, "granted", { expires: 30 * 13, sameSite: "Strict" });
};

export default {};
