<template>
    <div class="uk-margin-bottom">
    <div class="c-intro-wrapper uk-grid-small uk-margin-medium-bottom" uk-grid>
      <div class="uk-width-auto@s">
        <img src="@/assets/icons/0166-eye.svg"
          class="uk-margin-right c-svg-dark c-custom-margin-top-small" width="30" height="30"
          uk-svg />
      </div>
      <div class="uk-width-4-5@s">
        <h2 class="uk-margin-remove">Ihr Therapieplan</h2>
        <p class="uk-margin-remove-top uk-margin-small-bottom c-treatment-duration">
          {{ startDateFmt }}
        </p>

          <!-- Insert TreatmentHpText here -->
          <treatment-hp-text :patient-case="patientCase"></treatment-hp-text>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import TreatmentHpText from "./TreatmentHpText.vue";

export default {
  name: "TreatmentDetailsSkeleton",
  props: {
    patientCase: {
      type: Object,
      required: false,
    },
  },
  components: {
    TreatmentHpText,
  },
  computed: {
    startDateFmt() {
      if (this.patientCase?.treatment_case?.date) {
        return DateTime.fromISO(this.patientCase.treatment_case.date).toFormat("dd. LLLL", { locale: "de" });
      }
      return "n/a";
    },
  },
};
</script>

<style lang="sass" scoped>
h3
  margin-bottom: 8px!important

.c-treatment-recovery,
.c-treatment-start
  font-weight: 600
  line-height: 1!important
  margin-bottom: 2px!important
  font-size: .96em
.c-treatment-duration
  font-size: .92em

</style>
