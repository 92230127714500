<template>
       <careety-navbar
        @show-promo-code-checker="showPromoCodeChecker"
        @show-login="showLogin"
        @show-landing="showLanding">
        </careety-navbar>
</template>

<script>
export default {
  name: "SignupHeader",
  methods: {
    showPromoCodeChecker() {
      this.$emit("show-promo-code-checker");
    },
    showLogin() {
      this.$emit("show-login");
    },
    showLanding() {
      this.$emit("show-landing");
    },
  },
};
</script>

<style>

</style>
