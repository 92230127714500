<template>
  <div
    class="uk-flex uk-height-medium uk-background-default uk-margin uk-padding-large"
    uk-height-viewport="offset-bottom: 30"
  >
    <div class="uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s">
      <div class="uk-width-large uk-margin-auto uk-margin-top">
        <div class="uk-text-center uk-margin-medium uk-animation-fade">
          <h1>Password vergessen</h1>
        </div>

        <div v-if="successMessage" class="uk-margin-medium-top c-alert">
          <el-alert title="Erfolgreich" type="success">
            <span>{{ successMessage }}</span>
          </el-alert>
          <div class="uk-text-small uk-text-center uk-margin-top">
            <p>Dieser Tab kann geschlossen werden.</p>
          </div>
        </div>

        <div v-if="isAuthenticated" class="uk-margin-medium-top uk-text-center">
          Du bist bereits angemeldet.
          <router-link
            to="/login"
            class="uk-margin-medium-top uk-button uk-button-primary uk-width-expand"
            >Zurück zu careety</router-link
          >
        </div>

        <div
          v-else-if="!hideForm"
          class="uk-card uk-card-default uk-card-large uk-padding uk-animation-slide-bottom-medium"
          style="animation-delay: 500ms"
        >
          <p class="uk-text-normal c-pw-reset">
            E-Mail Adresse eingeben um einen Passwort-Reset Link zu erhalten.
          </p>

          <el-form
            :model="passwordReset"
            :rules="VALIDATOR_RULES"
            ref="passwordResetUsername"
            v-on:submit.prevent
          >
            <el-form-item prop="username">
              <el-input
              v-model="passwordReset.username"
              clearable
              placeholder="E-Mail Adresse"
              type="email"
              name="email"
              id="email"
              autocomplete="username"
              >
              </el-input>
            </el-form-item>
            <div class="uk-margin-medium-top">
              <button
                class="uk-button uk-button-primary uk-width-expand uk-text-capitalize"
                @click="sendPasswordReset"
                type="submit"
                value="senden"
                @keydown="sendPasswordReset"
                :disabled="isDisabled"
              >
                Senden
              </button>
            </div>
          </el-form>

          <div v-if="errorMessage" class="uk-margin-medium-top c-alert">
            <el-alert title="Fehler" type="error">
              <span>{{ errorMessage }}</span>
            </el-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ACTION_ASSURE_FETCHED_PROFILE,
  ACTION_USER_REQUEST_PASSWORD_RESET,
} from "@/store/action.type";

const VALIDATOR_RULES = {
  username: [
    { required: true, message: "Bitte deine E-Mail Adresse eingeben", trigger: "blur" },
    {
      type: "email",
      message: "Bitte eine gültige E-Mail Adresse eingeben",
      trigger: ["blur", "change"],
    },
  ],
};

export default {
  name: "PasswordReset",
  data() {
    return {
      passwordReset: {
        username: "",
      },
      isDisabled: false,
      hideForm: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async sendPasswordReset() {
      console.log("Request Password Reset");
      this.errorMessage = "";
      try {
        await this.$refs.passwordResetUsername.validate();
        await this.$store.dispatch(ACTION_USER_REQUEST_PASSWORD_RESET, this.passwordReset.username);
        this.successMessage = "Danke, dein Passwort wurde zurückgesetzt, du erhältst in Kürze eine E-Mail über die du dein neues Passwort festlegen kannst.";
        this.passwordReset.username = "";
        this.isDisabled = true;
        this.hideForm = true;
      } catch (err) {
        this.errorMessage = "Reset-Anfrage fehlgeschlagen.";
      }
    },
  },
  async created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_PROFILE);
    } catch (err) {
      console.log(`could not fetch profile ${err}`);
    }
  },
};
</script>

<style lang="sass" scoped>
.uk-card
  padding-top: 30px

  .c-pw-reset
    font-size: .9em
</style>
