<template>
   <div class="add-icon-wrapper">
      <img
        :src="require(`@/assets/icons/${icon}.svg`)"
        height="12"
        width="12"
        uk-svg
        alt="Element hinzufügen"
      />
    </div>
</template>

<script>
export default {
  name: "AddNewItem",
  data() {
    return {
      icon: "0823-plus",
    };
  },
};
</script>

<style lang="scss">

.add-icon-wrapper {
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  border: 1px dashed #bbb;
  border-radius: 4px;
  transition: all .3s ease-in-out;
}

.add-icon-wrapper .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: #ccc;
  transition: all .3s ease-in-out;
}

.add-icon-wrapper img {
  margin: 0;
  padding: 0;
}

.add-icon-wrapper:hover {
  cursor: pointer;
  border: 1px dashed #4a4a4a;
  transition: all .3s ease-in-out;
}

.add-icon-wrapper:hover .uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: #4a4a4a;
  transition: all .3s ease-in-out;
}

</style>
