<template>
  <nav
    class="uk-navbar uk-container uk-container-xlarge uk-black uk-animation-fade uk-padding-large uk-padding-remove-top uk-padding-remove-bottom"
    uk-navbar
  >
    <div class="uk-navbar-left">
      <router-link to="/" class="uk-navbar-item uk-logo uk-padding-remove"
        ><img src="@/assets/logo/careety-logo.png" width="120"
      /></router-link>
    </div>

    <div class="uk-navbar-right">
      <ul class="uk-navbar-nav uk-visible@s uk-flex-middle">
         <li v-if="userProfile.is_staff">
          <a href="/admin">Admin</a>
        </li>
        <li v-if="isAuthenticated" class="profile-wrapper uk-flex uk-flex-middle uk-margin-left">
          <img
            class="uk-border-circle"
            v-if="userProfile.profile_image_url"
            :src="userProfile.profile_image_url"
            width="50"
            :alt="userProfile.forename + ' ' + userProfile.surname"
            uk-img
          />
          <div v-else>{{ userProfile.forename }}</div>

          <div href="#" class="profile-name uk-parent uk-margin-left">
            <div class="profile-user-name">
              <span v-if="userProfile.forename">{{ userProfile.forename }}</span>
              <span v-if="userProfile.surname">{{ userProfile.surname }}</span>
              <span v-if="userProfile.email">
                <a href="#" @click="logout()">logout {{ userProfile.email }}</a>
              </span>
              <router-link v-else to="/login">login</router-link>
            </div>
          </div>
        </li>
      </ul>
      <a @click="drawer = true" type="primary" class="uk-hidden@s" uk-navbar-toggle-icon></a>
    </div>
  </nav>
  <el-drawer
    size="60%"
    withHeader="false"
    v-model="drawer"
    :direction="direction"
  >
    <div class="uk-padding uk-padding-remove-top">
      <a href="" class="uk-navbar-item uk-logo">
        <img src="@/assets/logo/careety-logo.png" alt="careety" width="120"
      /></a>
      <ul class="uk-nav uk-nav-default">
        <li class="uk-active"><router-link to="/hp">Dashbaord</router-link></li>
      </ul>
      <div class="uk-margin-large-top uk-text-small c-drawer-content">
        <span class="uk-text-bold c-block-el">Hallo {{ userProfile.forename }}, </span>
        <u
          ><a href="#" @click="logout()">logout {{ userProfile.email }}</a></u
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { ACTION_LOGOUT } from "@/store/action.type";

export default {
  name: "HealthDashboardNavigation",
  data() {
    return {
      drawer: false,
      direction: "rtl",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState(["userProfile"]),
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch(ACTION_LOGOUT);
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
@import '../../assets/scss/helpers/_variables.scss';

.uk-active
  color: $c-coral-main!important
  font-weight: 500

.profile-user-name
  span
    display: block
.c-drawer-content
  p
    line-height: 1.6
    margin: 10px 0
.c-block-el
  display: block
</style>
