<template>
  <editor-header
    @show="showSaveBtn"
    :treatment="patientCase?.treatment"
    :show-save-button="showSaveButton"
    @child-to-parent="assignTextProp"
  ></editor-header>
  <div class="editor-view-wrapper uk-container uk-container-xlarge uk-padding-large uk-padding-remove-bottom">
    <treatment-details
      @show="showSaveBtn"
      :med-text="patientCase?.treatment?.med_office_intervention"
      v-if="$route.name === 'TreatmentDetails'"
      @update-parent-text-data="updateTreatmentMedOfficeIntervention"
      :save-text="saveText"
    ></treatment-details>
    <behaviour-editor
      @add-new-behaviour="addNewBehaviour"
      @delete-parent-behaviour="removeBehaviour"
      @update-parent-behaviour="updateBehaviour"
      :behaviour-recommendations="patientCase?.behavior_recommendations"
      v-if="$route.name === 'BehaviourEditor'"
    ></behaviour-editor>
    <tip-editor
      @add-new-tip="addNewTip"
      @delete-parent-tip="removeTip"
      @update-parent-tip="updateTip"
      :tips="patientCase?.tips"
      v-if="$route.name === 'TipEditor'"
    ></tip-editor>
    <complication-editor
    v-if="$route.name === 'ComplicationEditor'"
    :complications="patientCase?.complications"
    @remove-parent-complication="updateComplications"
    @add-complication="addComplicationItem">
    </complication-editor>

    <medication-editor
    v-if="$route.name === 'MedicationEditor'"
    :medications="patientCase?.prescriptions"
    @remove-parent-medication="removeMedicationItem"
    @update-parent-medication="updateMedication"
    @add-prescription="addPrescriptionItem"
    >
    </medication-editor>
  </div>
</template>

<script>
import {
  apiGetHpTreatmentsCasePreview, apiHpUpdateTreatment, apiHpUpdateComplications,
} from "@/api";
import TreatmentDetails from "../../components/HealthProfessional/editor/TreatmentDetails.vue";
import BehaviourEditor from "../../components/HealthProfessional/editor/BehaviourEditor.vue";
import EditorHeader from "../../components/HealthProfessional/editor/EditorHeader.vue";
import TipEditor from "../../components/HealthProfessional/editor/TipEditor.vue";
import ComplicationEditor from "../../components/HealthProfessional/editor/ComplicationEditor.vue";
import MedicationEditor from "../../components/HealthProfessional/editor/MedicationEditor.vue";

export default {
  name: "TreatmentEdit",
  components: {
    TreatmentDetails,
    BehaviourEditor,
    EditorHeader,
    TipEditor,
    ComplicationEditor,
    MedicationEditor,
  },
  data() {
    return {
      showSaveButton: false,
      errorCode: null,
      patientCase: null,
      saveText: false,
    };
  },
  async mounted() {
    try {
      this.patientCase = await apiGetHpTreatmentsCasePreview(this.$route.params.id);
    } catch (err) {
      if (err.response?.data?.err) {
        this.errorCode = err.response.data.err;
      } else {
        this.errorCode = -1; // display generic error message
      }
    }
  },
  updated() {
    if (this.$route.name !== "TreatmentDetails") {
      this.showSaveButton = false;
    }
  },
  methods: {
    assignTextProp() {
      this.saveText = true;
    },
    addNewBehaviour(newItem) {
      this.patientCase.behavior_recommendations.push(newItem);
    },

    addPrescriptionItem(newItem) {
      this.patientCase.prescriptions.push(newItem);
    },

    addNewTip(newItem) {
      this.patientCase.tips.push(newItem);
    },
    async addComplicationItem(singleComplicationItem) {
      const data = {
        name: singleComplicationItem.value,
        id: singleComplicationItem.id,
        icon_name: singleComplicationItem.icon,
        description: singleComplicationItem.description,
        complication_type: singleComplicationItem.complication_type,
      };
      try {
        const findId = this.patientCase.complications.find((item) => item.id === singleComplicationItem.id);
        if (this.patientCase.complications.includes(findId)) {
          this.$message({
            message: "Komplikation bereits vorhanden",
            type: "error",
            offset: 80,
          });
        } else {
          this.patientCase.complications.push(data);
          await apiHpUpdateComplications(this.$route.params.id, this.patientCase.complications);
          this.$message({
            message: "Komplikation hinzugefügt",
            type: "success",
            offset: 80,
          });
        }
      } catch (err) {
        if (err.response?.data?.err) {
          this.errorCode = err.response.data.err;
          if (this.errorCode === 470) {
            this.$message({
              message: "Komplikation bereits vorhanden",
              type: "error",
              offset: 80,
            });
          } else {
            this.$message({
              message: "Ein Fehler ist aufgetreten",
              type: "error",
              offset: 80,
            });
          }
        }
      }
    },
    // async addPrescriptionItem(singlePrescriptionItem) {
    //   const data = {
    //     treatment_id: this.$route.params.id,
    //     drug: singlePrescriptionItem.drug,
    //     prescription_type: singlePrescriptionItem.prescription_type,
    //     quantity_morning: singlePrescriptionItem.quantity_morning,
    //     quantity_noon: singlePrescriptionItem.quantity_noon,
    //     quantity_evening: singlePrescriptionItem.quantity_evening,
    //     quantity_night: singlePrescriptionItem.quantity_night,
    //     intake_hint: singlePrescriptionItem.intake_hint,
    //     short_clarification: singlePrescriptionItem.short_clarification,
    //     full_clarification: singlePrescriptionItem.full_clarification,
    //     duration: singlePrescriptionItem.duration,
    //     offset: singlePrescriptionItem.offset,
    //     name: singlePrescriptionItem.name,
    //     name_addition: singlePrescriptionItem.name_addition,
    //     affiliate_url: singlePrescriptionItem.affiliate_url,
    //     id: singlePrescriptionItem.id,
    //   };
    //   try {
    //     const findId = this.patientCase.prescriptions.find((item) => item.id === singlePrescriptionItem.id);
    //     if (this.patientCase.prescriptions.includes(findId)) {
    //       this.$message({
    //         message: "Medikation bereits vorhanden",
    //         type: "error",
    //         offset: 80,
    //       });
    //     } else {
    //       this.patientCase.prescriptions.push(data);
    //       await apiHpAddPrescription(this.$route.params.id, this.patientCase.prescriptions);
    //       this.$message({
    //         message: "Medikation hinzugefügt",
    //         type: "success",
    //         offset: 80,
    //       });
    //     }
    //   } catch (err) {
    //     if (err.response?.data?.err) {
    //       this.errorCode = err.response.data.err;
    //       if (this.errorCode === 470) {
    //         this.$message({
    //           message: "Medikation bereits vorhanden",
    //           type: "error",
    //           offset: 80,
    //         });
    //       } else {
    //         this.$message({
    //           message: "Ein Fehler ist aufgetreten",
    //           type: "error",
    //           offset: 80,
    //         });
    //       }
    //     }
    //   }
    // },
    async removeBehaviour(singleBehaviourItemLocal) {
      const itemIndex = this.patientCase.behavior_recommendations.findIndex((item) => item.id === singleBehaviourItemLocal.id);
      this.patientCase.behavior_recommendations.splice(itemIndex, 1);
    },
    async removeTip(singleTipItemLocal) {
      const itemIndex = this.patientCase.tips.findIndex((item) => item.id === singleTipItemLocal.id);
      this.patientCase.tips.splice(itemIndex, 1);
    },
    async updateMedication(singleMedicationItemLocal) {
      const medicationItem = this.patientCase.prescriptions.find(
        (item) => item.id === singleMedicationItemLocal.id,
      );
      this.medicationItem = await Object.assign(medicationItem, singleMedicationItemLocal);
    },
    async updateBehaviour(singleBehaviourItemLocal) {
      const behaviourItem = this.patientCase.behavior_recommendations.find(
        (item) => item.id === singleBehaviourItemLocal.id,
      );
      this.behaviourItem = await Object.assign(behaviourItem, singleBehaviourItemLocal);
    },
    async updateTip(singleTipItemLocal) {
      const tipItem = this.patientCase.tips.find(
        (item) => item.id === singleTipItemLocal.id,
      );
      this.tipItem = await Object.assign(tipItem, singleTipItemLocal);
    },
    // async updateTextData(medOfficeInterventionEditorData) {
    //   this.patientCase.treatment.med_office_intervention = await medOfficeInterventionEditorData;
    // },

    async removeMedicationItem(singleMedicationItemLocal) {
      const itemIndex = this.patientCase.prescriptions.findIndex((item) => item.id === singleMedicationItemLocal.id);
      this.patientCase.prescriptions.splice(itemIndex, 1);
    },
    async updateComplications(singleComplicationItemLocal) {
      const itemIndex = this.patientCase.complications.findIndex((item) => item.id === singleComplicationItemLocal.id);
      this.patientCase.complications.splice(itemIndex, 1);
      try {
        apiHpUpdateComplications(this.$route.params.id, this.patientCase.complications);
      } catch (error) {
        console.log(error);
      }
    },
    async updateTreatmentMedOfficeIntervention(medOfficeInterventionEditorData) {
      const data = {
        med_office_intervention: medOfficeInterventionEditorData,
      };
      try {
        await this.$confirm(
          "Das Editieren wirkt sich auf alle bereits verschickten Therapiepläne aus. Möchtest du fortfahren?",
          "Achtung",
          {
            confirmButtonText: "Einverstanden",
            cancelButtonText: "Abbrechen",
            closeOnClickModal: false,
            closeOnPressEscape: false,
          },
        )
          .then(() => {
            this.$message({
              message: "Therapieplan wurde erfolgreich gespeichert",
              type: "success",
              closable: "true",
              offset: 80,
            });
            apiHpUpdateTreatment(this.$route.params.id, data).then((result) => {
              this.patientCase.treatment.med_office_intervention = result.med_office_intervention;
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Speichern abgebrochen",
              offset: 80,
            });
            this.showSaveButton = true;
          });
        this.showSaveButton = false;
      } catch (error) {
        console.log(error);
      }
      this.saveText = false;
    },
    showSaveBtn() {
      this.showSaveButton = true;
    },
  },
};
</script>

<style lang="scss">
.editor-view-wrapper {
  margin-top: 3vh;
}

.c-send-plan:hover {
  text-decoration: none;
}

.preview-treatment--diagnosis {
  font-size: 0.8em;
  margin: 4px 0 0 0;
  font-weight: 400;
  line-height: 1;
}

.el-message-box__message p {
  line-height: 1.6;
}

@media screen and (max-width: 639px) {
  .ck.ck-toolbar-container {
    top: 47% !important;
  }
  .editor-view-wrapper {
    margin-top: 5vh;
  }
}
</style>
