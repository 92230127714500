<template>
      <router-view :user-profile="userProfile" :patient-case="patientCase"></router-view>
      <el-dialog
        v-if="userProfile?.is_patient && !userProfile?.is_health_professional"
        v-model="showPatientFeedbackPopup"
        width="100%"
        custom-class="c-fluid-dialog"
        :close-on-click-modal="false"
        @ask-later="closePatientFeedback"
        :destroy-on-close="true"
        @close="closePatientFeedback">
        <patient-feedback-popup
          v-loading="loading"
          :health-professional="patientCase?.health_professional"
          @feedback-error="feedbackError"
          @ask-later="closeFeedback"
          @feedback-close="hideFeedback">
        </patient-feedback-popup>
      </el-dialog>
        <el-dialog
          v-if="userProfile?.is_health_professional && !userProfile?.is_patient"
          v-model="showHpFeedbackPop"
          custom-class="feedback-popup"
          width="100%"
          :close-on-click-modal="false"
          @ask-later="closeHpFeedback"
          :destroy-on-close="true"
          @feedback-error="feedbackError"
          @close="closeHpFeedback">
            <hp-feedback-popup
             @ask-later="closeFeedback"
             @feedback-error="feedbackError"
             @feedback-close="hideFeedback">
            </hp-feedback-popup>
          </el-dialog>
    <accept-bar></accept-bar>
    <chat></chat>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  patientFeedbackPopupCheckandPropagate,
  patientFeedbackPopupFalse,
  healthProfessionalFeedbackPopupCheckandPropagate,
  healthProfessionalFeedbackPopupFalse,
} from "@/utils/popup";
import { ACTION_ASSURE_FETCHED_PROFILE, ACTION_ASSURE_FETCHED_CASE } from "@/store/action.type";
import AcceptBar from "./components/UI/AcceptBar.vue";
import PatientFeedbackPopup from "./components/feedback/PatientFeedbackPopup.vue";
import HpFeedbackPopup from "./components/HealthProfessional/feedback/HealthProfessionalFeedbackPopup.vue";
import Chat from "./components/widgets/Chat.vue";
import { version } from "../package.json";

export default {
  name: "App",
  data() {
    return {
      appVersion: version,
      showPatientFeedbackPopup: false,
      showHpFeedbackPop: false,
      popupDelayInMin: 2.5,
      popupDenied: 0,
      showChecker: false,
      showLogin: false,
    };
  },
  components: {
    AcceptBar,
    PatientFeedbackPopup,
    HpFeedbackPopup,
    Chat,
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_PROFILE);
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_CASE);
    } catch (err) {
      console.log(err);
    }
    if (this.userProfile?.is_patient && this.showPatientFeedbackPopup === false) {
      setTimeout(() => {
        this.showPatientFeedbackPopup = patientFeedbackPopupCheckandPropagate();
      }, this.popupDelayInMin * 60000);
    }
    if (this.userProfile?.is_health_professional && this.showHpFeedbackPop === false) {
      setTimeout(() => {
        this.showHpFeedbackPop = healthProfessionalFeedbackPopupCheckandPropagate();
      }, this.popupDelayInMin * 60000);
    }
    console.log(`Version: ${this.appVersion}`);
  },
  computed: {
    ...mapState(["userProfile", "patientCase"]),
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    hideFeedback() {
      this.showPatientFeedbackPopup = false;
      this.showHpFeedbackPop = false;
    },
    closePatientFeedback() {
      this.showPatientFeedbackPopup = false;
      this.popupDenied += 1;
      if (this.popupDenied === 3) {
        patientFeedbackPopupFalse();
      }
      setTimeout(() => {
        this.showPatientFeedbackPopup = patientFeedbackPopupCheckandPropagate();
      }, this.popupDelayInMin * 60000);
    },
    closeHpFeedback() {
      this.showHpFeedbackPop = false;
      this.popupDenied += 1;
      if (this.popupDenied === 3) {
        healthProfessionalFeedbackPopupFalse();
      }
      setTimeout(() => {
        this.showHpFeedbackPop = healthProfessionalFeedbackPopupCheckandPropagate();
      }, this.popupDelayInMin * 60000);
    },
    feedbackError() {
      this.showPatientFeedbackPopup = false;
      this.showHpFeedbackPop = false;
    },
    closeFeedback() {
      this.showHpFeedbackPop = false;
      this.showPatientFeedbackPopup = false;
    },
  },
};
</script>
