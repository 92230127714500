<template>
  <div>
    <div class="uk-child-width-expand@s c-signup-wrapper uk-grid-large uk-flex-middle" uk-grid>
      <div
        class="uk-grid-small"
        uk-grid
        uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
        style="animation-delay: 400ms"
      >
        <div class="uk-width-auto">
          <img
            src="@/assets/icons/0105-shield-check.svg"
            class="uk-margin-right"
            width="35"
            height="35"
            uk-svg
          />
        </div>
        <div class="uk-width-4-5@s c-signup-gdpr-content">
          <h3>Nutzungsbedingungen & Datenschutz</h3>
          <p>
            Wir geben Ihnen nicht nur die volle Kontrolle über Ihre Gesundheit, sondern auch über
            Ihre Daten. Bitte nehmen Sie sich einen Moment Zeit um die entsprechenden Einstellungen
            vorzunehmen.
          </p>
          <img
            class="uk-border-rounded uk-box-shadow-medium"
            src="@/assets/img/signup/signup-02.jpg"
            alt="Ihr digitaler Therapieplan"
          />
          <a class="c-form-link-primary" href="#modal-privacy" uk-toggle>Wie wir diese Daten verarbeiten</a>
        </div>
      </div>

      <div
        uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
        style="animation-delay: 800ms"
      >
       <el-form :model="signupConsent" :rules="VALIDATOR_RULES" ref="signupConsentForm" class="c-signup-form">

         <el-form-item prop="age_confirmation">
             <el-checkbox v-model="signupConsent.age_confirmation" label="Mindestalter"></el-checkbox>
           <span class="c-ceckbox-text">Ich bin 18 Jahre oder älter.</span>
         </el-form-item>

             <el-form-item prop="agbs_confirmation">
             <el-checkbox label="Nutzungsbedingungen" v-model="signupConsent.agbs_confirmation"></el-checkbox>
           <span class="c-ceckbox-text">Ich habe die <a href="#modal-terms" uk-toggle><strong>Nutzungsbedingungen</strong></a> gelesen und akzeptiere sie.</span>
         </el-form-item>

             <el-form-item prop="careety_dp_confirmation">

             <el-checkbox label="Careety Konto" v-model="signupConsent.careety_dp_confirmation"></el-checkbox>

               <span class="c-ceckbox-text">Ich bin damit einverstanden, dass Careety die von mir zur Verfügung gestellten persönlichen Gesundheitsdaten verarbeitet, um mein Konto zu erstellen, mich bei meiner Therapie digital zu unterstützten und die Wirksamkeit der Therapie zu messen. Diesbezüglich entbinde ich meinen Arzt/ meine Ärztin von der gesetzlichen Schweigepflicht und stimme zu dass er meinen Gesundheitszustand über Careety überwachen kann.</span>
         </el-form-item>

       </el-form>

      </div>
    </div>

    <div
      class="uk-flex-center uk-grid-small"
      uk-grid
      uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
      style="animation-delay: 1000ms"
    >
      <div>
        <button
          class="uk-button uk-button-default uk-width-small uk-text-capitalize"
          type="button"
          @click.prevent="prev()"
        >
          Zurück
        </button>
      </div>
      <div>
        <button
          class="uk-button uk-button-primary uk-width-small uk-text-capitalize"
          type="submit"
          @click="next()"
        >
          Weiter
        </button>
      </div>
    </div>
  </div>

  <div id="modal-terms" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-margin-remove">Nutzungsbedingungen</h2>
      </div>
      <div class="uk-modal-body" uk-overflow-auto>
        <p v-html="termsPatient.content"></p>
      </div>
    </div>
  </div>

</template>

<script>
import { MUTATION_SET_PATIENT_SIGNUP_2_CONSENT } from "@/store/mutation.type";
import { ACTION_ASSURE_FETCHED_TERMS_PATIENT } from "@/store/action.type";
import { mapState } from "vuex";

const VALIDATOR_RULES = {
  age_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Bitte akzeptiere das Mindestalter",
    },
  ],
  agbs_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Akzeptiere unsere Nutzungsbedingungen um fortzufahren",
    },
  ],
  careety_dp_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Akzeptiere unsere Datenverarbeitung um fortzufahren",
    },
  ],
};

export default {
  name: "SignupStepTwo",
  data() {
    return {
      signupConsent: {
        age_confirmation: false,
        agbs_confirmation: false,
        careety_dp_confirmation: false,
      },
    };
  },
  methods: {
    async next() {
      try {
        await this.$refs.signupConsentForm.validate();
      } catch (err) {
        console.log("form validation error");
        return;
      }

      this.$store.commit(MUTATION_SET_PATIENT_SIGNUP_2_CONSENT, this.signupConsent);
      this.$router.push("/signup/patient/3");
    },
    async prev() {
      this.$store.commit(MUTATION_SET_PATIENT_SIGNUP_2_CONSENT, this.signupConsent);
      this.$router.push("/signup/patient/1");
    },
  },
  computed: {
    ...mapState(["termsPatient"]),
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;

    try {
      this.$store.dispatch(ACTION_ASSURE_FETCHED_TERMS_PATIENT);
    } catch (err) {
      console.log(err);
    }
  },
  async mounted() {
    if (this.$store.state.patientSignup2Consent) {
      this.signupConsent = this.$store.state.patientSignup2Consent;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helpers/_variables.scss';

.c-signup-form a {
  color: #272727;
}

.c-signup-form .c-ceckbox-text {
  display: block;
  line-height: 1.7;
  font-size: .89em;
  color: #272727;
  margin-left: 25px;
}

.c-signup-form .el-checkbox {
  color: #272727!important;
  font-weight: 600!important;
  line-height: 2;
  display: block;
}

.c-signup-form .el-form-item {
  margin-bottom: 40px!important;
}

.c-signup-gdpr-content img {
  margin-bottom: 1.8em
}

.c-form-link-primary {
  color: $c-coral-main;
  display: flex;
  justify-content: center;
}

</style>
