<template>
  <div
    class="uk-card uk-card-default uk-card-body uk-margin-medium-top uk-width-5-6@xl greet-patient uk-align-right"
    uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true">
    <div class="uk-flex uk-flex-between uk-flex-middle">
      <div>
        <p class="greet-patient--name uk-text uk-margin-remove">
          Hallo {{ userProfile.forename }}
        </p>
        <h3 class="uk-card-title uk-margin-remove">Heutige Aufgaben</h3>
        <p
          class="greet-patient--paragraph uk-margin-small-top uk-margin-remove-bottom"
        >
        {{ nowDateFmt }}
        </p>
      </div>
      <div class="uk-margin-small-left">
        <el-tooltip class="item" effect="dark" v-bind:content="`Sie sind bei Tag ${Math.ceil(patientCase.treatment_case.progressDur.as('days'))} von ${Math.ceil(patientCase.treatment_case.durationDur.as('days'))}`" placement="top">
        <radial-progress-bar v-bind:dayCount="Math.ceil(patientCase.treatment_case.progressDur.as('days'))" v-bind:completedSteps="28*patientCase.treatment_case.progress"></radial-progress-bar>
      </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import RadialProgressBar from "../../UI/RadialProgress.vue";

export default {
  name: "GreetPatientCard",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    userProfile: {
      type: Object,
      required: true,
    },
    nowDate: {
      type: Object,
      default: DateTime.local(),
    },
  },
  components: {
    RadialProgressBar,
  },
  computed: {
    nowDateFmt() {
      return this.nowDate.toFormat("dd. MMMM yyyy", { locale: "de" });
    },
  },
};
</script>
