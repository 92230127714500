<template>
    <div
      v-if="!addPrescriptionClicked"
      class="drug-search-icon uk-width-1-1"
      @click="showDrugSearchForm"
    >
      <img
        src="../../../assets/icons/0823-plus.svg"
        height="12"
        width="12"
        uk-svg
        alt="Medikament hinzufügen"
      />
    </div>

  <transition name="fade">
  <div v-if="addPrescriptionClicked" class="drug-search-form uk-width-1-1">
    <el-form
      v-loading="loading"
      uk-grid
      uk-margin
      :label-position="labelPosition"
      label-width="100px"
      :model="formLabelAlign"
      @submit.prevent
    >
      <div class="uk-width-expand">
        <div>
          <el-form-item>
            <el-autocomplete
              class="uk-width-1-1"
              v-model="state"
              :fetch-suggestions="querySearchAsync"
              :trigger-on-focus="false"
              placeholder="Suchen ..."
              @select="handleSelect"
            >
              <template #default="{ item }">
                <div class="uk-padding-small">
                    <div class="uk-margin-remove uk-width-expand">
                      <p class="uk-margin-remove"><strong>{{ item.value }}</strong></p>
                    </div>
              </div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  </transition>
</template>

<script>
import { apiHpDrugSearch } from "@/api";

export default {
  name: "DrugSearch",
  emit: ["add-prescription-item"],
  data() {
    return {
      addPrescriptionClicked: false,
      labelPosition: "top",
      drugs: [],
      state: "",
      timeout: null,
      singleDrugItem: {
        drug: {},
      },
    };
  },
  methods: {
    showDrugSearchForm() {
      this.addPrescriptionClicked = !this.addPrescriptionClicked;
    },
    async querySearchAsync(queryString, cb) {
      const data = await apiHpDrugSearch({ q: this.state });
      this.drugs = data.map((item) => ({
        value: item.name,
        id: item.id,
      }));
      const results = queryString
        ? this.drugs.filter(this.createFilter(queryString))
        : this.drugs;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 1000 * Math.random());
    },
    createFilter(queryString) {
      return (item) => item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleSelect(item) {
      this.singleDrugItem.drug = item;
      this.$emit("add-prescription-item", this.singleDrugItem);
      this.state = "";
    },
  },
};
</script>

<style lang="scss">

.drug-search-icon {
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  border: 1px dashed #bbb;
  border-radius: 4px;
  transition: all .3s ease-in-out;
}

.drug-search-icon .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: #ccc;
  transition: all .3s ease-in-out;
}

.drug-search-icon img {
  margin: 0;
  padding: 0;
}

.drug-search-icon:hover {
  cursor: pointer;
  border: 1px dashed #4a4a4a;
  transition: all .3s ease-in-out;
}

.drug-search-icon:hover .uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: #4a4a4a;
  transition: all .3s ease-in-out;
}

.drug-search-description {
  font-size: 0.92em;
  line-height: 1.5;
  white-space: normal;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
