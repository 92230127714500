<template>
  <div
    class="editor-component-wrapper uk-width-2-4@s uk-width-1-1 uk-align-center uk-margin-large-bottom">

      <div class="card-header uk-margin-large-bottom">
        <h3 class="editor-title">Verhaltenstipps</h3>
        <p v-if="tips?.length" class="editor-subtitle">
          Füge deine besten Verhaltenstipps hinzu oder übernimm einfach vorhandene.
        </p>
        <p v-else class="editor-subtitle">Im Moment sind keine Verhaltenstipps zugewiesen.</p>
      </div>

      <tip-item
        :single-tip-item="item"
        :is-editing="isEditing"
        v-for="item in tips"
        :key="item.text"
        @showEdit="editItem"
        @saveItem="save"
        @delete-item="remove"
        :add-new-item="false"
        >
      </tip-item>

      <tip-item
        :single-tip-item="item"
        :is-editing="isEditing"
        v-for="item in tipsLocal"
        :key="item.text"
        @showEdit="editItem"
        @saveItem="save"
        @delete-item="remove"
        :add-new-item="addNewItem"
        @add-new-tip="addNewTip"
        @remove-object-from-array="removeObjectFromArray"
        >
      </tip-item>
      <add-new-item
        class="uk-width-1-1"
        @click="addLocalTip">
      </add-new-item>
  </div>
</template>

<script>
import TipItem from "./TipItem.vue";
import AddNewItem from "../../widgets/AddNewItem.vue";

export default {
  name: "TipEditor",
  emits: ["update-parent-tip", "delete-parent-tip"],
  components: {
    TipItem,
    AddNewItem,
  },
  props: {
    tips: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      labelPosition: "right",
      isEditing: false,
      tipsLocal: [],
      addNewItem: false,
    };
  },
  methods: {
    removeObjectFromArray() {
      this.tipsLocal.splice(0, 1);
    },
    remove(singleTipItemLocal) {
      this.$emit("delete-parent-tip", singleTipItemLocal);
    },
    save(singleTipItemLocal) {
      this.$emit("update-parent-tip", singleTipItemLocal);
    },
    addNewTip(newItem) {
      this.$emit("add-new-tip", newItem);
      this.tipsLocal = [];
    },
    addLocalTip() {
      this.addNewItem = true;
      this.tipsLocal.push(
        {
          text: "",
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header p {
  font-size: 0.9em;
}

.behaviour-item {
  margin: 50px 0;
}
</style>
