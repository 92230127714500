<template>
  <div class="uk-section">
      <div class="uk-container uk-container-small uk-text-center uk-animation-slide-bottom-medium">
          <h1>{{ message.headline }}</h1>
          <p class="uk-text-lead"> {{ message.text }}</p>
          <router-link to="/" class="uk-button uk-button-default">{{message.link}}</router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      message: {
        headline: "404",
        text: "Diese Seite wurde leider nicht gefunden",
        link: "zurück zu careety",
      },
    };
  },
};
</script>

<style>

</style>
