<template>
  <header class="uk-width-1-1 editor-header">
    <div
      uk-sticky=" sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-dark; top: 200; animation: uk-animation-slide-top;"
    >
      <nav
        class="uk-navbar uk-container uk-container-xlarge uk-black uk-padding-large uk-padding-remove-top uk-padding-remove-bottom uk-flex-wrap"
        uk-navbar
      >
        <div class="uk-navbar-left uk-width-1-6@m uk-width-1-1">
          <router-link to="/hp" class="uk-navbar-item uk-logo uk-padding-remove"
            ><img src="@/assets/logo/c.png" width="40" />
          </router-link>
        </div>

        <div
          class="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap uk-width-1-1 uk-width-expand@m"
        >
          <div
            class="title-wrapper uk-margin-small-right uk-width-1-1 uk-width-auto@s"
          >
            <router-link :to="{ name: 'HealthProfessionalTreatmentSend' }"><h3>{{ treatment?.name }}</h3></router-link>
            <h4>{{ treatment?.diagnosis?.name }}</h4>
          </div>
          <ul
            class="uk-navbar-nav uk-flex-middle editor-steps uk-flex-wrap uk-width-1-1 uk-width-auto@s"
          >
            <li :class="{ 'uk-active': $route.name === 'TreatmentDetails' }">
              <router-link :to="{ name: 'TreatmentDetails' }"
                >Haupttext</router-link
              >
            </li>

            <li :class="{ 'uk-active': $route.name === 'MedicationEditor' }">
              <router-link :to="{ name: 'MedicationEditor' }"
                >Medikamente</router-link
              >
            </li>

            <li :class="{ 'uk-active': $route.name === 'BehaviourEditor' }">
              <router-link :to="{ name: 'BehaviourEditor' }"
                >To-Dos</router-link
              >
            </li>

            <li :class="{ 'uk-active': $route.name === 'TipEditor' }">
              <router-link :to="{ name: 'TipEditor' }">Verhaltenstipps</router-link>
            </li>

            <li :class="{ 'uk-active': $route.name === 'ComplicationEditor' }">
              <router-link :to="{ name: 'ComplicationEditor' }"
                >Komplikationen</router-link
              >
            </li>
          </ul>

          <div v-if="showSaveButton" class="c-save-textmed">
              <el-button
                type="primary"
                style="display: block"
                size="small"
                @click="updateTreatmentMedOfficeIntervention"
                >Speichern</el-button
              >
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "EditorHeader",
  data() {
    return {
      treatmentDetailsAvailable: true,
      behaviourEditorAvailable: false,
    };
  },
  props: {
    treatment: {
      type: Object,
      required: true,
    },
    showSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editorAvailable() {
      this.$parent.showSaveButton = true;
    },
    updateTreatmentMedOfficeIntervention() {
      // this.$parent.updateTreatmentMedOfficeIntervention();
      this.$parent.assignTextProp();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper h3 {
  font-size: 0.94em;
  margin: 0;
}

.title-wrapper h4 {
  font-size: 0.84em;
  font-weight: 400;
  margin: 0;
  line-height: 1.2;
}

.title-wrapper a:hover {
  color: #000;
}

.editor-steps li a {
  font-size: 0.94em;
}
.editor-steps li a:first-child {
  padding-left: 0;
}

.uk-navbar-nav > li > a {
  min-height: 10px;
}
@media screen and (max-width: 959px) {
  .c-save-textmed {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .uk-sticky-fixed {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 739px) {
  .title-wrapper {
    margin-bottom: 20px;
  }
}
</style>
