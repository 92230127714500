<template>
<slim-header></slim-header>
  <div class="uk-container uk-container-xlarge uk-padding-large">
    <data-policy></data-policy>
  </div>
  <careety-footer :user-profile="userProfile"></careety-footer>
</template>

<script>
import { ACTION_ASSURE_FETCHED_PRIVACY } from "@/store/action.type";
import { mapState } from "vuex";
import DataPolicy from "../components/widgets/DataPolicy.vue";

export default {
  name: "Privacy",
  components: {
    DataPolicy,
  },
  props: {
    userProfile: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_PRIVACY);
    } catch (err) {
      // TODO: handle errors
    }
  },
  computed: {
    ...mapState(["privacy"]),
  },
};
</script>

<style>

</style>
