<template>
    <div v-html="termsPatient.content"></div>
</template>

<script>
import { ACTION_ASSURE_FETCHED_TERMS_PATIENT } from "@/store/action.type";
import { mapState } from "vuex";

export default {
  name: "TermsPatient",
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_TERMS_PATIENT);
    } catch (err) {
      // TODO: handle errors
    }
  },
  computed: {
    ...mapState(["termsPatient"]),
  },
};
</script>

<style>

</style>
