<template>

 <header class="uk-inline uk-width-1-1">
  <div class="uk-cover-container uk-height-large c-header-shadow">
   <img src="@/assets/img/header/hero.jpg" alt="" uk-cover>
     <div uk-sticky=" sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-dark; top: 200; animation: uk-animation-slide-top; show-on-up: true;">
       <!-- insert nav -->
       <careety-navbar></careety-navbar>
     </div>
     <!-- insert title -->
     <doctor-and-title
       v-if="patientCase?.treatment?.name"
       :patient-case="patientCase"
       :patient-diagnosis="patientDiagnosis"
     ></doctor-and-title>
   </div>
 </header>

</template>

<script>
import DoctorAndTitle from "./DoctorAndTitle.vue";

export default {
  components: {
    DoctorAndTitle,
  },
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    patientDiagnosis: {
      type: Object,
      required: true,
    },
    userProfile: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .c-header-shadow {
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.08);
  }

</style>
