<template>
    <div v-if="medText"
      v-loading="loading"
    class="uk-margin-large-bottom">
        <ckeditor
          :editor="editor"
          v-model="medOfficeInterventionEditorData"
          :config="editorConfig"
          @focus="this.$emit('show', true)"
          @ready="prefillText"
          ></ckeditor>
    </div>
    <div v-else>
       <h3 class="editor-title">Haupttext</h3>
        <p class="editor-subtitle uk-margin-bottom">Im Moment ist kein Haupttext zugewiesen.</p>
    </div>
</template>

<script>
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

export default {
  name: "TreatmentDetails",
  props: {
    medText: {
      type: String,
      required: true,
    },
    showSaveButton: {
      type: Boolean,
    },
    saveText: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      showEditor: true,
      editor: InlineEditor,
      medOfficeInterventionEditorData: "",
      editorConfig: {
        toolbar: {
          viewportTopOffset: 93,
          items: [
            "heading",
            "|",
            "bold",
            "underline",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "|",
            "link",
            "RemoveFormat",
            "|",
            "redo",
            "undo",
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Überschrift 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Überschrift 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Überschrift 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Überschrift 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Überschrift 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Überschrift 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        language: "de",
      },
    };
  },
  async updated() {
    if (this.saveText === true) {
      try {
        this.$emit("update-parent-text-data", this.medOfficeInterventionEditorData);
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {
    prefillText() {
      this.medOfficeInterventionEditorData = this.medText;
    },
    // abortEditing() {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.prefillText();
    //     this.loading = false;
    //     this.$message({
    //       message: "Editieren abgebrochen",
    //       offset: 80,
    //     });
    //   }, 800);
    // },
  },
};
</script>
