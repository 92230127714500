<template>
    <div class="uk-container uk-container-medium uk-padding">
      <waiting-list></waiting-list>
    </div>
</template>

<script>
import WaitingList from "../components/waitinglist/WaitingList.vue";

export default {
  name: "WaitingListView",
  components: {
    WaitingList,
  },
};
</script>

<style lang="sass">
.dev-router
    a
        font-size: .8em
</style>
