<template>
  <div
    class="uk-container uk-container-xlarge therapy-information-list uk-margin-medium-top c-complications"
  >
    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-auto@s">
        <img
          src="@/assets/icons/0106-shield-alert.svg"
          class="uk-margin-right c-custom-margin-top-small c-svg-dark"
          width="26"
          height="26"
          uk-svg
        />
      </div>

      <div class="uk-width-expand">
        <h2 class="uk-margin-remove">Was Sie noch wissen sollten</h2>

        <div class="uk-grid-large" uk-grid>
          <div class="uk-width-1-2@s uk-margin-top uk-margin-large-bottom" v-if="doctorComplications.length">
            <h3 class="uk-h4">Wann Sie uns kontaktieren sollten</h3>
            <div
              class="c-custom-margin-bottom"
              v-for="complication in doctorComplications"
              :key="complication"
            >
              <div
                class="uk-flex uk-flex-middle"
                v-if="complication.complication_type === 'doctor'"
              >

                <el-popover
                  placement="top-start"
                  :title="complication.name"
                  :width="200"
                  trigger="hover"
                  tabindex="100"
                >
                <slot>
                  <div v-html="complication.description"></div>
                </slot>
                  <template #reference>
                    <div class="uk-flex uk-flex-middle change-hover-cursor">

                       <div class="svg-icon uk-margin-small-right">
                          <img
                            :src="require(`@/assets/icons/${complication.icon_name}.svg`)"
                            width="18"
                            height="18"
                            uk-svg/>
                        </div>

                      <div class="therpy-information-heading">
                        <h5 class="uk-h5 uk-text-light uk-margin-remove">
                          {{ complication.name }}
                        </h5>
                      </div>

                    </div>

                  </template>
                </el-popover>
              </div>
            </div>
            <button
              class="uk-button uk-button-default uk-margin c-button-modal"
              uk-toggle="target: #c-call-doc-modal"
              :title="
                patientCase?.health_professional?.name_prefix +
                  ' ' +
                  patientCase?.health_professional?.surname +
                  ' ' +
                  'kontaktieren'
              "
              uk-scrollspy="cls: uk-animation-fade; delay: 300"
              repeat="false"
              v-if="complicationsModal"
            >
              {{ patientCase?.health_professional?.name_prefix }}
              {{ patientCase?.health_professional?.surname }} kontaktieren
            </button>
          </div>

          <div class="uk-width-1-2@s uk-margin-top" v-if="emergencyComplications.length">
            <h3 class="uk-h4">Wann Sie in die Notaufnahme sollten</h3>
            <div
              class="c-custom-margin-bottom"
              v-for="complication in emergencyComplications"
              :key="complication"
            >
              <div
                class="uk-flex uk-flex-middle"
                v-if="complication.complication_type === 'emergency'">

                <el-popover
                  placement="top-start"
                  :title="complication.name"
                  :width="200"
                  trigger="hover">
                  <slot>
                    <div v-html="complication.description"></div>
                  </slot>
                    <template #reference>

                      <div class="uk-flex uk-flex-middle change-hover-cursor">

                        <div class="svg-icon uk-margin-small-right">
                          <img
                            :src="require(`@/assets/icons/${complication.icon_name}.svg`)"
                            width="18"
                            height="18"
                            uk-svg/>
                        </div>

                        <div class="therpy-information-heading">
                          <h5 class="uk-h5 uk-text-light uk-margin-remove">
                            {{ complication.name }}
                          </h5>
                        </div>

                      </div>
                    </template>
                </el-popover>

              </div>
            </div>
            <button
              class="uk-button uk-button-default uk-margin c-button-modal"
              uk-toggle="target: #c-call-a-funk-modal"
              uk-scrollspy="cls: uk-animation-fade; delay: 300"
              repeat="false"
              title="Ärztefunkdienst kontaktieren"
              v-if="complicationsModal"
            >
              Notruf kontaktieren
            </button>
          </div>
        </div>
      </div>

      <div id="c-call-doc-modal" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-border-rounded uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <h2 class="uk-modal-title">Kontakt Informationen</h2>

          <h4 class="uk-margin-bottom uk-margin-remove-top c-text-500">
            {{ patientCase?.health_professional?.name_prefix }}
            {{ patientCase?.health_professional?.forename }}
            {{ patientCase?.health_professional?.surname
            }}<span v-if="patientCase?.health_professional?.name_postfix"
              >, {{ patientCase?.health_professional?.name_postfix }}</span
            >
          </h4>
          <div class="" uk-grid>
            <div
              v-if="patientCase?.health_professional?.telephone"
              class="uk-flex uk-flex-middle uk-width-1-3@s"
            >
              <div class="svg-icon uk-margin-small-right">
                <img src="@/assets/icons/0363-telephone.svg" width="18" height="18" uk-svg />
              </div>
              <div
                v-if="patientCase?.health_professional?.telephone"
              >
                <div class="uk-margin-remove">
                  <a
                    :href="'tel:' + patientCase.health_professional.telephone"
                    class="c-text-dark"
                    >{{ patientCase.health_professional.telephone }}</a
                  >
                </div>
              </div>
            </div>

            <div
              v-if="patientCase?.health_professional?.email"
              class="uk-flex uk-flex-middle uk-width-2-3@s"
            >
              <div class="svg-icon uk-margin-small-right">
                <img src="@/assets/icons/0151-envelope.svg" width="18" height="18" uk-svg />
              </div>
              <div>
                <div class="uk-margin-remove">
                  <a
                    :href="'mailto:' + patientCase.health_professional.email"
                    class="c-text-dark"
                    >{{ patientCase.health_professional.email }}</a
                  >
                </div>
              </div>
            </div>

            <div
              v-if="patientCase?.health_professional?.website"
              class="uk-flex uk-flex-middle uk-width-2-3@s"
            >
              <div class="svg-icon uk-margin-small-right">
                <img src="@/assets/icons/0792-link2.svg" width="18" height="18" uk-svg />
              </div>
              <div>
                <div class="uk-margin-remove">
                  <a
                    :href="patientCase.health_professional.website"
                    target="_blank"
                    class="c-text-dark"
                    >Website</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="patientCase?.health_professional?.street"
            class="uk-flex uk-flex-top uk-margin-top uk-width-1-2@s"
          >
            <div class="svg-icon uk-margin-small-right">
              <img src="@/assets/icons/0001-home.svg" width="18" height="18" />
            </div>
            <div>
              <div class="uk-margin-remove c-text-dark break-word">
                {{ patientCase.health_professional.street }}
                <div class="uk-flex">
                  {{ patientCase.health_professional.zip_code }}
                  {{ patientCase.health_professional.city }}
                </div>
                {{ patientCase.health_professional.land }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="c-call-a-funk-modal" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-border-rounded uk-margin-auto-vertical">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <h2 class="uk-modal-title">Notruf</h2>

          <div uk-grid>
            <div class="uk-width-expand@s">
              <a href="tel:144" class="uk-width-expand c-button-modal uk-button uk-button-primary"
                >Rettung 144</a
              >
            </div>

            <div class="uk-width-expand@s">
              <a href="tel:112" class="uk-width-expand c-button-modal uk-button uk-button-primary"
                >Euronotruf 112</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "Complications",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    complicationsModal: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  computed: {
    doctorComplications() {
      if (this.patientCase?.complications) {
        return this.patientCase.complications.filter((doctor) => doctor.complication_type === "doctor");
      }
      return [];
    },
    emergencyComplications() {
      if (this.patientCase?.complications) {
        return this.patientCase.complications.filter((emergency) => emergency.complication_type === "emergency");
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/helpers/_variables.scss';

.c-text-dark {
    color: #000 !important;
    font-size: .9em;
    line-height: 1.6;
}

.c-button-modal {
    background-color: #fff;
    font-weight: 500;
    text-transform: none;
    color: $c-black-main;
}

.c-button-modal:hover {
    background-color: #f7f7f7;
}

.uk-h4 {
    font-weight: 500 !important;
    line-height: 1.2;
    font-size: 1.2em;
    margin-bottom: 16px;
}

.c-text-500 {
    font-weight: 500;
}

.uk-h5 {
    font-size: 15px;
}

.c-custom-margin-bottom {
    margin-bottom: 14px;
}

.svg-icon-question {
    padding: 1px 4px;
    border-radius: 6px;
    margin-left: 2px;
}

.svg-icon-question:hover {
    background-color: #f8f8f8;
}

.change-hover-cursor:hover {
    cursor: help;
}

#c-call-doc-modal .c-text-dark {
    line-height: 1.2 !important;
    word-break: break-word;
}

#c-call-doc-modal .svg-icon img {
    min-width: 20px;
}

</style>
