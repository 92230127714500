<template>
  <div
    class="uk-card uk-card-default uk-padding-remove uk-width-5-6@xl uk-align-right"
    uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
    v-for="phase in exercisePhases"
    :key="phase.name"
  >
    <div class="uk-card-header">
      <div class="uk-grid-small uk-flex-middle" uk-grid>
        <img src="@/assets/icons/0610-dumbbell.svg" width="45" height="45" uk-svg />
        <div class="c-card-remove-line-height">
          <h3 class="uk-card-title uk-margin-remove-bottom">Übungen</h3>
          <span class="c-card-title-day" v-if="phase.phasenr === 0"
            >Heute, Übungen für Zwischendurch</span
          >
          <span class="c-card-title-day" v-else
            >Heute, Phase {{ phase.phasenr }} von {{ countExercisePhases }}</span
          >
        </div>
      </div>
      <div class="c-progress-wrapper uk-margin-bottom">
        <div class="uk-flex uk-flex-between uk-flex-middle c-progress-text-wrapper">
          <p class="c-progress-title uk-margin-remove">
            Übungs Fortschritt
          </p>
          <p class="c-progress-day uk-margin-remove">
            Tag
            {{ patientCase.exercice_programs_overall_progress.progressAsDays }}
            von
            {{ patientCase.exercice_programs_overall_progress.durationAsDays }}
          </p>
        </div>
        <progress
          id="js-progressbar"
          class="uk-progress uk-margin-remove"
          v-bind:value="100 * patientCase.exercice_programs_overall_progress.progress"
          max="100"
          v-bind:uk-tooltip="
            `title: Du bist bei Tag ${patientCase.exercice_programs_overall_progress.progressAsDays} von ${patientCase.exercice_programs_overall_progress.durationAsDays}; delay: 500`
          "
        ></progress>
      </div>
    </div>
    <el-tooltip content="Mehr Informationen" placement="top" v-if="modal">
      <div class="svg-icon uk-position uk-position-top-right uk-position-small">
        <a href="#exercise-modal" uk-toggle class="card--info-btn">
          <img src="@/assets/icons/custom/information-icon-custom.svg" width="15" height="15" uk-svg
        /></a>
      </div>
    </el-tooltip>

    <div>
      <div uk-slideshow="animation: push">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
          <ul class="uk-slideshow-items">
            <li v-for="single in phase.exercises.slice(0, 1)" :key="single">
              <video
                :src="single.video_url + '#t=2'"
                loop
                muted
                playsinline
                preload="auto"
                class="c-exercise-video-card"
              ></video>
              <a href="#c-exercise-modal" uk-toggle>
                <img
                  src="@/assets/icons/custom/play-button.svg"
                  class="c-exercise-playbtn uk-position-center uk-margin-remove"
                  alt="Play Video"
                  uk-svg
                  v-if="modal"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="c-exercise-modal" class="uk-flex-top uk-modal-container" uk-modal uk-overflow-auto>
      <div
        class="uk-modal-dialog c-exercise-modal uk-modal-body uk-margin-auto-vertical uk-padding-remove"
      >
        <button class="uk-modal-close-default uk-close" type="button" uk-close></button>
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
          uk-slideshow="ratio: 16:9; animation: push"
        >
          <ul class="uk-slideshow-items c-exercise-canvas" :class="{ expandSliderHeight: clicked }">
            <li v-for="single in phase.exercises" :key="single">
              <div v-if="single.is_explainer_video">

                <div
                  id="c-exercise-description"
                  class="uk-padding"
                  hidden
                  :class="{ showContent: clicked }"
                >
                  <div class="uk-text-lead">
                    <h3 class="dark">Anleitung</h3>
                    <span class="c-exercise-description-html" v-html="single.description"></span>
                  </div>
                </div>

                <div>
                  <vue-plyr :options="options">
                  <video
                    controls
                    disablePictureInPicture
                    uk-video="autoplay: false;"
                  >
                    <source
                      :src="single.video_url"
                      type="video/mp4"
                    />
                  </video>
                </vue-plyr>

                  <div class="action-menu" :class="{ 'top-layout' : single.is_explainer_video }">

                  <div
                    class="uk-position uk-padding uk-position-bottom-left uk-light exercise-content"
                  >
                    <h4> <span v-if="single.repetition_count">{{ single.repitition_count }}x </span> {{ single.name }}</h4>
                  </div>

                  <div v-if="single.description.length"
                    class="uk-position uk-padding uk-position-small uk-position-bottom-right uk-light exercise-content c-tutorial-wrapper">
                    <button
                      v-show="!clicked"
                      class="uk-button uk-button-default c-tutorial-btn"
                      type="button"
                      uk-toggle="target: #c-exercise-description; duration: 0;"
                      @click="clicked = !clicked"
                    >
                      Anleitung
                    </button>
                    <button
                      v-show="clicked"
                      class="uk-button uk-button-default c-tutorial-btn"
                      type="button"
                      uk-toggle="target: #c-exercise-description; duration: 0;"
                      @click="clicked = !clicked"
                    >
                      Anleitung schließen
                    </button>
                  </div>
                </div>
                </div>
              </div>

              <div v-else>
                <div class="gradient"></div>

                <div
                  id="c-exercise-description"
                  class="uk-padding"
                  hidden
                  :class="{ showContent: clicked }"
                >
                  <div class="uk-text-lead">
                    <h3 class="dark">Anleitung</h3>
                    <span class="c-exercise-description-html" v-html="single.description"></span>
                  </div>
                </div>

                <div style="width: 100%">
                  <video
                    :src="single.video_url"
                    autoplay
                    loop
                    muted
                    playsinline
                    class="c-exercise-video"
                  ></video>

                  <div
                    class="uk-position uk-padding uk-position-bottom-left uk-light exercise-content"
                  >
                    <h4>{{ single.repitition_count }}x {{ single.name }}</h4>
                    <div uk-grid class="uk-grid-small">
                      <span class="c-exercise-sets">{{ single.set_count }} Sätze</span>
                      <span class="c-exercise-pause">30 Sek. Pause</span>
                    </div>
                  </div>

                  <div
                    class="uk-position uk-padding uk-position-small uk-position-bottom-right uk-light exercise-content c-tutorial-wrapper"
                  >
                    <button
                      v-show="!clicked"
                      class="uk-button uk-button-default c-tutorial-btn"
                      type="button"
                      uk-toggle="target: #c-exercise-description; duration: 0;"
                      @click="clicked = !clicked"
                    >
                      Anleitung
                    </button>
                    <button
                      v-show="clicked"
                      class="uk-button uk-button-default c-tutorial-btn"
                      type="button"
                      uk-toggle="target: #c-exercise-description; duration: 0;"
                      @click="clicked = !clicked"
                    >
                      Anleitung schließen
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-previous
            uk-slideshow-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            uk-slidenav-next
            uk-slideshow-item="next"
          ></a>
          <ul
            class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin-medium-bottom uk-inline uk-position-bottom uk-flex"
          ></ul>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!exercisePhases.length"
    class="uk-card uk-card-default uk-padding-remove uk-width-5-6@xl uk-align-right"
    uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
  >
    <div class="uk-card-header">
      <div class="uk-grid-small uk-flex-middle" uk-grid>
        <img src="@/assets/icons/0610-dumbbell.svg" width="45" height="45" uk-svg />
        <div class="c-card-remove-line-height">
          <h3 class="uk-card-title uk-margin-remove-bottom">Übungen</h3>
        </div>
      </div>
    </div>

    <el-tooltip content="Mehr Informationen" placement="top" v-if="modal">
      <div class="svg-icon uk-position uk-position-top-right uk-position-small">
        <a href="#exercise-modal" uk-toggle class="card--info-btn" title="Mehr Informationen">
          <img src="@/assets/icons/custom/information-icon-custom.svg" width="15" height="15" uk-svg
        /></a>
      </div>
    </el-tooltip>

    <div class="uk-card-body c-no-exercises">
      <img src="@/assets/img/exercises/clouds.png" class="background-image" alt="" uk-img />
      <h5 class="c-exercise-text uk-text-center uk-text-light uk-margin-large-bottom">
        Für heute gibt es keine Übungen
        <p v-if="patientCase.exercice_programs_overall_progress.startCountdownAsDays > 0">
          Wird in {{ patientCase.exercice_programs_overall_progress.startCountdownAsDays }} Tag(en) freigegeben.
        </p>
      </h5>
      <div class="c-progress-wrapper uk-margin-bottom">
        <div class="uk-text-right c-progress-text-wrapper">
          <p v-if="patientCase.exercice_programs_overall_progress.progress === 1" class="c-progress-day uk-margin-remove">
            Abgeschlossen
          </p>
          <p v-else class="c-progress-day uk-margin-remove">
            Tag
            {{ patientCase.exercice_programs_overall_progress.progressAsDays }}
            von
            {{ patientCase.exercice_programs_overall_progress.durationAsDays }}
          </p>
        </div>
        <el-tooltip :content="`Sie sind bei Tag ${patientCase.exercice_programs_overall_progress.progressAsDays} von ${patientCase.exercice_programs_overall_progress.durationAsDays}`" placement="top">
          <progress
            id="js-progressbar"
            class="uk-progress uk-margin-remove"
            v-bind:value="100 * patientCase.exercice_programs_overall_progress.progress"
            max="100">
          ></progress>
        </el-tooltip>
      </div>
    </div>
  </div>

  <!-- Exercise Modal Start -->
  <div id="exercise-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-border-rounded uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-grid-small" uk-grid>
        <div class="uk-width-auto@s">
          <img
            src="@/assets/icons/0610-dumbbell.svg"
            class="uk-margin-right c-svg-dark"
            width="30"
            height="30"
            uk-svg
          />
        </div>
        <div class="uk-width-4-5@s">
          <h3 class="uk-margin-remove">Übungen</h3>
          <p class="uk-margin-remove c-treatment-start">
            {{ patientCase.exercice_programs_overall_progress.durationAsDays }} Tage
          </p>
          <p class="uk-margin-remove-top uk-margin-small-bottom c-treatment-duration">
            {{ patientCase.exercice_programs_overall_progress.startDate }}
            –
            {{ patientCase.exercice_programs_overall_progress.finishDate }}
          </p>
          <div class="uk-margin-remove" v-html="patientCase.treatment.exercise_explainer"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Exercise Modal END -->
</template>

<script>
import { DateTime } from "luxon";
import { filterArrayOffDurCurrentlyActive } from "@/utils/time";
// import RadialProgressBar from '../../UI/RadialProgress.vue';

export default {
  name: "ExerciseCard",
  data() {
    return {
      clicked: false,
      options: {
        loadSprite: false,
        // eslint-disable-next-line global-require
        iconUrl: require("@/assets/sprites/plyr.svg"),
        controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "fullscreen"],
      },
    };
  },
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    modal: {
      type: Boolean,
      required: true,
      default: true,
    },
    nowDate: {
      type: Object,
      default: DateTime.local(),
    },
  },
  components: {
    // RadialProgressBar
  },
  computed: {
    exercisePhases() {
      return filterArrayOffDurCurrentlyActive(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.exercise_program,
      );
    },
    showExercises() {
      return this.patientCase.exercise_program.slice(0, 1);
    },
    countExercisePhases() {
      return this.patientCase.exercise_program.length - 1;
    },
  },
};
</script>

<style lang="sass">
.c-exercise-modal
  background-color: transparent

.c-no-exercises
  .c-exercise-text
    font-size: .95em
    line-height: 1.3

.dark
    color: #fff!important

.expandSliderHeight
    height: 78vh
    background-color: #000
    color: #fff!important
.showContent
    height: 78vh
    background-color: #000

.c-exercise-canvas
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
    border-radius: 14px
    p
        opacity: .9
        font-size: .92em
    .top-layout
        position: absolute
        bottom: 50px
        width: 220px
        height: 100px
    .custom-position
      position: absolute
      bottom: 50px
      left: 20px

    .exercise-content
        z-index: 100!important
        padding-bottom: 46px
        button
          text-transform: none
        h4
            margin: 0
        .c-tutorial-btn
          background: #fff
          color: #000
          font-size: .8em
          padding: 0 16px
          line-height: 2.4
          font-weight: 500
          &:hover
            background: #f5f5f5
        .c-exercise-sets,
        .c-exercise-pause
            font-size: .8em

        .c-exercise-infos
            margin-top: 50px

            .c-exercise-text p
                font-size: 14px!important

.gradient
    height: 40%
    width: 100%
    position: absolute
    bottom: -10px
    left: 0
    z-index: 2
    background: rgb(0,0,0)
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.8) 20%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0.2) 80%, rgba(0,0,0,0.1) 90%, rgba(0,0,0,0.02) 100%)
    opacity: 0.5

.wrapper
    position: relative;
    width: 100%;
    height: 100%;
    background-attachment: scroll;
    overflow: hidden;

.c-exercise-video
    position: relative
    z-index: 1
    width: 100%!important

.c-exercise-video-card
    border-bottom-left-radius: 14px
    border-bottom-right-radius: 14px

.c-exercise-description
    transform: translateY(-40px)
    color: #fff
    margin: 0
    list-style: none
    padding-top: 5px
    padding-bottom: 0

    .uk-accordion-title
        font-size: .8em
        text-decoration: underline

    .uk-accordion-content
        padding: 20px 0
        h4
            color: #000

.c-exercise-description-html
    p
        color: #fff!important

.c-exercise-tags-wrapper
    display: inline
    background: #ffffff
    border-radius: 6px

.uk-dotnav > *
    padding-left: 6px

.uk-slideshow-items
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.06))
    li
        img
            filter: brightness(.95)

.c-exercise-dayindication
    font-size: .9em
    li
        padding: 0 .5em
        display: flex
        justify-content: center

.c-exercise-playbtn
    height: 50px
    width: 50px
    transition: all .2s ease-in-out
    z-index: 100
    .st0
      transition: all .2s ease-in-out
      stroke: none!important
      fill: #f8f8f8!important
    &:hover
        cursor: pointer
        .st0
          fill: #fff!important
          transition: all .2s ease-in-out

.uk-dotnav
    margin-left: 0

.c-exercise-switcher
    z-index: 500

@media screen and (max-width: 600px)
  .c-exercise-canvas
    .top-layout
      width: 80px
      top: 10px
      h4
        font-size: .7em!important
    .exercise-content,
        padding: 10px 10px!important
        h4
            font-size: .8em
        .c-exercise-sets,
        .c-exercise-pause
            font-size: .7em!important
        button
            font-size: .7em!important
            position: absolute
            right: 0px
            bottom: 0px
            padding: 0 8px!important
</style>
