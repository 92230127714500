<template>
  <div class="uk-padding-small c-hp-feedback-popup">
    <h3 class="uk-margin-top uk-margin-medium-bottom">
      Bitte hilf uns dir besser helfen zu können
    </h3>

    <el-form v-model="feedbackData">
      <el-form-item
        label="Wie würdest du dich fühlen, wenn du careety nicht mehr verwenden könntest?"
      >
        <el-radio-group v-model="feedbackData.rating" class="uk-width-expand">
          <div
            class="uk-flex-wrap uk-flex-start uk-grid-small uk-child-width-expand"
            uk-grid
          >
            <div class="c-radio-min-width">
              <el-radio label="3" border size="medium"
                >Sehr enttäuscht</el-radio
              >
            </div>
            <div class="c-radio-min-width">
              <el-radio label="2" border size="medium"
                >Etwas enttäuscht</el-radio
              >
            </div>
            <div class="c-radio-min-width">
              <el-radio label="1" border size="medium"
                >Nicht enttäuscht</el-radio
              >
            </div>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="feedbackData.rating"
        label="Wer würde deiner Meinung nach am meisten von careety profitieren?"
      >
        <el-input
          type="textarea"
          :rows="2"
          v-model="feedbackData.gain_most_text"
        >
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="feedbackData.rating"
        label="Was ist der Hauptnutzen, den du von careety erhältst?"
      >
        <el-input
          type="textarea"
          :rows="2"
          v-model="feedbackData.main_use_text"
        >
        </el-input>
      </el-form-item>

      <el-form-item
        v-if="feedbackData.rating"
        label="Wie können wir careety für dich verbessern?"
      >
        <el-input type="textarea" :rows="2" v-model="feedbackData.improve_text">
        </el-input>
      </el-form-item>
    </el-form>

    <el-button
      type="primary"
      class="uk-width-1-1"
      :loading="loading"
      @click="sendFeedback"
      :disabled="!feedbackData.rating"
      >Senden</el-button
    >

    <div class="uk-margin-top uk-margin-small-bottom uk-text-center">
      <span class="uk-margin-remove">Kann ich nicht sagen. </span>
      <span class="ask-later uk-text-bold uk-link" @click="askLater"
        >Später fragen</span
      >
    </div>
  </div>
</template>

<script>
import { apiHpFeedback } from "@/api";
import {
  healthProfessionalFeedbackPopupCheckandPropagate,
  healthProfessionalFeedbackPopupTrue,
} from "@/utils/popup";

export default {
  name: "HPFeedbackPopup",
  data() {
    return {
      loading: false,
      feedbackSend: false,
      feedbackData: {
        rating: "",
        gain_most_text: "",
        main_use_text: "",
        improve_text: "",
      },
    };
  },
  methods: {
    async sendFeedback() {
      this.loading = true;
      const data = {
        rating: this.feedbackData.rating,
        gain_most_text: this.feedbackData.gain_most_text,
        main_use_text: this.feedbackData.main_use_text,
        improve_text: this.feedbackData.improve_text,
      };
      setTimeout(async () => {
        try {
          await apiHpFeedback(data);
          healthProfessionalFeedbackPopupTrue();
          this.feedbackSend = true;
          this.$message({
            message: "Danke für dein Feedback",
            type: "success",
            offset: 80,
          });
          this.$emit("feedback-close");
          this.loading = false;
        } catch (err) {
          this.loading = false;
          if (err.response?.data?.err && err.response?.data?.err === 1630) {
            this.$emit("feedback-error");
            this.errorCode = err.response.data.err;
            this.$message({
              message: "Du hast bereits ein Feedback abgegeben",
              type: "error",
              offset: 80,
            });
            healthProfessionalFeedbackPopupTrue();
          } else if (
            err.response?.data?.err
            && err.response?.data?.err === 50
          ) {
            this.errorCode = err.response.data.err;
            this.$message({
              message: "Kein Feedback angegeben",
              type: "error",
              offset: 80,
            });
          } else {
            this.errorCode = -1; // display generic error message
            this.errorMsg = "Ein unerwarteter Fehler ist aufgetreten. Bitte verusche es etwas später erneut";
          }
        }
      }, 1000);
    },
    askLater() {
      this.$emit("ask-later");
    },
  },
  mounted() {
    this.feedbackSend = !healthProfessionalFeedbackPopupCheckandPropagate();
  },
};
</script>

<style lang="scss">
.c-hp-feedback-popup .el-form-item__label {
  line-height: 1.2;
  padding: 0 0 4px 0;
  color: #272725;
  font-weight: 500;
  word-break: break-word;
  text-align: left;
  margin-bottom: 2px;
}

.c-hp-feedback-popup .el-textarea__inner:focus {
  border-color: #272725;
}

.c-hp-feedback-popup .el-radio--medium.is-bordered .el-radio__label {
  font-size: 13px !important;
}

.c-hp-feedback-popup .el-radio--medium.is-bordered,
.c-hp-feedback-popup .el-textarea__inner,
.c-hp-feedback-popup .el-button {
  border-radius: 6px;
}

.c-hp-feedback-popup .el-form-item {
  margin-bottom: 2em;
}

// .c-hp-feedback-popup .el-textarea__inner {
//     background: rgba(235, 235, 235, 0.15);
// }

.c-hp-feedback-popup .c-radio-min-width {
  min-width: 140px;
  width: 100%;
}

.c-hp-feedback-popup .c-radio-min-width .el-radio {
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.c-hp-feedback-popup .el-radio.is-bordered.is-checked,
.c-hp-feedback-popup .el-radio__input.is-checked + .el-radio__label {
  border-color: #272725;
  color: #272725;
}
.c-hp-feedback-popup .el-radio__input.is-checked .el-radio__inner {
  background-color: #272725;
  border-color: #272725;
}

.c-hp-feedback-popup .el-radio__inner:hover {
  border-color: #272725;
}

.c-hp-feedback-popup .el-radio:hover {
  border-color: #272725;
  color: #272725;
}

.c-hp-feedback-popup .el-radio:hover .el-radio__inner {
  border-color: #272725;
  transition: all 0.2s ease-in-out;
}

.c-hp-feedback-popup .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none!important;
}

@media screen and (max-width: 505px) {
  .c-hp-feedback-popup .c-radio-min-width {
    max-width: 150px;
  }
}

@media screen and (max-width: 364px) {
  .c-hp-feedback-popup .c-radio-min-width {
    max-width: 100%;
    margin-top: 8px;
  }
}
</style>
