<template>
  <!-- TODO: add a loading animation/spinner or something -->

  <div
    class="uk-flex uk-height-medium uk-background-default uk-margin uk-padding-large"
    uk-height-viewport="offset-bottom: 30"
  >
    <div class="uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s">
      <div class="uk-width-large uk-margin-auto uk-margin-top">
        <div class="uk-text-center uk-margin-medium uk-animation-fade">
          <img
            src="@/assets/logo/careety-logo.png"
            uk-svg
            width="160"
            alt="careety Logo"
          />
        </div>
        <div
          class="uk-card uk-card-default uk-card-large uk-padding uk-animation-slide-bottom-medium"
          style="animation-delay: 500ms"
        >
          <h1 class="uk-text-bold uk-text-center c-login uk-margin-medium-bottom">
            Patienten Signup
          </h1>

          <div v-if="showInvalidMessage" class="uk-margin-medium-bottom">
            <p class="uk-text-center">Diese Einladung wurde bereits verwendet oder ist abgelaufen.</p>
          </div>
          <p class="uk-text-center uk-text-small uk-margin-small-bottom">
            Bereits angemeldet?
          </p>
          <router-link to="/login" class="uk-button uk-button-primary uk-width-expand">Weiter zum Login</router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ACTION_PATIENT_SIGNUP_CHECK_TOKEN } from "@/store/action.type";
import { MUTATION_SET_PATIENT_SIGNUP_0_INVITE_DATA } from "@/store/mutation.type";

export default {
  name: "SignupPatientWithToken",
  data() {
    return {
      showInvalidMessage: false,
    };
  },
  async mounted() {
    try {
      const tokenCheckResult = await this.$store.dispatch(ACTION_PATIENT_SIGNUP_CHECK_TOKEN, this.$route.params.token);
      await this.$store.commit(MUTATION_SET_PATIENT_SIGNUP_0_INVITE_DATA, tokenCheckResult);
      this.$router.replace("/signup/patient/");
    } catch (err) {
      this.showInvalidMessage = true;
    }
  },
};
</script>

<style lang="scss">

.uk-button {
  text-transform: initial;
}
</style>
