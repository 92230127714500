<template>
  <div class="uk-flex uk-height-medium uk-padding-large" uk-height-viewport="offset-bottom: 30">
    <div class="uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s">
      <div class="uk-width-large uk-margin-auto">
        <div class="uk-text-center uk-animation-slide-bottom-medium uk-card uk-card-default uk-padding" style="animation-delay: 300ms">
          <h1 class="uk-text-bold uk-text-center c-login uk-margin-medium-bottom">
            Login
          </h1>
          <el-form :model="login" :rules="VALIDATOR_RULES" ref="loginForm" v-on:submit.prevent>
            <el-form-item prop="username">
              <el-input
                v-model="login.username"
                clearable
                placeholder="E-Mail Adresse"
                type="email"
                name="email"
                id="email"
                autocomplete="username"
              ></el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                v-model="login.password"
                show-password
                placeholder="Passwort"
                type="password"
                name="current-password"
                id="current-password"
                autocomplete="current-password"
              ></el-input>
            </el-form-item>
            <div class="uk-margin-medium-top">
              <button
                class="uk-button uk-button-primary uk-width-expand uk-text-capitalize"
                @click="tryLogin()"
                type="submit"
                value="anmelden"
                @keydown="tryLogin()"
              >
                Anmelden
              </button>
            </div>
          </el-form>

          <div class="uk-text-small uk-text-center uk-margin-top">
            <router-link to="/request-password-reset">Passwort vergessen?</router-link>
          </div>
            <div v-if="loginErrorMessage" class="uk-margin-medium-top">
            <el-alert title="Fehler" type="error">
              <span>{{ loginErrorMessage }}</span>
            </el-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ACTION_TRY_LOGIN, ACTION_FETCH_PROFILE } from "@/store/action.type";

const VALIDATOR_RULES = {
  username: [
    { required: true, message: "Bitte E-Mail Adresse eingeben.", trigger: "blur" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "blur" },
  ],
  password: [
    { required: true, message: "Bitte Passwort eingeben.", trigger: "blur" },
    { min: 8, message: "Mindestens 8 Zeichen", trigger: "blur" },
  ],
};

export default {
  name: "Login",
  data() {
    return {
      login: {
        username: "",
        password: "",
      },
      loginErrorMessage: "",
    };
  },
  methods: {
    async tryLogin() {
      await this.$refs.loginForm.validate();
      const genericErrMsg = "Anmeldung fehlgeschlagen, bitte E-Mail Adresse und Passwort überprüfen.";
      try {
        await this.$store.dispatch(ACTION_TRY_LOGIN, {
          username: this.login.username,
          password: this.login.password,
        });
        await this.$store.dispatch(ACTION_FETCH_PROFILE);
        this.loginErrorMessage = "";
        if (this.$store.state.userProfile.is_health_professional) {
          this.$router.replace("/hp");
        } else {
          this.$router.replace("/treatment");
        }
      } catch (err) {
        // TODO: implement more detailed error handling when server returns
        // more specific error codes
        this.loginErrorMessage = genericErrMsg;
      }
    },
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
  },
};
</script>

<style lang="sass" scoped>
.uk-card
  padding-top: 30px
  .uk-text-small
    font-size: .8em
  .el-form-item
    margin-bottom: 24px!important
</style>
