<template>
  <el-form v-loading="loading"
    class="uk-grid-collapse uk-margin-large-bottom behaviour-item uk-animation-slide-bottom-small uk-card uk-card-default uk-flex-between uk-padding"
    uk-grid uk-margin :label-position="labelPosition" label-width="100px" :model="formLabelAlign">
    <div class="uk-margin-bottom uk-width-1-1 uk-width-4-5@s uk-padding-remove">
      <div>
        <el-form-item label="Beschreibung">
          <el-input type="textarea" maxlength="240" :autosize="{ minRows: 1, maxRows: 3 }"
            show-word-limit placeholder="Verhaltenstipp ..."
            v-model="singleTipItemLocal.text" :disabled="!isEditing">
          </el-input>
        </el-form-item>
      </div>
    </div>

    <div class="editor-menu uk-width-expand uk-width-1-4@s">
      <div v-if="!isEditing" class="uk-position-top-right uk-position-small">
        <el-button type="text" class="edit-btn" @click="editItem()">Bearbeiten</el-button>
      </div>
      <div v-if="isEditing" class="uk-margin">
        <el-button size="small" class="cancel-btn" @click="cancelEdit()">Abbrechen</el-button>
      </div>
      <div v-if="isEditing" class="uk-margin">
        <el-button size="small" type="primary" class="save-btn" @click="saveEdit()">Speichern</el-button>
      </div>
      <div v-if="isEditing && !isNew">
        <el-popconfirm confirmButtonText="Löschen" cancelButtonText="Abbrechen" icon="el-icon-info"
          iconColor="red" title="Möchtest du dieses Element löschen?"
          @confirm="deleteSingleTipItem">
          <template #reference>
            <el-button type="default" class="delete-btn" size="small">Löschen</el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>

  </el-form>
</template>

<script>
import { apiHpPatchTip, apiHPDeleteTip, apiHpAddTips } from "@/api";

export default {
  name: "TipItem",
  props: ["singleTipItem", "addNewItem"],
  emits: ["showEdit", "saveItem", "cancelEdit", "delete-item", "add-new-tip", "remove-object-from-array"],
  data() {
    return {
      isNew: this.addNewItem,
      loading: false,
      isEditing: this.addNewItem,
      labelPosition: "top",
      singleTipItemLocal: {
        text: this.singleTipItem.text,
        id: this.singleTipItem.id,
      },
    };
  },
  methods: {
    editItem() {
      this.isEditing = !this.isEditing;
    },
    cancelEdit() {
      this.isEditing = !this.isEditing;
      if (this.addNewItem === true) {
        this.$emit("remove-object-from-array");
      }
    },
    async saveEdit() {
      if (this.addNewItem === true && this.singleTipItemLocal.text !== "") {
        const data = {
          treatment_id: this.$route.params.id,
          text: this.singleTipItemLocal.text,
        };
        try {
          await apiHpAddTips(data).then((result) => {
            this.singleTipItemLocal.id = result.id;
          }).catch((err) => {
            console.log(err);
          });
          this.lodaing = false;
          this.$message({
            message: "Verhaltenstipp hinzugefügt",
            type: "success",
            offset: 80,
          });
        } catch (error) {
          console.log(error);
        }
        this.$emit("add-new-tip", this.singleTipItemLocal);
        this.isEditing = !this.isEditing;
      } else if (this.addNewItem === false && this.singleTipItemLocal.text !== "") {
        this.$emit("saveItem", this.singleTipItemLocal);
        await setTimeout(() => {
          apiHpPatchTip(this.singleTipItem.id, this.singleTipItem);
          this.$message({
            message: "Verhaltenstipp geändert",
            type: "success",
            offset: 80,
          });
        }, 800);
        this.isEditing = !this.isEditing;
      } else {
        this.$message({
          message: "Beschreibung ist leer",
          tye: "error",
          offset: 80,
        });
      }
    },
    async deleteSingleTipItem() {
      this.loading = true;
      await setTimeout(() => {
        try {
          this.$emit("delete-item", this.singleTipItemLocal);
        } catch (error) {
          console.log(error);
        }
        try {
          apiHPDeleteTip(this.singleTipItem.id);
        } catch (error) {
          console.log(error);
        }
        this.$message({
          message: "Verhaltenstipp gelöscht",
          offset: 80,
        });
        this.loading = false;
      }, 800);
      this.isEditing = false;
    },
  },

};
</script>

<style lang="scss">

.behaviour-item {
  margin: 50px 0;
}

.behaviour-item .el-form-item__label {
  font-size: .9em;
  font-weight: 500;
  color: #272725;
  padding: 0;
  line-height: 1.2;
}
.behaviour-item .el-input--suffix .el-input__inner {
  height: 32px;
}
.behaviour-item .el-form-item {
  margin-bottom: 5px!important
}
.behaviour-item .el-input__icon {
  line-height: 36px;
}
.behaviour-item .el-textarea__inner:focus {
  border-color: #4f4f4f;
}

.el-button--small {
  min-width: 90px;
}

.editor-menu {
  width: 100px;
  padding: 0;
  text-align: right;
}
.editor-menu .el-button--text {
  color:#4f4f4f;
}

.editor-menu .el-button--text:hover {
  text-decoration: underline;
}

@media screen and (max-width: 639px) {
  .editor-menu,
  .el-button--small {
    width: 100%;
  }
}
</style>
