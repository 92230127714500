<template>

  <div  class="uk-container uk-container-expand c-signup uk-cover-container " uk-height-viewport>
       <img src="@/assets/img/signup/signup.jpg" alt="careety" uk-cover>

       <div class="uk-container uk-container-xlarge uk-padding-large uk-animation-slide-bottom-small">
    <div class="uk-card uk-card-default uk-padding uk-width-3-4@s uk-width-1-2@m uk-width-1-1">
      <div class="uk-margin-small-bottom">
        <h1 class="uk-margin-remove">Sign UP</h1>
        <p class="uk-margin-remove">to be the best doctor you can be &#10084;&#65039; &#9996;&#65039;</p>
      </div>

      <div>
        <el-form :label-position="labelPosition" label-width="2000px" :model="signupForm" :rules="VALIDATOR_RULES" ref="signupForm" v-on:submit.prevent>

          <div class="uk-child-width-1-2@s uk-child-width-1-1 uk-grid-medium" uk-grid>
            <div>
              <el-form-item label="Vorname" prop="forename">
                <el-input v-model="signupForm.forename"
                type="text"
                name="given-name"
                id="given-name"></el-input>
              </el-form-item>
            </div>
            <div class="uk-margin-remove">
              <el-form-item label="Nachname" prop="surname">
                <el-input v-model="signupForm.surname"
                type="text"
                name="family-name"
                id="family-name"></el-input>
              </el-form-item>
            </div>
          </div>

            <div class="uk-child-width-1-2@s uk-child-width-1-1 uk-grid-medium uk-margin-small-top" uk-grid>
              <div>
                <el-form-item label="E-Mail" prop="email">
                  <el-input v-model="signupForm.email"
                  type="email"
                  name="email"
                  id="email"
                  autocomplete="username"></el-input>
                </el-form-item>
              </div>

            <div class="uk-margin-remove">
              <el-form-item label="Passwort" prop="password">
                <el-input v-model="signupForm.password"
                type="password"
                name="password"
                id="password"
                autocomplete="new-password"
                show-password></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="uk-margin-top radio-group">
            <el-form-item prop="newsletter_choice">
               <el-checkbox class="required" v-model="signupForm.newsletter_choice">Ich möchte über neue Therapiepläne, Inhalte und Features informiert werden ✌️</el-checkbox>
            </el-form-item>

            <el-form-item prop="agbs_confirmation">
                <el-checkbox v-model="signupForm.agbs_confirmation">Zustimmung <span class="c-ceckbox-text"><a href="#modal-agb" uk-toggle>AGB</a></span></el-checkbox>
            </el-form-item>

            <el-form-item prop="careety_dp_confirmation">
              <div class="uk-flex">
                <el-checkbox v-model="signupForm.careety_dp_confirmation">Zustimmung <span class="c-ceckbox-text"><a href="#modal-privacy" uk-toggle>Datenschutz</a></span></el-checkbox>
              </div>
            </el-form-item>

          </div>
        </el-form>

        <el-button type="primary" class="uk-width-3-4@s uk-width-1-2@m uk-width-1-1 uk-margin-top" :loading="loading" @click="tryRegister">Registrieren</el-button>
      </div>

      <div class="uk-margin-medium-top">
        <p class="uk-text-small">Du hast bereits ein Konto? <span class="uk-link uk-text-bold login-link"><router-link class="login-link" to="/login">Login</router-link></span> </p>
      </div>

    </div>
  </div>
  </div>

<el-dialog
  title="Datenschutzerklärung"
  v-model="showDialog"
  width="100%"
  :before-close="handleClose"
  custom-class="uk-padding c-basic-pages-dialog">
  <data-policy></data-policy>
</el-dialog>

  <div id="modal-agb" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-margin-remove">AGB</h2>
      </div>
      <div class="uk-modal-body" uk-overflow-auto>
        <p v-html="termsHealthProfessional.content"></p>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from "vuex";
import { apiHpSignup } from "@/api";
import { ACTION_FETCH_PROFILE, ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL } from "@/store/action.type";
import DataPolicy from "../components/widgets/DataPolicy.vue";

const VALIDATOR_RULES = {
  forename: [
    { required: true, message: "Bitte Vorname eingeben", trigger: "change" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "change" },
  ],
  surname: [
    { required: true, message: "Bitte Nachname eingeben", trigger: "change" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "change" },
  ],
  password: [
    { required: true, message: "Bitte Passwort eingeben", trigger: "change" },
    { min: 8, message: "Mindestens 8 Zeichen", trigger: "change" },
  ],
  email: [
    { required: true, message: "Bitte E-Mail Adresse eingeben", trigger: "change" },
    { min: 2, message: "Mindestens 3 Zeichen", trigger: "change" },
  ],
  agbs_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Bitte akzeptiere unsere AGB",
    },
  ],
  careety_dp_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Bitte akzeptiere unsere Datenschutzerklärung",
    },
  ],
  careety_dpa_confirmation: [
    {
      type: "boolean",
      required: true,
      validator: (rule, value) => value === true,
      message: "Bitte akzeptiere unsere Auftrags­verarbeitungs­vertrag",
    },
  ],
};

export default {
  name: "Signup",
  data() {
    return {
      showDialog: false,
      loading: false,
      labelPosition: "top",
      signuped: false,
      signupForm: {
        campaign_pin: this.$route.params.campaign_pin,
        forename: "",
        surname: "",
        email: "",
        password: "",
        newsletter_choice: false,
        agbs_confirmation: false,
        careety_dp_confirmation: false,
        careety_dpa_confirmation: false,
      },
    };
  },
  components: {
    DataPolicy,
  },
  methods: {
    async tryRegister() {
      const data = {
        campaign_pin: this.$route.params.campaign_pin,
        email: this.signupForm.email,
        password: this.signupForm.password,
        name_prefix: "",
        forename: this.signupForm.forename,
        surname: this.signupForm.surname,
        name_postfix: "",
        agbs_confirmation: this.signupForm.agbs_confirmation,
        careety_dp_confirmation: this.signupForm.careety_dp_confirmation,
        careety_dpa_confirmation: this.signupForm.careety_dpa_confirmation,
        newsletter_choice: this.signupForm.newsletter_choice,
      };
      try {
        this.loading = true;
        await this.$refs.signupForm.validate();
      } catch (error) {
        this.loading = false;
      }
      setTimeout(async () => {
        try {
          await apiHpSignup(data);
          this.$message({
            message: "Erfolgreich Registriert",
            type: "success",
          });
          this.signuped = true;
          await this.$store.dispatch(ACTION_FETCH_PROFILE);
          if (this.$store.state.userProfile.is_health_professional) {
            this.$router.replace("/hp");
          } else {
            this.$router.replace("/treatment");
          }
        } catch (err) {
          console.log("form validation error");
          this.loading = false;
          if (err.response?.data?.err && err.response?.data?.err === 59) {
            this.$message({
              message: "E-Mail bereits registriert",
              type: "error",
              offset: 80,
            });
          }
        }
      }, 2000);
    },
    callLoginForm() {
      this.$router.push("/login");
    },
    showDp() {
      this.showDialog = true;
    },
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
  },
  computed: {
    ...mapState(["promoCode", "termsHealthProfessional"]),
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL);
    } catch (err) {
      this.$message({
        message: "Ein unerwarteter Fehler ist aufgetreten",
        type: "error",
        offset: 80,
      });
    }
  },
};
</script>

<style>
.login-link {
  color: #FF7061;
}

.c-signup .el-form-item__label {
  line-height: 1;
  padding-bottom: 4px;
}

.c-signup .uk-card-default {
  box-shadow: 0px 0px 80px 2px rgba(0, 0, 0, 0.1)!important;
}

.c-signup .el-checkbox {
  white-space: normal!important;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.c-signup .el-input__inner {
  background: rgba(235, 235, 235, 0.15);
}

.c-signup .radio-group .el-form-item__error {
  position: relative;
  transition: all .3s ease-in-out;
  left: 24px;
}

.c-signup .el-form-item {
  margin-bottom: 16px;
}

c.signup .el-checkbox__label {
  display: inline-block;
  float: none;
}

.c-signup .el-form-item:not(:first-child) .el-checkbox__label:after {
  content: "*";
  color: #F56C6C;
  margin-left: 4px;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #4F4F4E;
}

.uk-modal-header {
  border-radius: 12px 12px 0 0;
}

.c-signup .el-form-item {
  min-height: 40px;
}

.c-signup .radio-group .el-form-item {
  margin-bottom: 0;
}
</style>
