<template>
  <div v-if="patientCase" class="uk-container uk-container-xlarge uk-padding-large uk-padding-remove-bottom">
    <div class="uk-grid-large" uk-grid>
      <div class="uk-width-3-5@m c-treatment-wrapper">

        <!-- Insert TreatmentIntro here -->
        <treatment-intro :patient-case="patientCase"></treatment-intro>
       <hr>

       <!-- Insert ComplicationsSection here -->
        <complications-section v-if="Object.keys(patientCase.complications).length" :patient-case="patientCase" :complicationsModal="complicationsModal"></complications-section>
      </div>

      <!-- Insert MyPersonalBox here -->
      <my-personal-box
        :patient-case="patientCase"
        :user-profile="userProfile"
        :complicationsModal="complicationsModal"
        :now-date="nowDate">
      </my-personal-box>
    </div>

      <!-- Insert SourceInfo here - if available -->

      <div id="spacer"></div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { parseTimedelta } from "@/utils/time";
import MyPersonalBox from "./mypersonalbox/MyPersonalBox.vue";
import ComplicationsSection from "./sections/ComplicationsSection.vue";
import TreatmentIntro from "./treatmentDetails/TreatmentDetailsSkeleton.vue";

export default {
  name: "TreatmentOverview",
  data() {
    return {
      isAccept: false,
    };
  },
  props: {
    patientCase: {
      type: Object,
      required: false,
    },
    userProfile: {
      type: Object,
      required: false,
    },
    complicationsModal: {
      type: Boolean,
      required: false,
    },
    nowDate: {
      type: Object,
      default: DateTime.local(),
    },
  },
  components: {
    MyPersonalBox,
    ComplicationsSection,
    TreatmentIntro,
  },
  computed: {
    planDurationInWeeks() {
      if (this.patientCase?.treatment?.duration) {
        return Math.round(parseTimedelta(this.patientCase.treatment.duration).as("weeks"));
      }
      return "n/a";
    },
    finishDateFmt() {
      if (this.patientCase?.treatment_case?.date && this.patientCase?.treatment?.duration) {
        return DateTime.fromISO(this.patientCase.treatment_case.date).plus(parseTimedelta(this.patientCase.treatment.duration)).toFormat("dd. LLLL", { locale: "de" });
      }
      return "n/a";
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style lang="sass" scoped>

@media screen and (max-width: 1000px )
  .c-medication-wrapper,
  .c-exercise-wrapper,
  .c-behavior-wrapper
    padding: 1em 0

</style>
