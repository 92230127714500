<template>
  <div
    v-if="patientCase?.tips?.length"
    class="uk-card uk-card-default uk-padding-remove uk-width-5-6@xl uk-align-right"
    uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
  >
    <div class="uk-card-header uk-flex uk-flex-between">
      <div class="uk-grid-small uk-flex-top" uk-grid>
        <img src="@/assets/icons/0521-diamond3.svg" width="45" height="45" uk-svg>
        <div class="c-card-remove-line-height">
          <h3 class="uk-card-title uk-margin-remove-bottom">Verhaltenstipps</h3>
          <!-- <span class="c-card-title-day">Heute</span> -->
        </div>
      </div>
    </div>

    <el-tooltip content="Mehr Informationen" placement="top" v-if="modal">
      <div class="svg-icon uk-position uk-position-top-right uk-position-small">
        <a href="#tipps-modal" uk-toggle class="card--info-btn">
          <img src="@/assets/icons/custom/information-icon-custom.svg" width="15" height="15"
            uk-svg /></a>
      </div>
    </el-tooltip>

    <div class="uk-card-body uk-margin-bottom">
      <div class="c-behavior-wrapper uk-grid-small uk-grid-match uk-flex-middle"
        v-for="tip in patientCase.tips" :key="tip" uk-grid>
          <div class="uk-width-auto c-behavior-pb c-svg-wrapper-max-width">
            <img src="@/assets/icons/0522-diamond4.svg" class="c-svg-max-width" width="24" height="24" uk-svg />
          </div>
        <div class="uk-width-expand">
          <p class="c-behavior-text">{{ tip.text }}</p>
        </div>
      </div>
    </div>

  </div>

  <div id="tipps-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-border-rounded uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-grid-small" uk-grid>
        <div class="uk-width-auto@s">
          <img src="@/assets/icons/0521-diamond3.svg" class="uk-margin-right c-svg-dark" width="30"
            height="30" uk-svg />
        </div>
        <div class="uk-width-4-5@s">
          <h3 class="uk-margin-remove">Verhaltenstipps</h3>
          <p class="uk-margin-remove c-treatment-start">
            {{ patientCase.behavior_recommendations_overall_progress.durationAsDays }} Tage</p>
          <p class="uk-margin-remove-top uk-margin-small-bottom c-treatment-duration">
            {{ patientCase.behavior_recommendations_overall_progress.startDate }}
            –
            {{ patientCase.behavior_recommendations_overall_progress.finishDate }}
          </p>
          <p class="uk-margin-remove">
            Hier finden Sie alle Verhaltenstipps zu Ihrem Therapieplan.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Verhaltenstipps",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    modal: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
};
</script>

<style  lang="scss" scoped>
.c-svg-max-width {
  max-width: 24px;
}
.c-svg-wrapper-max-width {
  max-width: 38px;
}
</style>
