const lockdownModeLookup = () => {
  if (
    typeof window === "object"
    && typeof window.location === "object"
    && typeof window.location.host === "string"
  ) {
    if (window.location.host === "alpha.careety.com") {
      return false;
    }
    if (window.location.host.startsWith("careety-staging")) {
      return false;
    }
    if (window.location.hostname === "localhost") {
      return false;
    }
  }
  return true;
};

export const lockdownMode = lockdownModeLookup();

const matomoSiteIdLookup = () => {
  if (
    typeof window === "object"
    && typeof window.location === "object"
    && typeof window.location.host === "string"
  ) {
    if (window.location.host === "careety.com") {
      return 1;
    }
    if (window.location.host === "alpha.careety.com") {
      return 3;
    }
    if (window.location.host.startsWith("careety-staging")) {
      return 11;
    }
  }
  return 0;
};

export const matomoSiteId = matomoSiteIdLookup();

export default {};
