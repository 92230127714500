<template>
  <div>
    <signup-header></signup-header>
    <div class="uk-container uk-container-xlarge uk-padding-remove-top uk-padding-large c-signup-container">
      <div class="uk-text-center c-signup-bread uk-margin-medium-bottom">
        <h1>Willkommen</h1>
        <div>
          <p class="c-signup-sub uk-animation-fade" style="animation-delay: 600ms">Bitte erstellen Sie zum Schutz Ihrer Daten ein passwortgesichertes, kostenloses Konto</p>
        </div>
      </div>

      <div class="uk-flex c-margin-mobile" uk-height-viewport="offset-bottom: 30">
          <signup-patient></signup-patient>
      </div>

    </div>
  </div>
</template>

<script>
import SignupHeader from "../components/signup/SignupHeader.vue";
import SignupPatient from "../components/signup/SignupPatient.vue";

export default {
  name: "SignupPatientWizzard",
  components: {
    SignupHeader,
    SignupPatient,
  },
};
</script>

<style lang="sass">
@import "../assets/scss/helpers/_variables.scss";
.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve)
    fill: #000

.c-signup-container
    p
        color: $c-black-main!important

    form
    .uk-radio:checked,
    .uk-checkbox:checked,
    .uk-checkbox:indeterminate
        background-color: $c-coral-main

    .uk-radio:focus,
    .uk-checkbox:focus
        border-color: $c-coral-main

    .c-signup-bread
        h1
            font-size: 2em
        h1,
        p
            margin: 0
        .c-signup-sub
            color: #202020!important
        .c-signup-sub
            font-size: .9em
    .c-signup-wrapper
        margin-top: 0px
        line-height: 1.6

        button
            display: block
            margin: 0 auto
        .uk-checkbox
            border-radius: 3px

@media screen and (max-width: 959px)
  .c-margin-mobile
    margin: 50px 0

</style>
