<template>
  <health-professional-header></health-professional-header>
  <div class="uk-container uk-container-xlarge uk-padding-large">
    <div class="uk-flex-top uk-flex-between" uk-grid>
      <div class="uk-margin-large-bottom c-dashboard-intro">
        <h1>Hallo, {{ userProfile.forename }}!</h1>
        <p class="uk-text-lead">{{ leadMsg }} &#10084;&#65039;</p>
      </div>
    </div>
    <div uk-filter="target: .js-filter">
      <h3>Therapiepläne</h3>

      <ul class="uk-subnav uk-subnav-pill uk-margin-medium-bottom">
        <li uk-filter-control="sort: data-diagnosis">
          <a href="#">Diagnose</a>
        </li>
        <li uk-filter-control="sort: data-cases; order: desc">
          <a href="#">PatientInnen</a>
        </li>
        <li uk-filter-control="sort: data-title">
          <a href="#">Name</a>
        </li>
      </ul>

      <div class="js-filter uk-grid-large uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-match" uk-grid>
        <div
          v-for="item in treatmentplans"
          :key="item.id"
          :data-diagnosis="item.diagnosis.name"
          :data-cases="item.case_count"
          :data-title="item.name"
        >
            <div
              class="c-treatment-dashboard-card uk-card uk-card-default uk-padding uk-body uk-flex uk-flex-between uk-flex-middle"
            >

              <div class="uk-flex uk-flex-middle uk-grid-small c-diangosis-img-wrapper" uk-grid>
                  <el-image class="uk-width-1-3@l c-custom-hover c-diagnosis--image" style="width: 120px; height: 60px;" :src="item.diagnosis.image_url"
                    @click="openSendRoute(item.id)"
                    fit="contain" :alt="item.name">
                    <template #error>
                      <div class="image-slot">
                          <img src="../../assets/img/healthprofessional/treatment/treatment-placeholder-square.png" @click="openSendRoute(item.id)" uk-img alt="careety">
                      </div>
                    </template>
                  </el-image>

                <div class="uk-width-expand@l uk-margin-small-right">
                  <el-tooltip content="Vorschau & Versand" placement="top">
                    <h3 class="uk-card-title c-custom-hover" @click="openSendRoute(item.id)">{{ item.name }}</h3>
                  </el-tooltip>
                  <h4 class="uk-card-subtitle">{{ item.diagnosis.name }}</h4>
                  <h5 v-if="userProfile.is_staff && item.author" class="uk-card-subsubtitle">{{ item.author }}</h5>
                  <div class="treatment-stats">
                    <span v-if="userProfile.is_admin" class="uk-margin-medium-right">Verschickt: {{ item.invite_count }}</span>
                    <span>PatientInnen: {{ item.case_count }}</span>
                </div>
                </div>
              </div>

              <div class="c-action-menu uk-flex uk-flex-middle uk-flex-between">
                <div>
                  <el-tooltip class="item" effect="dark" content="Vorschau" placement="top">
                    <div class="c-edit-plan" @click="openSendRoute(item.id)">
                        <img src="@/assets/icons/0166-eye.svg"
                          width="16"
                          class="c-edit-icon"
                          height="16" uk-svg />
                    </div>
                  </el-tooltip>
                </div>

                <div>
                  <el-tooltip class="item" effect="dark" content="Editieren" placement="top">
                    <div class="c-edit-plan" @click="openEditRoute(item.id)">
                        <img src="@/assets/icons/0014-pencil.svg"
                          width="16"
                          class="c-edit-icon"
                          height="16" uk-svg />
                    </div>
                  </el-tooltip>
                </div>

                <div v-if="userProfile.is_staff">
                  <el-tooltip class="item" effect="dark" content="Kopieren" placement="top">
                    <div>
                      <el-popconfirm confirmButtonText='Kopieren' cancelButtonText='Abbrechen'
                      icon="el-icon-info" iconColor="orange"
                      title="Möchten Sie diesen Plan kopieren?" @confirm="copyTreatment(item.id)">
                      <template #reference>
                        <div class="c-copy-plan">
                          <img src="@/assets/icons/0185-copy.svg" class=" c-copy-icon" width="16"
                            height="16" uk-svg />
                        </div>
                      </template>
                    </el-popconfirm>
                    </div>
                  </el-tooltip>
                </div>

                <div>
                  <el-tooltip class="item" effect="dark" content="Versenden" placement="top">
                    <div class="c-send-plan" @click="openQuickSend(item)">
                        <img src="@/assets/icons/0154-paper-plane.svg"
                          class="c-send-icon"
                          width="16"
                          height="16" uk-svg />
                    </div>
                  </el-tooltip>
                </div>

              </div>

            </div>

        </div>
      </div>
    </div>
  </div>

  <el-dialog
  @close-quick-send-dialog="closeDialog"
    custom-class="send-treatment-modal uk-text-center uk-padding"
    v-model="quickSendVisible"
    width="100%"
    :destroy-on-close="true">
      <quick-send-plan-dialog
        @close-quick-send-dialog="closeDialog"
        :patient-case="patientCase">
      </quick-send-plan-dialog>
  </el-dialog>

  <accept-bar-health-progessional></accept-bar-health-progessional>

  <careety-footer :user-profile="userProfile"></careety-footer>
</template>

<script>
import { ACTION_FETCH_TREATMENTPLANS_TO_SEND } from "@/store/action.type";
import { apiHpTreatmentCopy } from "@/api";
import { mapState } from "vuex";

import HealthProfessionalHeader from "../../components/HealthProfessional/TheHeader.vue";
import QuickSendPlanDialog from "../../components/HealthProfessional/QuickSendPlanDialog.vue";
import AcceptBarHealthProgessional from "../../components/UI/AcceptBarHealthProfessional.vue";

export default {
  name: "HealthProfessionalDasboard",
  data() {
    return {
      leadMsg: "be the best doctor you can be",
      leadLink: "Wie können wir dir noch dabei helfen?",
      diagnosisPlaceholderImage: "@/assets/img/healthprofessional/treatment/treatment-placeholder.png",
      quickSendVisible: false,
      patientCase: "",
    };
  },
  components: {
    HealthProfessionalHeader,
    QuickSendPlanDialog,
    AcceptBarHealthProgessional,
  },
  computed: {
    ...mapState(["treatmentplans", "userProfile"]),
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_FETCH_TREATMENTPLANS_TO_SEND);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async copyTreatment(id) {
      try {
        const response = await apiHpTreatmentCopy(id);
        this.treatments = response.data;
        this.$notify({
          message: "Plan kopiert",
          type: "success",
          offset: 80,
        });
      } catch (error) {
        console.log(error);
      }
      try {
        this.$store.dispatch(ACTION_FETCH_TREATMENTPLANS_TO_SEND);
      } catch (error) {
        console.log(error);
      }
    },
    openEditRoute(treatmentId) {
      this.$router.push({ name: "TreatmentDetails", params: { id: treatmentId } });
    },
    openSendRoute(treatmentId) {
      this.$router.push({ name: "HealthProfessionalTreatmentSend", params: { id: treatmentId } });
    },
    openQuickSend(item) {
      this.quickSendVisible = true;
      this.patientCase = item;
    },
    closeDialog() {
      this.quickSendVisible = false;
      this.$message({
        message: "Plan verschickt",
        type: "success",
        offset: 80,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.c-dashboard-intro h1 {
    font-size: 2.2em;
    margin-bottom: 0;
}

.c-dashboard-intro .uk-text-lead {
    margin-top: 0;
    color: #4F4F4E;
    font-size: 1em;
}

.c-custom-hover:hover {
  cursor: pointer;
}

.c-diagnosis--image {
  transition: all .3s linear;
}
.c-diagnosis--image:hover {
  transform: scale(1.05);
}

.c-dashboard-intro a {
    color: #979797 !important;
    font-size: 1em;
    letter-spacing: .19px;
}

li a {
    text-transform: capitalize;
    padding-bottom: 0;
    font-weight: 500;
}

.c-treatment-dashboard-card {
    border-radius: 16px;
}

.c-treatment-dashboard-card .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
    transition: all .3s ease-in-out;
}

.c-treatment-dashboard-card .c-treatment-img {
    height: 60px;
}

.c-treatment-dashboard-card h3,
.c-treatment-dashboard-card h4,
.c-treatment-dashboard-card h5 {
    margin: 0;
}

.c-treatment-dashboard-card .uk-card-title {
    font-size: 1.35rem;
    margin-bottom: 0px;
}

.c-treatment-dashboard-card .uk-card-subtitle {
    font-size: .9rem;
    color: #4a4a4a;
    font-weight: 500;
    word-break: break-word;
    hyphens: auto;
}

.c-treatment-dashboard-card .uk-card-subsubtitle {
    font-size: 0.7rem;
    color: #4a4a4a;
    font-weight: 400;
    margin-top: 4px;
    word-break: break-word;
    hyphens: auto;
}

.c-treatment-dashboard-card .header-title,
.c-treatment-dashboard-card .header-numbers,
.c-treatment-dashboard-card .footer-title,
.c-treatment-dashboard-card .footer-numbers {
    font-size: .8em;
    display: block;
    line-height: 1.4;
}

.c-treatment-dashboard-card .header-title {
    color: #979797;
}

.c-treatment-dashboard-card .header-numbers {
    color: #000;
    font-weight: 500;
}

.c-treatment-dashboard-card .footer-numbers {
    color: #000;
}

.c-treatment-dashboard-card .card-tabs button {
    font-size: .8em;
}

.c-treatment-dashboard-card .uk-progress {
    margin: 8px 0 5px 0;
    height: 10px !important;
}

.c-treatment-dashboard-card .uk-progress::-webkit-progress-value {
    background-color: #000;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.c-treatment-dashboard-card .uk-progress::-moz-progress-bar {
    background-color: #D8D8D8;
}

.c-treatment-dashboard-card .uk-progress::-webkit-progress-value {
    background-color: #000;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.c-treatment-dashboard-card .uk-progress::-webkit-progress-bar {
    background-color: #D8D8D8;
}

.c-treatment-dashboard-card .treatment-graph {
    padding: 3em 0;
}

.c-treatment-dashboard-card .c-action-menu {
    text-align: center;
}

.c-treatment-dashboard-card .header-seperator {
    position: relative;
}

.c-treatment-dashboard-card .header-seperator::after {
    content: "";
    height: 40px;
    width: 1px;
    background: #f5f5f5;
    position: absolute;
    top: 0;
    right: -32px;
}

.c-treatment-dashboard-card .c-send-plan,
.c-treatment-dashboard-card .c-copy-plan,
.c-treatment-dashboard-card .c-edit-plan {
    min-width: 20px !important;
    min-height: 25px !important;
    padding: 8px;
    border-radius: 8px;
}

.c-treatment-dashboard-card .c-send-plan:hover,
.c-treatment-dashboard-card .c-copy-plan:hover,
.c-treatment-dashboard-card .c-edit-plan:hover {
    cursor: pointer;
    background: #f8f8f8;
}

.c-treatment-dashboard-card .c-send-plan .c-send-icon,
.c-treatment-dashboard-card .c-send-plan .c-copy-icon,
.c-treatment-dashboard-card .c-send-plan .c-preview-icon,
.c-treatment-dashboard-card .c-copy-plan .c-send-icon,
.c-treatment-dashboard-card .c-copy-plan .c-copy-icon,
.c-treatment-dashboard-card .c-copy-plan .c-preview-icon,
.c-treatment-dashboard-card .c-edit-plan .c-send-icon,
.c-treatment-dashboard-card .c-edit-plan .c-copy-icon,
.c-treatment-dashboard-card .c-edit-plan .c-preview-icon {
    min-width: 16px !important;
}

.c-treatment-dashboard-card .planview-wrapper {
    position: relative;
}

.c-treatment-dashboard-card .planview-wrapper .planview-counter {
    font-size: .8em;
}

.c-treatment-dashboard-card .planview-wrapper .planview-dot {
    position: absolute;
    height: 10px;
    width: 10px;
    left: -24px;
}

.c-treatment-dashboard-card .planview-wrapper .planview-dot::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    z-index: 2;
    left: 0px;
    top: 0px;
    width: 10px;
    height: 10px;
    background-color: #FF7061;
    border-radius: 50%;
}

.c-treatment-dashboard-card .planview-wrapper .planview-dot::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 6px;
    height: 6px;
    background-color: #FF7061;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
    -webkit-animation-name: "ripple";
    -webkit-animation-duration: 1.6s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
}

/* filter */
.uk-subnav-pill > * > :first-child {
  border-radius: 6px;
  color: #4F4F4E;
  text-transform: none;
  font-size: .98em;
}

.uk-subnav-pill > .uk-active > a {
  background-color: #EBEBEB;
  color: #272725;
}

@keyframes ripple {
    0% {
        left: 5px;
        top: 5px;
        opcity: 75;
        width: 0;
        height: 0;
    }
    100% {
        left: -20px;
        top: -20px;
        opacity: 0;
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 880px) {
    .planview-wrapper {
        margin: 0 auto;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
  .c-treatment-dashboard-card .c-action-menu {
    flex-direction: column!important;
  }
}

@media screen and (max-width: 450px) {
    .c-treatment-dashboard-card {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .c-treatment-dashboard-card .c-action-menu {
        margin-top: 20px;
        width: 100%;
        flex-direction: row!important;
    }
    .c-diangosis-img-wrapper .el-image {
      margin: 0 auto;
      width: 80%!important;
      height: auto!important;
      margin-bottom: 10px;
    }
}

.treatment-stats {
  margin-top: 10px;
  font-size: .86em;
  font-weight: 500;
  color: #333;
}

</style>
