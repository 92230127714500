<template>
  <div id="step3">
    <div class="c-signup-wrapper uk-grid-large uk-flex-middle" uk-grid>
      <div
        class="uk-grid-small uk-width-1-2@s"
        uk-grid
        uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
        style="animation-delay: 400ms"
      >
        <div class="uk-width-auto">
          <img
            src="@/assets/icons/0105-shield-check.svg"
            class="uk-margin-right"
            width="35"
            height="35"
            uk-svg
          />
        </div>
        <div class="uk-width-4-5@s c-signup-person-content">
          <h3>Konto erstellen</h3>
          <p>Um ein Konto zu erstellen müssen wir nun ein wenig mehr über Sie erfahren.</p>
          <img
            class="uk-border-rounded uk-box-shadow-medium"
            src="@/assets/img/signup/signup-03.jpg"
            alt="Ihr digitaler Therapieplan"
          />
        </div>
      </div>

      <div
        class="uk-width-xlarge@l uk-width-1-2@s"
        uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
        style="animation-delay: 800ms"
      >
        <div class="uk-card uk-card-default uk-card-body">

        <el-form :model="signupProfile" :rules="VALIDATOR_RULES" ref="signupProfileForm" class="c-signup-person-form">

          <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="forename" label="Vorname">
                <el-input v-model="signupProfile.forename" type="text" name="forename" id="forename" clearable></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="surname" label="Nachname">
                <el-input v-model="signupProfile.surname" type="text" name="surname" id="surname" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="username" label="Username">
                <el-input v-model="username" :disabled="true" type="email" id="email" name="email" autocomplete="username"></el-input>
                <span class="c-label-bottom"> Locked durch Host des Therapieplans.</span>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <el-form-item prop="password" label="Passwort">
                <el-input v-model="signupProfile.password" show-password type="password" name="password" id="password"></el-input>
                <span class="c-label-bottom"> mind. 8+ Zeichen</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        </div>
      </div>
    </div>
    <div
      class="uk-flex-center uk-grid-small __mobile-margin"
      uk-grid
      uk-scrollspy="cls: uk-animation-slide-bottom-small; repeat: true"
      style="animation-delay: 1000ms"
    >
      <div>
        <button
          class="uk-button uk-button-default uk-width-small uk-text-capitalize"
          type="button"
          @click.prevent="prev()"
        >
          Zurück
        </button>
      </div>

      <div>
        <button
          class="uk-button uk-button-primary uk-width-auto uk-text-capitalize"
          type="submit"
          @click="validateAndCompletePatientSignup()"
        >
          Zum Therapieplan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ACTION_PATIENT_SIGNUP, ACTION_UPDATE_PROFILE } from "@/store/action.type";
import { MUTATION_SET_PATIENT_SIGNUP_3_PROFILE } from "@/store/mutation.type";

const VALIDATOR_RULES = {
  forename: [
    { required: true, message: "Bitte gib deinen Vornamen ein", trigger: "blur" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "blur" },
  ],
  surname: [
    { required: true, message: "Bitte gib deinen Nachnamen ein", trigger: "blur" },
    { min: 2, message: "Mindestens 2 Zeichen", trigger: "blur" },
  ],
  password: [
    { required: true, message: "Bitte gib dein Passwort ein.", trigger: "blur" },
    { min: 8, message: "Mindestens 8 Zeichen", trigger: "blur" },
  ],
};

export default {
  name: "SignupStepThree",
  data() {
    return {
      signupProfile: {
        forename: "",
        surname: "",
        password: "",
      },
      username: "",
    };
  },
  methods: {
    async validateAndCompletePatientSignup() {
      // validate
      try {
        await this.$refs.signupProfileForm.validate();
        await this.$store.commit(MUTATION_SET_PATIENT_SIGNUP_3_PROFILE, this.signupProfile);
      } catch (err) {
        console.log("signupPatientForm validation error");
        return;
      }

      try {
        // we need to keep a separate reference of profile update args here
        // becuase the signup action clears the store
        const updateArgs = {
          forename: this.$store.state.patientSignup3Profile.forename,
          surname: this.$store.state.patientSignup3Profile.surname,
        };
        await this.$store.dispatch(ACTION_PATIENT_SIGNUP);
        await this.$store.dispatch(ACTION_UPDATE_PROFILE, updateArgs);
      } catch (err) {
        console.log(`signup error: ${err}`);
        // TODO: display error message
        return;
      }

      // all done, go to treatment
      this.$router.push("/treatment");
    },
    async prev() {
      await this.$store.commit(MUTATION_SET_PATIENT_SIGNUP_3_PROFILE, this.signupProfile);
      this.$router.push("/signup/patient/2");
    },
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
  },
  mounted() {
    if (this.$store.state.patientSignup0InviteData) {
      this.username = this.$store.state.patientSignup0InviteData.email;
    }
    if (this.$store.state.patientSignup3Profile) {
      this.signupProfile = this.$store.state.patientSignup3Profile;
    }
  },
};
</script>

<style lang="sass" scoped>

.c-signup-person-content
    img
        margin-bottom: 1.6em
.c-signup-person-form
    .c-label-bottom
        font-size: .7em
        color: #A6A6A6
        display: block
        line-height: 1.7
        margin-top: 8px
    .c-form-link-secondary
        text-decoration: underline
        display: block
        margin-top: 16px
    .c-checkbox-paragraph
        display: block
@media screen and (max-width: 640px )
  .__mobile-margin
    margin-top: 32px
</style>
