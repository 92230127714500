<template>
  <div class="uk-flex uk-flex-wrap uk-margin-bottom uk-width-1-3@s uk-width-auto@m uk-width-1-1 uk-padding-remove">
          <div class="integer-wrapper uk-width-expand">
          <el-form-item label="Dauer">

             <el-input-number
              v-model="durationValue"
              :max="99"
              size="small"
              :step="1"
              :controls="false"
              :disabled="!isEditing"
              @change="handleValueChange"
              pattern="[0-9]*"
              inputmode="numeric"
              ></el-input-number>
          </el-form-item>

          <el-form-item>
              <el-select v-model="timeSpanDuration" :disabled="!isEditing" placeholder="Zeitspanne wählen" clearable @change="handleValueChange">
                <el-option
                v-for="value in values"
                :key="value"
                :label="value.value"
                :value="value.value"
                :disabled="!isEditing">
                </el-option>
              </el-select>
            </el-form-item>
        </div>
      </div>
</template>

<script>
import { Duration } from "luxon";

export default {
  name: "DurationPicker",
  props: ["isEditing", "modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      durationValue: this.modelValue.as("days"),
      values: [
        {
          value: "Tag(e)",
          label: "Tag(e)",
        },
        {
          value: "Woche(n)",
          label: "Woche(n)",
        },

      ],
      timeSpanDuration: "Tag(e)",
    };
  },
  methods: {
    handleValueChange() {
      if (this.timeSpanDuration === "Tag(e)") {
        this.$emit("update:modelValue", Duration.fromObject({ days: this.durationValue }));
      }
      if (this.timeSpanDuration === "Woche(n)") {
        this.$emit("update:modelValue", Duration.fromObject({ weeks: this.durationValue }));
      }
    },
  },
};
</script>
