<template>
  <div class="uk-margin-small-bottom uk-margin-small-top"
    v-html="patientCase?.treatment?.med_office_intervention">
  </div>
</template>

<script>
export default {
  name: "TreatmentHpText",
  props: {
    patientCase: {
      type: Object,
      reuired: true,
    },
  },
};
</script>
