<template>
  <div v-if="!hideAcceptBar" class="uk-container uk-container-large c-banner-wrapper">

    <div class="uk-text-left uk-card uk-card-default c-banner-content uk-animation-slide-bottom-small uk-flex uk-flex-between uk-flex-wrap uk-flex-middle">
        <div class="uk-width-1-1 uk-width-expand@s">
          <p>Durch Nutzung dieses Dienstes stimmst du unseren <u><a href="/agb-health-professional/">Geschäftsbedingungen</a></u> zu. Wir verwenden Cookies, um dir unseren Service anzubieten, so wie <u><a href="/datenschutz/">hier</a></u> beschrieben.</p>
        </div>

      <div class="uk-width-1-1 uk-width-1-5@s uk-text-right">
        <button @click="consentGrantedTerms" class="uk-button uk-button-primary c-banner-btn uk-text-capitalize"> Verstanden </button>
      </div>

    </div>

  </div>
</template>

<script>
import {
  healthProfessionalConsentTermsCheckandProagate,
  healthProfessionalConsentTerms,
} from "@/utils/consent";

export default {
  name: "AcceptBarHealthProgessional",
  data() {
    return {
      hideAcceptBar: false,
    };
  },
  methods: {
    consentGrantedTerms() {
      this.hideAcceptBar = true;
      healthProfessionalConsentTerms();
    },
  },
  async mounted() {
    this.hideAcceptBar = !healthProfessionalConsentTermsCheckandProagate();
  },
};
</script>

<style lang="sass" scoped>

.c-banner-wrapper
    position: fixed
    bottom: 25px
    left: 0
    right: 0
    min-width: 200px
    max-width: 90%
    z-index: 6000

.c-banner-content
    padding: 20px 30px
    border-radius: 12px
    margin: 0 auto
    animation-delay: 1200ms
    border: 1px solid #f6f6f6

.c-banner-content p
    font-size: .88em
    margin-top: 0px!important
    margin-bottom: 0px!important
    color: #000!important
    line-height: 1.5
    margin-right: 10px

@media screen and (max-width: 639px)
    .c-banner-content p
      margin-right: 0
    .c-banner-btn
        margin-top: 16px
        width: 100%
</style>
