<template>
  <div class="c-complication-item uk-flex uk-margin-bottom uk-width-1-1 uk-width-1-1@m">
    <slot name="doctorOnly"> </slot>

    <slot name="emergencyOnly"> </slot>

    <div class="c-complication-remove-btn uk-margin-small-left">
      <el-popconfirm
        confirmButtonText="Entfernen"
        cancelButtonText="Abbrechen"
        icon="el-icon-info"
        iconColor="red"
        title="Möchtest du diese Komplikation entfernen?"
        @confirm="removeItem"
      >
        <template #reference>
          <div class="uk-flex uk-flex-center uk-flex-middle">
            <img
              src="../../../assets/icons/0811-cross.svg"
              alt="Komplikation entfernen"
              class="complication-remove-icon"
              width="12"
              height="12"
              uk-svg
            />
          </div>
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplicationItem",
  props: ["singleComplicationItem"],
  data() {
    return {
      loading: false,
      isEditing: false,
      labelPosition: "top",
      singleComplicationItemLocal: {
        complication_type: this.singleComplicationItem.complication_type,
        deprecated_icon_path: this.singleComplicationItem.deprecated_icon_path,
        description: this.singleComplicationItem.description,
        icon_name: this.singleComplicationItem.icon_name,
        id: this.singleComplicationItem.id,
        name: this.singleComplicationItem.name,
      },
    };
  },
  methods: {
    async removeItem() {
      try {
        this.$emit("remove-item", this.singleComplicationItemLocal);
        await this.$message({
          message: "Komplikation entfernt",
          offset: 80,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.c-complication-item {
  padding: 4px 0;
}

.c-complication-remove-btn {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.c-complication-remove-btn:hover {
  box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, 6%);
  cursor: pointer;
}

.c-complication-remove-btn .uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: #ccc;
  transition: all 0.3s ease-in-out;
}

.c-complication-remove-btn:hover .uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: #333;
}

</style>
