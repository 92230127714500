<script>
export default {
  name: "Chat",
  data() {
    return {
      src: "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/3f233d05b33b4eba870a2b96a97ebdb9be05090d93274befbd431f1b1bc114a7.js",
    };
  },
  mounted() {
    const userLikeChat = document.createElement("script");
    userLikeChat.setAttribute("src", this.src);
    document.head.appendChild(userLikeChat);
  },
};
</script>

<style lang="scss">

</style>
