<template>
  <nav
    class="uk-navbar uk-container uk-container-xlarge uk-black uk-padding-large uk-padding-remove-top uk-padding-remove-bottom"
    uk-navbar
  >
    <div class="uk-navbar-left">
      <router-link to="/" class="uk-navbar-item uk-logo uk-padding-remove"
        ><img src="@/assets/logo/careety-logo.png" width="120"
      /></router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SignupHeader",
};
</script>

<style></style>
