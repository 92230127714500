<template>
    <div v-html="privacy.content"></div>
</template>

<script>
import { ACTION_ASSURE_FETCHED_PRIVACY } from "@/store/action.type";
import { mapState } from "vuex";

export default {
  name: "Privacy",
  props: {
    userProfile: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_PRIVACY);
    } catch (err) {
      // TODO: handle errors
    }
  },
  computed: {
    ...mapState(["privacy"]),
  },
};
</script>

<style>

</style>
