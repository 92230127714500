<template>
  <div class="editor-component-wrapper uk-margin-large-bottom uk-width-2-4@s uk-width-1-1 uk-align-center">
    <div class="uk-margin-large-bottom">
      <h3 class="editor-title">Komplikationen</h3>
      <p v-if="complications?.length" class="editor-subtitle">
        Lösche Komplikationen oder wähle neue aus unserem Katalog aus.
      </p>
      <p v-else class="editor-subtitle">Im Moment sind keine Komplikationen zugewiesen.</p>
    </div>

    <div class="uk-margin-large-top uk-grid-large" uk-grid>
      <div
        class="uk-width-1-2@m uk-width-2-3@s uk-width-1-1 uk-animation-slide-bottom-small uk-margin-large-bottom"
      >
        <h4>Wann Sie uns kontaktieren sollten</h4>
        <complication-item
          :single-complication-item="item"
          v-for="item in doctorComplications"
          :key="item.name"
          @remove-item="remove"
          @add-complication="addComplicationItem"
        >
          <template v-slot:doctorOnly>
            <div class="uk-flex">
              <div class="svg-icon uk-margin-small-right">
                <img
                  :src="require(`@/assets/icons/${item?.icon_name}.svg`)"
                  width="20"
                  height="20"
                  uk-svg
                />
              </div>
              <p class="uk-margin-remove">{{ item?.name }}</p>
            </div>
          </template>
        </complication-item>
        <div v-if="complications">
          <complication-search
            @add-complication="addComplicationItem"
            >
          </complication-search>
        </div>
      </div>

      <div class="uk-width-1-2@m uk-width-2-3@s uk-width-1-1 uk-animation-slide-bottom-small">
        <h4>Wann Sie in die Notaufnahme sollten</h4>
        <complication-item
          :single-complication-item="item"
          v-for="item in emergencyComplications"
          :key="item.name"
          @remove-item="remove"
          @add-complication="addComplicationItem"
        >
          <template v-slot:emergencyOnly>
            <div class="uk-flex">
              <div class="svg-icon uk-margin-small-right">
                <img
                  :src="require(`@/assets/icons/${item?.icon_name}.svg`)"
                  width="20"
                  height="20"
                  uk-svg
                />
              </div>
              <p class="uk-margin-remove">{{ item?.name }}</p>
            </div>
          </template>
        </complication-item>
        <div
          v-if="complications">
          <complication-search
            @add-complication="addComplicationItem"
            isEmergencySearch="true"
          >
          </complication-search>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComplicationSearch from "./ComplicationSearch.vue";
import ComplicationItem from "./ComplicationItem.vue";

export default {
  name: "ComplicationEditor",
  emits: ["add-complication", "remove-parent-complication"],
  components: {
    ComplicationItem,
    ComplicationSearch,
  },
  props: {
    complications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      labelPosition: "right",
    };
  },
  methods: {
    remove(singleComplicationItemLocal) {
      this.$emit("remove-parent-complication", singleComplicationItemLocal);
    },
    addComplicationItem(singleComplicationItem) {
      this.$emit("add-complication", singleComplicationItem);
    },
  },
  computed: {
    emergencyComplications() {
      if (this.complications) {
        return this.complications.filter((emergency) => emergency.complication_type === "emergency");
      }
      return [];
    },

    doctorComplications() {
      if (this.complications) {
        return this.complications.filter((doctor) => doctor.complication_type === "doctor");
      }
      return [];
    },
  },
};
</script>
