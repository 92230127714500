<template>
  <div>
    <careety-header
      :patient-case="patientCase"
      :patient-diagnosis="patientDiagnosis"
    ></careety-header>

    <treatment-overview
      :patient-case="patientCase"
      :user-profile="userProfile"
      :complicationsModal="true"
    ></treatment-overview>

    <careety-footer :user-profile="userProfile"></careety-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ACTION_ASSURE_FETCHED_DIAGNOSIS, ACTION_ASSURE_FETCHED_CASE } from "@/store/action.type";

export default {
  name: "Treatment",
  async mounted() {
    try {
      this.$store.dispatch(ACTION_ASSURE_FETCHED_CASE);
      this.$store.dispatch(ACTION_ASSURE_FETCHED_DIAGNOSIS);
    } catch (err) {
      // TODO: handle errors
    }
  },
  computed: {
    ...mapState(["userProfile", "patientDiagnosis", "patientCase"]),
  },
  props: {
    patientCase: {
      type: Object,
    },
  },
};
</script>
