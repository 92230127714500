<template>
  <div v-if="!feedbackSend" class="uk-text-center uk-padding-small c-patient-feedback-popup">
    <el-image
      class="uk-border-circle uk-box-shadow-large uk-margin-top uk-margin-bottom"
      style="width: 100px; height: 100px"
      :src="healthProfessional?.profile_image"
      :fit="fit"
    >
    </el-image>

    <h3 class="uk-card-title uk-margin-bottom">
      Wie nützlich finden Sie diesen <br />digitalen Therapieplan?
    </h3>
    <el-form :model="form">
      <div class="uk-margin-top uk-margin-medium-bottom">
        <el-rate
          v-model="form.ratingValue"
          :texts="[
            'nicht nützlich',
            'wenig nützlich',
            'nützlich',
            'etwas nützlich',
            'sehr nützlich',
          ]"
          show-text
          :colors="colors"
        >
        </el-rate>
      </div>

      <h4 class="uk-margin-small-bottom uk-margin-top">
        Was könnten wir verbessern?
      </h4>
      <el-form-item>
        <el-input
          type="textarea"
          :rows="3"
          v-model="form.feedback"
          placeholder="Ihr Feedback ..."
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button class="uk-width-1-1" type="primary" @click="sendFeedback" :loading="loading" :disabled="form.ratingValue === 0"
      >Senden</el-button
    >

    <div class="uk-margin-top uk-margin-small-bottom">
      <span class="uk-margin-remove">Kann ich noch nicht sagen, </span>
      <span class="ask-later uk-text-bold uk-link" @click="askLater">später nochmal fragen</span>
    </div>
  </div>
</template>

<script>
import { apiPatientFeedback } from "@/api";
import { patientFeedbackPopupCheckandPropagate, patientFeedbackPopupTrue } from "@/utils/popup";

export default {
  name: "PatientFeedbackPopup",
  data() {
    return {
      loading: false,
      feedbackSend: false,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      form: {
        ratingValue: null,
        feedback: "",
      },
      errorCode: null,
      errorMsg: "",
    };
  },
  props: {
    healthProfessional: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async sendFeedback() {
      const data = {
        rating: this.form.ratingValue,
        feedback_text: this.form.feedback,
      };
      try {
        this.loading = true;
        await apiPatientFeedback(data);
        patientFeedbackPopupTrue();
        setTimeout(() => {
          this.feedbackSend = true;
          this.$message({
            message: "Danke für Ihr Feedback",
            type: "success",
            offset: 80,
          });
          this.$emit("feedback-close");
        }, 1000);
      } catch (err) {
        this.$emit("feedback-error");
        if (err.response?.data?.err && err.response?.data?.err === 1630) {
          this.errorCode = err.response.data.err;
          this.$message({
            message: "Sie haben bereits ein Feedback abgegeben",
            type: "error",
            offset: 80,
          });
          patientFeedbackPopupTrue();
        } else if (err.response?.data?.err && err.response?.data?.err === 50) {
          this.errorCode = err.response.data.err;
          this.$message({
            message: "Kein Feedback angegeben",
            type: "error",
            offset: 80,
          });
        } else {
          this.errorCode = -1; // display generic error message
          this.errorMsg = "Ein unerwarteter Fehler ist aufgetreten. Bitte veruschen Sie es etwas später erneut";
        }
      }
    },
    askLater() {
      this.$emit("ask-later");
    },
  },
  mounted() {
    this.feedbackSend = !patientFeedbackPopupCheckandPropagate();
  },
};
</script>

<style lang="scss">
.c-patient-feedback-popup .el-rate__text {
  display: block;
  margin-top: 10px !important;
}
.c-patient-feedback-popup h3 {
  font-size: 1.6em;
  line-height: 1.2 !important;
}

.c-patient-feedback-popup h4 {
  font-weight: 500;
  font-size: 1.05em;
}

.c-patient-feedback-popup .el-rate__icon {
  font-size: 24px;
}

.c-patient-feedback-popup .el-textarea__inner:focus {
  border-color: #272727;
}

@media screen and (max-width: 400px) {
  .c-patient-feedback-popup h3 {
    font-size: 1.3em!important;
  }
}
</style>
