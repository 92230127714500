<template>
  <div v-if="patientDiagnosis?.name">
    <div class="uk-container uk-container-xlarge uk-padding-large">
      <div class="uk-grid-large uk-child-width-expand@s" uk-grid>
        <div class="uk-width-1-2@m uk-padding-remove-top">
          <h1>{{ patientDiagnosis?.name }}</h1>

          <div>
            <div v-if="patientDiagnosis.abstract" class="uk-margin-large-bottom c-diagnosis-abstract" v-html="patientDiagnosis.abstract">
            </div>
          </div>

          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick" stretch="true">
              <el-tab-pane
                v-if="patientDiagnosis.symptoms_explainer"
                label="Symptome"
                name="Symptome"
                class="uk-animation-slide-bottom-small" v-html="patientDiagnosis.symptoms_explainer"></el-tab-pane
              >
              <el-tab-pane
                v-if="patientDiagnosis.causes_explainer"
                label="Ursachen"
                name="Ursachen"
                class="uk-animation-slide-bottom-small" v-html="patientDiagnosis.causes_explainer"></el-tab-pane
              >
              <el-tab-pane
                v-if="patientDiagnosis.prevention_explainer"
                label="Vorsorge"
                name="Vorsorge"
                class="uk-animation-slide-bottom-small" v-html="patientDiagnosis.prevention_explainer"></el-tab-pane
              >
            </el-tabs>
          </div>
        </div>

        <div
          class="uk-width-1-2@m uk-margin-large-top"
        >
          <div v-if="patientDiagnosis.image_url">
            <el-image
            style="height: 300px; height: 400px;"
              :src="patientDiagnosis.image_url"
              :alt="patientDiagnosis.name"
              fit="contain">
            </el-image>
          </div>

          <!-- Hide sidebar info items because we don't have any data for them yet
               TODO: re-enable when api for this is available

          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-auto">
              <img
                src="../assets/icons/0502-bone.svg"
                class="uk-margin-right uk^-margin-small-top"
                width="26"
                height="26"
                uk-svg
              />
            </div>

            <div class="uk-width-4-5@s uk-margin-medium-bottom">
              <h4 class="uk-margin-remove">
                Krankheiten des Muskel-Skelett-Systems und des Bindegewebes
              </h4>
              <p class="uk-margin-remove">
                Sonstige Krankheiten des Muskel-Skelett-Systems und des Bindegewebes, Sonstige
                Krankheiten der Synovialis und der Sehnen
              </p>
            </div>

          </div>

          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-auto">
              <img
                src="../assets/icons/0291-users.svg"
                class="uk-margin-right uk^-margin-small-top"
                width="26"
                height="26"
                uk-svg
              />
            </div>

            <div class="uk-width-4-5@s uk-margin-medium-bottom">
              <h4 class="uk-margin-remove">Häufige Erkrankung</h4>
              <p class="uk-margin-remove">
                Ganglion sind sehr häufig, in Österreich gibt es derzeit geschätzt 20.000 Personen
                mit dem selben Problem.
              </p>
            </div>
          </div>

          <div class="uk-grid-small" uk-grid>
            <div class="uk-width-auto">
              <img
                src="../assets/icons/0215-reading.svg"
                class="uk-margin-right uk^-margin-small-top"
                width="26"
                height="26"
                uk-svg
              />
            </div>

            <div class="uk-width-4-5@s uk-margin-large-bottom">
              <h4 class="uk-margin-remove">Literaturempfehlung von Dr. Riegler</h4>
              <p class="uk-margin-remove">
                Wikipedia, Pubmed, <a href="#"><u>mehr erfahren</u></a>
              </p>
            </div>
          </div>

          -->

        </div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name: "Diagnosis",
  data() {
    return {
      activeName: "Symptome",
    };
  },
  props: {
    patientDiagnosis: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">
@import '../../assets/scss/base/_colors.scss';

h1
  font-size: 1.7rem

.c-diagnosis-abstract,
.el-tab-pane
  text-align: justify

</style>
