<template>
<slim-header></slim-header>
    <div class="uk-container uk-container-xlarge uk-padding-large">
      <h1>Impressum</h1>
     <div v-html="imprint.content"></div>
    </div>
  <careety-footer :user-profile="userProfile"></careety-footer>
</template>

<script>
import { ACTION_ASSURE_FETCHED_IMPRINT } from "@/store/action.type";
import { mapState } from "vuex";

export default {
  name: "Imprint",
  props: {
    userProfile: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch(ACTION_ASSURE_FETCHED_IMPRINT);
    } catch (err) {
      // TODO: handle errors
      console.log(err);
    }
  },
  computed: {
    ...mapState(["imprint"]),
  },
};
</script>

<style>

</style>
