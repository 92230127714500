<template>
  <radial-progress-bar :diameter="diameter"
                       :completed-steps="completedSteps"
                       :total-steps="totalSteps"
                       :animateSpeed="animateSpeed"
                       :fps="fps"
                       :strokeWidth="strokeWidth"
                       :innerStrokeWidth="innerStrokeWidth"
                       :startColor="startColor"
                       :stopColor="stopColor"
                       :innerStrokeColor="innerStrokeColor">
  </radial-progress-bar>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";

export default {
  data() {
    return {
      diameter: 30,
      completedSteps: 0,
      totalSteps: (7 * 4),
      animateSpeed: 2000,
      fps: 120,
      strokeWidth: 1.8,
      innerStrokeWidth: 1.8,
      startColor: "#FF7061",
      stopColor: "#FF7061",
      innerStrokeColor: "#eee",
      dayCount: 2,
    };
  },

  components: {
    RadialProgressBar,
  },
};
</script>

<style lang="sass">
.radial-progress-inner
  span
    font-size: .8em
    font-weight: 500

</style>
