<template>
   <header class="uk-inline uk-width-1-1">
     <div uk-sticky=" sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-dark; top: 200; animation: uk-animation-slide-top;">
       <health-professional-naviation></health-professional-naviation>
     </div>
 </header>
</template>

<script>
import HealthProfessionalNaviation from "./TheNavigation.vue";

export default {
  name: "HealthProfessionalHeader",
  components: {
    HealthProfessionalNaviation,
  },
};
</script>
