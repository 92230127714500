<template>
  <radial-progress-bar :diameter="diameter"
                       :completed-steps="completedSteps"
                       :total-steps="totalSteps"
                       :animateSpeed="animateSpeed"
                       :fps="fps"
                       :strokeWidth="strokeWidth"
                       :innerStrokeWidth="innerStrokeWidth"
                       :startColor="startColor"
                       :stopColor="stopColor"
                       :innerStrokeColor="innerStrokeColor">
                       <span>T {{ dayCount }}</span>
  </radial-progress-bar>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";

export default {
  data() {
    return {
      diameter: 44,
      completedSteps: 2,
      totalSteps: (7 * 4),
      animateSpeed: 2000,
      fps: 120,
      strokeWidth: 2,
      innerStrokeWidth: 2,
      startColor: "#FF7061",
      stopColor: "#FF7061",
      innerStrokeColor: "#eee",
    };
  },
  props: {
    dayCount: Number,
  },
  components: {
    RadialProgressBar,
  },
};
</script>

<style lang="sass">
.radial-progress-inner
  span
    font-size: .7em!important
    font-weight: 500

@media screen and (max-width: 600px)
  .radial-progress-bar
    height: 60px!important
    width: 60px!important
</style>
