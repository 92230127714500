export const ACTION_TRY_LOGIN = "actionTryLogin";
export const ACTION_LOGOUT = "actionLogout";
export const ACTION_FETCH_PROFILE = "actionFetchProfile";
export const ACTION_ASSURE_FETCHED_PROFILE = "actionAssureFetchedProfile";
export const ACTION_UPDATE_PROFILE = "actionUpdateProfile";
export const ACTION_FETCH_DIAGNOSIS = "actionFetchDiagnosis";
export const ACTION_ASSURE_FETCHED_DIAGNOSIS = "actionAssureFetchedDiagnosis";
export const ACTION_FETCH_CASE = "actionFetchCase";
export const ACTION_ASSURE_FETCHED_CASE = "actionAssureFetchedCase";
export const ACTION_FETCH_PRIVACY = "actionFetchPrivacy";
export const ACTION_ASSURE_FETCHED_PRIVACY = "actionAssureFetchedPrivacy";
export const ACTION_FETCH_IMPRINT = "actionFetchImprint";
export const ACTION_ASSURE_FETCHED_IMPRINT = "actionAssureFetchedImprint";
export const ACTION_WAITINGLIST_SIGNUP = "actionWaitinglistSignup";
export const ACTION_USER_REQUEST_PASSWORD_RESET = "actionUserRequestPasswordReset";
export const ACTION_USER_PASSWORD_RESET = "actionUserPasswordReset";
export const ACTION_PATIENT_SIGNUP_CHECK_TOKEN = "actionPatientSignupCheckToken";
export const ACTION_PATIENT_SIGNUP = "actionUserSignup";
export const ACTION_FETCH_TREATMENTPLANS_TO_SEND = "actionTreatmentplansToSend";
export const ACTION_SEND_TREATMENTPLAN = "actionSendTreatmentplan";
export const ACTION_PATIENT_FEEDBACK = "actionPatientFeedback";
export const ACTION_FETCH_TERMS_PATIENT = "actionFetchTermsPatient";
export const ACTION_ASSURE_FETCHED_TERMS_PATIENT = "actionAssureFetchTermsPatient";
export const ACTION_FETCH_TERMS_HEALTH_PROFESSIONAL = "actionFetchTermsHealthProfessional";
export const ACTION_ASSURE_FETCHED_TERMS_HEALTH_PROFESSIONAL = "actionAssureFetchTermsHealthProfessional";
export const ACTION_ASSURE_FETCHED_PROMO_CODE = "actionAssureFetchPromoCode";
export const ACTION_FETCH_PROMO_CODE = "actionFetchPromoCode";
