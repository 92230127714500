<template>
  <!-- MPB START -->
  <div class="uk-width-2-5@m mpb">
    <div class="mpb-wrapper">
      <greet-patient-card :patient-case="patientCase" :user-profile="userProfile" :now-date="nowDate"></greet-patient-card>
      <medication-card v-if="Object.keys(patientCase.prescriptions).length" :patient-case="patientCase" :modal="modal" :now-date="nowDate"></medication-card>
      <exercise-card v-if="Object.keys(patientCase.exercise_program).length" :patient-case="patientCase" :modal="modal" :now-date="nowDate"></exercise-card>
      <behavior-card v-if="Object.keys(patientCase.behavior_recommendations).length" :patient-case="patientCase" :modal="modal" :now-date="nowDate"></behavior-card>
      <tip-card :patient-case="patientCase" :modal="modal"></tip-card>
    </div>
    <!-- MPB Grid END -->
  </div>
  <!-- MPB END -->
</template>

<script>
import { DateTime } from "luxon";
import GreetPatientCard from "./cards/GreetPatientCard.vue";
import MedicationCard from "./cards/MedicationCard.vue";
import ExerciseCard from "./cards/ExerciseCard.vue";
import BehaviorCard from "./cards/BehaviorCard.vue";
import TipCard from "./cards/TipCard.vue";

export default {
  name: "QuickAccess",
  data() {
    return {
      modal: true,
    };
  },
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    userProfile: {
      type: Object,
      required: true,
    },
    nowDate: {
      type: Object,
      default: DateTime.local(),
    },
  },
  components: {
    GreetPatientCard,
    MedicationCard,
    ExerciseCard,
    BehaviorCard,
    TipCard,
  },
};
</script>
