<template>
  <!-- Meds List START -->
  <div class="uk-card uk-card-default uk-padding-remove uk-width-5-6@xl uk-align-right"
    uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true">
    <div class="uk-card-header uk-flex uk-flex-between">
      <div class="uk-grid-small uk-flex-top" uk-grid>
        <img src="@/assets/icons/0492-pills.svg" width="45" height="45" uk-svg />
        <div class="c-card-remove-line-height">
          <h3 class="uk-card-title uk-margin-remove-bottom">Medikamente</h3>
          <span class="c-card-title-day">Heute</span>
        </div>
      </div>
    </div>

    <el-tooltip content="Mehr Informationen" placement="top" v-if="modal">
      <div class="svg-icon uk-position uk-position-top-right uk-position-small">
        <a href="#medication-modal" uk-toggle class="card--info-btn">
          <img src="@/assets/icons/custom/information-icon-custom.svg" width="15" height="15"
            uk-svg /></a>
      </div>
    </el-tooltip>

    <div class="uk-card-body" v-if="medicationActive.length">
      <div
        class="grocery-list uk-grid-match uk-grid-small uk-margin-medium-bottom uk-flex-middle uk-flex-around"
        v-for="medication in medicationActive" :key="medication.name" uk-grid>
        <el-tooltip
          :content="`Tag ${Math.ceil(medication.progressDur.as('days'))} von ${Math.ceil(medication.durationDur.as('days'))}`"
          placement="top-end">
          <div class="grocery-list--pb">
            <radial-progress-bar-small v-bind:completedSteps="28 * medication.progress">
            </radial-progress-bar-small>
          </div>
        </el-tooltip>
        <div class="medication--list-items uk-width-expand">
          <p class="medication--list-name">{{ medication.name }} {{ medication.name_addition }}</p>
          <p class="medication--list-quantity">{{ medication.intake_hint }}</p>
          <p class="medication--list-cause">{{ medication.short_clarification }}</p>
        </div>

        <div v-if="medication.affiliate_url" class="uk-width-1-6 uk-align-right uk-margin-remove">
          <el-tooltip content="Online kaufen" placement="top">
            <a :href="medication.affiliate_url" class="medication--affiliate-link" target="_blank"
              :title="medication.name">
              <img src="@/assets/icons/0334-cart.svg" alt="Medikament kaufen" width="18" height="18"
                uk-svg />
            </a>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div class="uk-card-body" v-else>
      <p class="c-medication-text uk-text-center uk-text">Im Moment sind keine Medikamente aktiv
      </p>
    </div>

    <div class="uk-card-body uk-padding-remove-bottom uk-padding-remove-top c-medication-not-active" v-if="medicationNotActive.length">
      <el-collapse accordion>
        <el-collapse-item title="Startet in Kürze" name="1">

          <div
            class="grocery-list uk-grid-match uk-grid-small uk-flex-middle uk-flex-around uk-margin-top"
            v-for="medication in medicationNotActive" :key="medication.name" uk-grid>
            <div class="grocery-list--pb">
              <radial-progress-bar-small v-bind:completedSteps="28 * medication.progress">
              </radial-progress-bar-small>
            </div>
            <div class="medication--list-items uk-width-expand">
              <p class="medication--list-name">{{ medication.name }} {{ medication.name_addition }}
              </p>
              <p class="medication--list-quantity">{{ medication.intake_hint }}</p>
              <p class="medication--list-cause">{{ medication.short_clarification }}</p>
            </div>

            <div v-if="medication.affiliate_url"
              class="uk-width-1-6 uk-align-right uk-margin-remove">
              <el-tooltip content="Online kaufen" placement="top">
                <a :href="medication.affiliate_url" class="medication--affiliate-link"
                  target="_blank" :title="medication.name">
                  <img src="@/assets/icons/0334-cart.svg" alt="Medikament kaufen" width="16"
                    height="16" uk-svg />
                </a>
              </el-tooltip>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="uk-card-body c-medication-finished uk-padding-remove-bottom uk-padding-remove-top" v-if="medicationFinished.length">
      <el-collapse accordion>
        <el-collapse-item title="Abgeschlossen" name="1">

          <div
            class="grocery-list uk-grid-match uk-grid-small uk-flex-middle uk-flex-around uk-margin-top"
            v-for="medication in medicationFinished" :key="medication.name" uk-grid>
            <div class="grocery-list--pb">
              <img src="@/assets/icons/custom/finished-circle.svg" width="24" height="24" uk-svg />
            </div>
            <div class="medication--list-items uk-width-expand">
              <p class="medication--list-name">{{ medication.name }} {{ medication.name_addition }}
              </p>
              <p class="medication--list-quantity">{{ medication.intake_hint }}</p>
              <p class="medication--list-cause">{{ medication.short_clarification }}</p>
            </div>

            <div v-if="medication.affiliate_url"
              class="uk-width-1-6 uk-align-right uk-margin-remove">
              <el-tooltip content="Online kaufen" placement="top">
                <a :href="medication.affiliate_url" class="medication--affiliate-link"
                  target="_blank" :title="medication.name">
                  <img src="@/assets/icons/0334-cart.svg" alt="Medikament kaufen" width="18"
                    height="18" uk-svg />
                </a>
              </el-tooltip>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div
      class="uk-card-body c-progress-wrapper uk-margin-bottom uk-margin-remove-top">
      <div class="uk-text-right c-progress-text-wrapper">
        <p v-if="patientCase.prescriptions_overall_progress.progress === 1 " class="c-progress-day uk-margin-remove">
          Abgeschlossen
        </p>
        <p v-else class="c-progress-day uk-margin-remove">
          Tag {{ patientCase.prescriptions_overall_progress.progressAsDays }} von
          {{ patientCase.prescriptions_overall_progress.durationAsDays }}
        </p>
      </div>
      <el-tooltip
        :content="`Sie sind bei Tag ${patientCase.prescriptions_overall_progress.progressAsDays} von ${patientCase.prescriptions_overall_progress.durationAsDays}`"
        placement="top">
        <progress id="js-progressbar" class="uk-progress uk-margin-remove"
          v-bind:value="100 * patientCase.prescriptions_overall_progress.progress" max="100">
        </progress>
      </el-tooltip>
    </div>
  </div>
  <!-- Meds List END -->

  <!-- Meds Modal Start -->
  <div id="medication-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-border-rounded uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-grid-small " uk-grid>
        <div class="uk-width-auto@s">
          <img src="@/assets/icons/0492-pills.svg" class="uk-margin-right c-svg-dark" width="30"
            height="30" uk-svg />
        </div>
        <div class="uk-width-4-5@s">
          <h3 class="uk-margin-remove">Medikamente</h3>
          <p class="c-treatment-start">
            {{ patientCase.prescriptions_overall_progress.durationAsDays }} Tage, Bei Bedarf
          </p>
          <p class="uk-margin-remove-top uk-margin-small-bottom c-treatment-duration">
            {{ patientCase.prescriptions_overall_progress.startDate }}
            –
            {{ patientCase.prescriptions_overall_progress.finishDate }}
          </p>
          <div class="uk-margin-remove" v-html="patientCase.treatment.medication_explainer"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Meds Modal END -->
</template>

<script>
import { DateTime } from "luxon";
import { filterArrayOffDurCurrentlyActive, filterArrayOffDurNotYetActive, filterArrayOffDurAlreadyFinished } from "@/utils/time";
import RadialProgressBarSmall from "../../UI/RadialProgressSmall.vue";

export default {
  name: "MedicationCard",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    modal: {
      type: Boolean,
      required: true,
      default: true,
    },
    nowDate: {
      type: Object,
      default: DateTime.local(),
    },
  },
  computed: {
    medicationActive() {
      return filterArrayOffDurCurrentlyActive(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.prescriptions,
      );
    },
    medicationNotActive() {
      return filterArrayOffDurNotYetActive(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.prescriptions,
      );
    },
    medicationFinished() {
      return filterArrayOffDurAlreadyFinished(
        this.patientCase.treatment_case.date,
        this.nowDate,
        this.patientCase.prescriptions,
      );
    },
  },
  components: {
    RadialProgressBarSmall,
  },
};
</script>

<style lang="scss" scoped>
.medication--affiliate-link,
.card--info-btn {
  padding: 10px;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
}

.medication--affiliate-link:hover,
.card--info-btn:hover {
  background: #f8f8f8;
}

.c-medication-finished p,
.c-medication-not-active p {
  font-size: 1.06em
}

.c-medication-finished .medication--list-quantity,
.c-medication-finished .medication--list-cause {
  font-size: 1em;
  margin: 0!important;
}
.grocery-list:first-child {
  margin-bottom: 30px!important;
}

.c-medication-text {
  font-size: .95em;
  line-height: 1.3;
}

</style>
