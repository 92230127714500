<template>
  <nav
    class="uk-navbar uk-container uk-container-xlarge uk-black uk-padding-large uk-padding-remove-top uk-padding-remove-bottom"
    uk-navbar
  >
    <div class="uk-navbar-left">
      <router-link to="/" class="uk-navbar-item uk-logo uk-padding-remove"
        ><img src="@/assets/logo/careety-logo.png" width="120" @click="showLanding"
      /></router-link>
    </div>

    <div class="uk-navbar-right" v-if="isAuthenticated">
      <ul class="uk-navbar-nav uk-visible@s uk-flex-middle">
        <li v-if="userProfile.is_staff">
          <a href="/admin">Admin</a>
        </li>
        <li v-if="isAuthenticated && userProfile.is_patient">
          <router-link to="/treatment">Therapieplan</router-link>
        </li>

        <li v-if="isAuthenticated && userProfile.is_patient">
          <router-link to="/diagnose">Diagnose</router-link>
        </li>

        <li v-if="isAuthenticated" class="profile-wrapper uk-flex uk-flex-middle uk-margin-left">
          <div v-if="userProfile.profile_image_url" class="uk-margin-right">
            <el-image
              class="uk-border-circle uk-box-shadow-large"
              style="width: 50px; height: 50px"
              :src="userProfile.profile_image_url"
              :fit="fill"
            >
            </el-image>
          </div>

          <div href="#" class="profile-name uk-parent">
            <div class="profile-user-name">
              <span v-if="userProfile.forename">{{ userProfile.forename }}</span>
              <span v-if="userProfile.surname">{{ userProfile.surname }}</span>
              <span v-if="userProfile.email">
                <a href="#" @click="logout()">logout {{ userProfile.email }}</a>
              </span>
              <router-link v-else to="/login">login</router-link>
            </div>
          </div>
        </li>
      </ul>
      <a @click="drawer = true" type="primary" class="uk-hidden@s" uk-navbar-toggle-icon></a>
    </div>

        <div class="uk-navbar-right" v-if="!isAuthenticated">
      <ul class="uk-navbar-nav uk-flex-middle">
        <li v-if="userProfile.is_staff">
          <a href="/admin">Admin</a>
        </li>
        <li class="uk-text-bold uk-margin-right">
          <a @click="showLogin">Login</a>
        </li>

        <li>
          <el-button type="primary" size="medium" style="width: 100px;" @click="showPromoCodeChecker">Signup</el-button>
        </li>
      </ul>
      <a v-if="isAuthenticated" @click="drawer = true" type="primary" class="uk-hidden@s" uk-navbar-toggle-icon></a>
    </div>
  </nav>

  <el-drawer v-if="isAuthenticated"
    size="60%"
    withHeader="false"
    v-model="drawer"
    :direction="direction"
  >
    <div class="uk-padding uk-padding-remove-top">
      <a href="" class="uk-navbar-item uk-logo">
        <img src="@/assets/logo/careety-logo.png" alt="careety" width="120"
      /></a>
      <ul class="uk-nav uk-nav-default">
        <li class="uk-active"><router-link to="/treatment">Therapieplan</router-link></li>
        <li><router-link to="/diagnose">Diagnose</router-link></li>
      </ul>
      <div class="uk-margin-large-top uk-text-small c-drawer-content">
        <span class="uk-text-bold">Hallo {{ userProfile.forename }}, </span>
        <p>
          Heute ist der <strong>{{ dates.today }}</strong>
        </p>
        <u
          ><a href="#" @click="logout()">logout {{ userProfile.email }}</a></u
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { ACTION_LOGOUT } from "@/store/action.type";

export default {
  name: "TheNavigation",
  data() {
    return {
      dates: {
        today: DateTime.local().toFormat("dd. MMMM yyyy", { locale: "de" }),
      },
      drawer: false,
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch(ACTION_LOGOUT);
        this.$router.push("/");
      } catch (err) {
        // TODO: handle errors
      }
    },
    showPromoCodeChecker() {
      if (this.$route.name !== "Landing") {
        this.$router.push({
          path: "/signup",
        });
      } else {
        this.$emit("show-promo-code-checker");
      }
    },
    showLogin() {
      if (this.$route.name !== "Landing") {
        this.$router.push({
          path: "/login",
        });
      } else {
        this.$emit("show-login");
      }
    },
    showLanding() {
      this.$emit("show-landing");
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../assets/scss/helpers/_variables.scss';

.uk-active
  color: $c-coral-main!important
  font-weight: 500

.profile-user-name
  span
    display: block
.c-drawer-content
  p
    line-height: 1.6
    margin: 10px 0
</style>
