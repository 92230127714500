<template>
  <div
    class="uk-flex uk-height-medium uk-background-default uk-margin uk-padding-large"
    uk-height-viewport="offset-bottom: 30"
  >
    <div class="uk-margin-auto uk-margin-auto-vertical uk-width-1-2@s">
      <div class="uk-width-large uk-margin-auto uk-margin-top">
        <div class="uk-text-center uk-margin-medium uk-animation-fade">
            <h1>Password ändern</h1>
        </div>

        <div
          v-if="showSuccessMessage"
          class="uk-card uk-card-default uk-card-large uk-padding uk-animation-slide-bottom-medium c-pw-reset-redirect"
          style="animation-delay: 500ms"
        >
          <p>Passwort erfolgreich geändert.</p>
          <router-link to="/treatment">Weiter zu careety</router-link>
        </div>

        <div v-else-if="errorCode" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <div v-if="errorCode === 50">
            <p>Fehler: Ein interner Parameter konnte nicht verarbeitet werden.</p>
            <router-link to="/login/">Zurück zu careety</router-link>
          </div>
          <div v-else-if="errorCode === 1 || errorCode === 61">
            <p>Fehler: Sie sind bereits eingeloggt.</p>
            <router-link to="/login">Weiter zu careety</router-link>
          </div>
          <div v-else-if="errorCode === 3">
            <p>Fehler: Das angegebene Passwort ist zu kurz. (mind. 8 Zeichen)</p>
          </div>
          <div v-else-if="errorCode === 4">
            <p>Fehler: Dieser Passwort-Rücksetzungsanfrage-Link ist abgelaufen.</p>
            <router-link to="/login">Zurück zum Login</router-link>
          </div>
          <div v-else-if="errorCode === 62">
            <p>Fehler: Rücksetungsanfrage konnte nicht zugerodent werden.</p>
            <router-link to="/login">Zurück zu careety</router-link>
          </div>
          <div v-else-if="errorCode === 63">
            <p>Fehler: Dieser Passwort-Rücksetzungsanfrage-Link wurde bereits verwendet.</p>
            <router-link to="/login">Zurück zum Login</router-link>
          </div>
          <div v-else-if="errorCode === 64">
            <p>Fehler: Dieser Passwort-Rücksetzungsanfrage-Link ist abgelaufen.</p>
            <router-link to="/login">Weiter zum Login</router-link>
          </div>
          <div v-else>
            <p>Fehler: Passwort-Rücksetzungsanfrage fehlgeschlagen.</p>
            <router-link to="/login/">Zurück zu careety</router-link>
          </div>
        </div>

        <div
          v-else
          class="uk-card uk-card-default uk-card-large uk-padding uk-animation-slide-bottom-medium"
          style="animation-delay: 500ms"
        >
          <p class="uk-text-normal c-pw-reset">
            Bitte wähle ein neues Passwort.
          </p>

          <el-form
            :model="passwordReset"
            :rules="VALIDATOR_RULES"
            ref="passwordResetPasswordForm"
            v-on:submit.prevent
          >

            <!-- help password stores find username -->
            <el-input
              type="hidden"
              autocomplete="username"
              v-model="email"
            ></el-input>
            <el-input
              style="display: none;"
              type="email"
              autocomplete="username"
              v-model="email"
            ></el-input>

            <!-- display username -->
            <el-form-item>
              <el-input
                type="email"
                :disabled="true"
                v-model="email">
              </el-input>
            </el-form-item>

            <el-form-item prop="newPassword">
              <el-input
                v-model="passwordReset.newPassword"
                show-password
                placeholder="Neues Passwort"
                type="password"
                id="password"
                name="password"
                autocomplete="new-password"
              ></el-input>
            </el-form-item>

            <div class="uk-margin-medium-top">
              <button
                class="uk-button uk-button-primary uk-width-expand uk-text-capitalize"
                @click="sendPasswordReset"
                type="submit"
                value="senden"
                @keydown="sendPasswordReset"
              >
                Senden
              </button>
            </div>
          </el-form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ACTION_USER_PASSWORD_RESET } from "@/store/action.type";
import { apiUserPasswordResetCheckToken } from "@/api";

const VALIDATOR_RULES = {
  newPassword: [
    { required: true, message: "Bitte gib dein Passwort ein.", trigger: "blur" },
    { min: 8, message: "Mindestens 8 Zeichen", trigger: "blur" },
  ],
};

export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      passwordReset: {
        newPassword: "",
      },
      errorCode: null,
      showSuccessMessage: false,
    };
  },
  methods: {
    async sendPasswordReset() {
      const data = {
        new_password: this.passwordReset.newPassword,
        token: this.$route.params.token,
      };
      try {
        await this.$refs.passwordResetPasswordForm.validate();
        await this.$store.dispatch(ACTION_USER_PASSWORD_RESET, data);
        this.showSuccessMessage = true;
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.err) {
          this.errorCode = err.response.data.err;
        } else {
          this.errorCode = -1; // display generic error message
        }
      }
    },
  },
  async mounted() {
    try {
      const result = await apiUserPasswordResetCheckToken(this.$route.params.token);
      this.email = result.email;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.err) {
        this.errorCode = err.response.data.err;
      } else {
        this.errorCode = -1;
      }
    }
  },
  created() {
    this.VALIDATOR_RULES = VALIDATOR_RULES;
  },
};
</script>

<style lang="sass" scoped>
.uk-card
  padding-top: 30px

  .c-pw-reset
    font-size: .9em

.c-pw-reset-redirect
  text-align: center
  p
    margin: 0
  a
    display: block
/* TODO: figure out why this component doesn't use default link style + fix */
a
  text-decoration: underline;
</style>
