<template>
    <div
      v-if="!addComplicationClicked"
      class="complication-search-icon uk-width-3-5@l uk-width-1-1"
      @click="showComplicationsSearchForm"
    >
      <img
        src="../../../assets/icons/0823-plus.svg"
        height="12"
        width="12"
        uk-svg
        alt="Komplikation hinzufügen"
      />
    </div>

  <transition name="fade">
  <div v-if="addComplicationClicked" class="complication-search-form uk-width-3-5@l uk-width-1-1">
    <el-form
      v-loading="loading"
      uk-grid
      uk-margin
      :label-position="labelPosition"
      label-width="100px"
      :model="formLabelAlign"
    >
      <div class="uk-width-expand">
        <div>
          <el-form-item>
            <el-autocomplete
              class="uk-width-1-1"
              v-model="state"
              :fetch-suggestions="querySearchAsync"
              placeholder="Suchen ..."
              @select="handleSelect"
              highlight-first-item
            >
              <template #suffix>
                <i class="el-icon-edit el-input__icon" @click="handleIconClick"></i>
              </template>
              <template #default="{ item }">
                <div class="uk-padding-small">
                  <div class="uk-grid-small" uk-grid>

                    <div class="uk-width-auto">
                      <img
                        :src="require(`@/assets/icons/${item.icon}.svg`)"
                        width="18"
                        height="18"
                        uk-svg
                      />
                    </div>

                    <div class="uk-margin-remove uk-width-expand">
                      <p class="uk-margin-remove"><strong>{{ item.value }}</strong></p>
                      <p class="complication-search-description uk-margin-remove" v-html="item.description"></p>
                    </div>

                </div>
              </div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
  </transition>
</template>

<script>
import { apiHpSearchComplications } from "@/api";

export default {
  name: "ComplicaitonSearch",
  emit: ["add-complication"],
  data() {
    return {
      isEmergencySearch: this.$attrs.isEmergencySearch,
      addComplicationClicked: false,
      labelPosition: "top",
      complications: [],
      state: "",
      timeout: null,
      singleComplicationItem: {
        name: "",
        id: "",
        icon: "",
        description: "",
        complication_type: "",
      },
    };
  },
  methods: {
    showComplicationsSearchForm() {
      this.addComplicationClicked = !this.addComplicationClicked;
    },
    querySearchAsync(queryString, cb) {
      const results = queryString
        ? this.complications.filter(this.createFilter(queryString))
        : this.complications;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 1000 * Math.random());
    },
    createFilter(queryString) {
      return (item) => item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleSelect(item) {
      this.singleComplicationItem = item;
      this.showAssignBtns = true;
      if (this.isEmergencySearch) {
        this.singleComplicationItem.complication_type = "emergency";
      } else {
        this.singleComplicationItem.complication_type = "doctor";
      }
      this.$emit("add-complication", this.singleComplicationItem);
      this.state = "";
    },
  },
  async mounted() {
    const data = await apiHpSearchComplications();
    this.complications = data.map((item) => ({
      value: item.name,
      description: item.description,
      icon: item.icon_name,
      id: item.id,
    }));
  },
};
</script>

<style lang="scss">

.complication-search-icon {
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  border: 1px dashed #bbb;
  border-radius: 4px;
  transition: all .3s ease-in-out;
}

.complication-search-icon .uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: #ccc;
  transition: all .3s ease-in-out;
}

.complication-search-icon img {
  margin: 0;
  padding: 0;
}

.complication-search-icon:hover {
  cursor: pointer;
  border: 1px dashed #4a4a4a;
  transition: all .3s ease-in-out;
}

.complication-search-icon:hover .uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: #4a4a4a;
  transition: all .3s ease-in-out;
}

.complication-search-description {
  font-size: 0.92em;
  line-height: 1.5;
  white-space: normal;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
