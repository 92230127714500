<template>
  <div
    class="uk-container uk-container-xlarge uk-padding-large uk-dark uk-animation-fade c-treatment-title"
  >
    <div class="uk-grid-small uk-flex-middle" uk-grid>
      <div class="uk-width-auto@s">
        <div v-if="patientCase?.health_professional?.profile_image" uk-toggle="target: #c-call-doc-modal">
          <el-image
            class="uk-border-circle uk-box-shadow-large c-doc-image"
            style="width: 100px; height: 100px"
            :src="patientCase.health_professional.profile_image"
            :fit="cover"
          >
          </el-image>
        </div>
      </div>

      <div class="uk-width-4-5@s">
        <h1 class="uk-margin-small-bottom">
          {{ patientCase?.health_professional?.name_prefix}}
          {{ patientCase?.health_professional?.forename }}
          {{ patientCase?.health_professional?.surname }}
          {{ patientCase?.health_professional?.name_postfix }}</h1>
        <p class="uk-text-large uk-text-normal uk-margin-remove">
          Therapieplan {{ patientDiagnosis?.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TreatmentTitle",
  props: {
    patientCase: {
      type: Object,
      required: true,
    },
    patientDiagnosis: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.c-treatment-title
    transform: translateY(100%)
    padding-top: 0!important
    padding-bottom: 0!important

    h1
      font-size: 1.9em
      line-height: 1

    p
      font-size: 1.2em

.c-plan-rating
    letter-spacing: 0

@media screen and (max-width: 719px)
  .c-treatment-title
    transform: translateY(30%)

@media screen and (max-width: 330px)
  .c-treatment-title
    transform: translateY(10%)
</style>
