<template>
  <signup-header
    @show-promo-code-checker="showPromoCodeChecker"
    @hide-promo-code-checker="hidePromoCodeChecker"
    @show-login="toggleLogin"
    @show-landing="showLanding"
  ></signup-header>

  <div
    v-if="!showChecker && !showLogin"
    class="uk-flex uk-flex-center uk-flex-middle"
    uk-height-viewport="expand: true;"
  >
    <!-- <img src="@/assets/logo/c.png" width="60" height="60" alt="careety" /> -->
    <h1 class="uk-text-center uk-animation-slide-bottom-small" style="animation-delay: 600ms">for caring <br>doctors only <br>&#128521;</h1>
  </div>

  <div v-if="showChecker">
    <promo-code-checker
      @hide-promo-code-checker="hidePromoCodeChecker"
    ></promo-code-checker>
  </div>

  <div v-if="showLogin">
    <login></login>
  </div>
  <landing-footer v-if="!isAuthenticated"></landing-footer>
</template>

<script>
import SignupHeader from "../components/header/SignupHeader.vue";
import PromoCodeChecker from "../components/signup/PromoCodeChecker.vue";
import Login from "../components/login/Login.vue";
import LandingFooter from "../components/footer/LandingFooter.vue";

export default {
  data() {
    return {
      showChecker: false,
      showLogin: false,
    };
  },
  name: "Landing",
  components: {
    SignupHeader,
    PromoCodeChecker,
    Login,
    LandingFooter,
  },
  methods: {
    showPromoCodeChecker() {
      this.showChecker = true;
      this.showLogin = false;
    },
    hidePromoCodeChecker() {
      this.showChecker = false;
    },
    toggleLogin() {
      this.showLogin = true;
      this.showChecker = false;
    },
    showLanding() {
      this.showChecker = false;
      this.showLogin = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.uk-animation-scale-up
  animation-delay: 400ms
  animation-duration: 1000ms

.uk-animation-fade
  animation-delay: 1000ms
  animation-duration: 1000ms

p
  font-size: 13px
  margin: 8px

</style>
